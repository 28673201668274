import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Typography, Button, Space, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPosUserOption } from '../../ducks/actions'
import { addPosUser } from '../../ducks/services'
import { useForm } from 'react-hook-form';
import AddForm from '../components/AddForm';

const { Title, Text } = Typography;

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const meta = useSelector((state) => state.systems.posoption);
  const { control, formState: { errors }, handleSubmit, reset } = useForm();
  const [activeTab, setActiveTab] = useState("1");

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      ...val,
      roles: JSON.stringify(val.roles.map(x => ({id: x.value})))
    }

    await addPosUser(payload).then(res => {
      props.setLoading(false);
      if(res.data.code === 200) {
        message.success("POS User added successfully!");
        setTimeout(() => navigate("/pos/users"), 500);
      }else{
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  useEffect(() => {
    dispatch(getPosUserOption(props.setLoading))
  }, []);

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Row justify={'space-between'} align={'middle'}>
          <Col flex='auto'>
            <Title level={3} className='mb-0 mt-0'>New POS User</Title>
          </Col>
          <Col flex='auto' className='text-right'>
            <Space size={10}>
              <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/pos/users')}>{'<'} Back</Button>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <AddForm
          setLoading={props.setLoading}
          errors={errors}
          control={control}
          meta={meta}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          allowedEdit={true}
          addPosUser={addPosUser}
          handleSubmit={handleSubmit}
          mode={"add"}
          oneTab={true}
          reloadApi={(propId) => navigate(`/pos/users/${propId}`)}
        />
      </Col>
    </Row>
  )
}