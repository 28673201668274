import React, { useState, useEffect } from 'react';
import { Row, Col, Button, message } from 'antd';
import HeadingChip from 'Molecules/HeadingChip';
import { Popup } from 'Atoms/Popup';
import ListCard from 'Molecules/ListCard';
import AddEditRoles from './components/AddEditRoles';
import Search from './components/Search';
import { CloseCircleFilled } from '@ant-design/icons';
import { getPosUserRoleList, getPosUserRoleOption } from '../ducks/actions';
import { deletePosUserRole } from '../ducks/services';
import { useDispatch, useSelector } from 'react-redux';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';

export default (props) => {

  const [visible, setVisible] = useState(false);
  const [userFields, setUserFileds] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const dispatch = useDispatch();
  const [searchValue, setSearchVal] = useState(null);
  const userList = useSelector((state) => state.systems.posuserRoles);
  const globalListQuery = useSelector((state) => state.global.listQueryItems);

  useEffect(() => {
    dispatch(getPosUserRoleOption());

    if(!globalListQuery.posRolesListQuery) {
      dispatch(getPosUserRoleList(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('posRolesListQuery', 1, limit, '', '', null, props.setLoading));
    } else {
      const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.posRolesListQuery
      dispatch(getPosUserRoleList(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }, []);

  const reloadApi = () => {
    dispatch(getPosUserRoleList(page, limit, '', '', null, props.setLoading));
    dispatch(getListQueryItems('posRolesListQuery', page, limit, '', '', null, props.setLoading))
  };

  const deleteRecord = (record) => {
    if (confirm("Are you sure you want to delete this POS role?")) {
      props.setLoading(true);
      let payload = {
        id: record.id,
        status_event: 'remove'
      }
      deletePosUserRole(payload)
        .then((response) => {
          props.setLoading(false);
          if (response.data.code == 200) {
            message.success("POS Role deleted successfully");
            reloadApi();
          } else {
            message.error(response.data.message);
          }
        })
        .catch((error) => {
          props.setLoading(false);
          message.error(error?.message ?? 'Something went wrong');
        });
    }
  };

  const ListCol = [
    {
      title: 'Role',
      dataIndex: 'name',
      key: 'name',
      className: 'enable-click',
      sorter: true,
    },
    {
      title: 'Role Access',
      dataIndex: 'permission_items_count',
      key: 'permission_items_count',
      className: 'enable-click',
      sorter: true,
    },
    {
      title: 'Users',
      dataIndex: 'users_count',
      key: 'users',
      className: 'enable-click',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      sorter: true,
      align: 'center',
      render: (text, record) => (
        allowed([AllRoles.SYSTEM.POSROLE], 'delete') &&
        <Button type="link" className="list-links c-danger" onClick={() => deleteRecord(record)}>
          <CloseCircleFilled />
        </Button>
      ),
    },
  ];

  const btnList = [
    {
      text: '+ New POS Role',
      classes: 'attendance-success',
      action: () => {
        setUserFileds({ name: '', role_name: '' });
        setVisible(true);
      },
    },
  ];

  const popup = {
    closable: true,
    visibility: visible,
    content: (
      <AddEditRoles
        roleData={userFields}
        title={`${userFields.id ? 'Edit' : 'Add New'} POS Role`}
        onClose={() => setVisible(false)}
        reloadApi={reloadApi}
        visible={visible}
        allowedDelete={allowed([AllRoles.SYSTEM.POSROLE], 'delete')}
        hidePermissions={true}
      />
    ),
    width: 1199,
    onCancel: () => setVisible(false),
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest('.enable-click')) {
          if (allowed([AllRoles.SYSTEM.POSROLE], 'write')) {
            setUserFileds(record);
            setVisible(true);
          }
        }
      },
    };
  };
  const onSearch = (value) => {
    if (value) {
      let searchVal = {
        name: value?.user_role ? value?.user_role : '',
      };
      setSearchVal(searchVal);
      setPage(1);
      dispatch(getPosUserRoleList(1, 10, '', '', searchVal, props.setLoading));
      dispatch(getListQueryItems('posRolesListQuery', 1, 10, '', '', searchVal, props.setLoading))
    }
  };
  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getPosUserRoleList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchValue, props.setLoading));
      dispatch(getListQueryItems('posRolesListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchValue, props.setLoading))
    } else {
      dispatch(getPosUserRoleList(pagination.current, pagination.pageSize, '', '', searchValue, props.setLoading));
      dispatch(getListQueryItems('posRolesListQuery', pagination.current, pagination.pageSize, '', '', searchValue, props.setLoading))
    }
  };
  return (
    <>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <HeadingChip title="POS Roles" btnList={allowed([AllRoles.SYSTEM.POSROLE], 'write') ? btnList : null} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onRow={onClickRow}
            Search={Search}
            onSearch={onSearch}
            searchVal={globalListQuery.posRolesListQuery?.searchValue}
            ListCol={ListCol}
            ListData={userList?.list?.map(x => ({...x, key: x.id}))}
            pagination={{
              total: userList?.total_count,
              current: page,
              pageSize: limit,
            }}
            onChange={onTableChange}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  );
};
