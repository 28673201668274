import React, { useEffect, useState } from 'react';
import { Row, Col, message } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUoms } from '../ducks/actions'
import { removeUom, bulkRemoveUoms } from '../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search'
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uoms = useSelector((state) => state.product.uoms);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState(null);
  const allowedEdit = allowed([AllRoles.PRODUCT.UOM], 'write');
  const allowedDelete = allowed([AllRoles.PRODUCT.UOM], 'delete');
  const globalListQuery = useSelector((state) => state.global.listQueryItems);

  const colName = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
      className: 'enable-click',
      sorter: true
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="UOM"
          btnAction1={record.removable == "1" ? () => navigate(`/product/uom/${record.id}`) : null}
          recordId={record.id}
          onRemove={allowedDelete && record.removable == "1" && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ]

  const addNew = () => navigate('/product/uom/add');

  const btnList = [
    {
      title: 'Are you sure to remove the selected UOM(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New UOM',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    if(!globalListQuery.uomListQuery) {
      dispatch(getUoms(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('uomListQuery', 1, limit, '', '', null, props.setLoading));
    } else {
      const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.uomListQuery
      dispatch(getUoms(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }, []);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.removable == "0"
    }),
  };

  const onRemove = async (id) => {
    props.setLoading(true);

    const payload = {
      id: id,
      status_event: 'remove'
    }

    await removeUom(payload).then(res => {
      if (res.data.code === 200) {
        message.success("UOM has been removed successfully!");
        setTimeout(() => dispatch(getUoms(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('uomListQuery', page, limit, '', '', searchVal, props.setLoading))
      }else{
        message.error(res.data.message);
      }
    }).catch(e => {
      message.error(e.message ?? "Something went wrong");
    }).finally(() => {
      props.setLoading(false);
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      uoms: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveUoms(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("UOMs has been removed successfully!");
        setPage(1);
        setSearchVal(null);
        setTimeout(() => dispatch(getUoms(1, limit, '', '', null, props.setLoading)), 500);
        dispatch(getListQueryItems('uomListQuery', 1, limit, '', '', null, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getUoms(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
      dispatch(getListQueryItems('uomListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading))
    } else {
      dispatch(getUoms(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
      dispatch(getListQueryItems('uomListQuery', pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading))
    }
  }

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click"))
        navigate(`/product/uom/${record.id}`);
      },
    };
  };

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        code: search.code
      };
      setSearchVal(searching);
      dispatch(getUoms(1, limit, '', '', searching, props.setLoading));
      dispatch(getListQueryItems('uomListQuery', 1, limit, '', '', searching, props.setLoading))
    } else {
      setSearchVal(null);
      dispatch(getUoms(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('uomListQuery', 1, limit, '', '', null, props.setLoading))
    }
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"UOM"} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onRow={onClickRow}
            Search={Search}
            onSearch={onSearch}
            searchVal={globalListQuery.uomListQuery?.searchValue}
            ListData={uoms?.list?.map(x => ({ ...x, key: x.id }))}
            ListCol={colName}
            rowSelection={allowedEdit && rowSelection}
            onChange={onTableChange}
            pagination={{
              total: uoms?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
    </>
  )
}