import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Spin } from 'antd';
import AddForm from '../components/AddForm';
import { getTaxConditionDetail } from "../../ducks/actions";
import { editTaxCondition } from "../../ducks/services";
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import AllRoles from '../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../routing/config/utils';

const { Title } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation().pathname;
  const data = useSelector((state) => state.systems.taxCondition);
  const meta = useSelector((state) => state.systems.taxConditionOption);
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("1");
  const [load, setLoad] = useState(false);
  const allowedEdit = allowed([AllRoles.SYSTEM.TAX_CONDITION], 'write');
  const allowedDelete = allowed([AllRoles.SYSTEM.TAX_CONDITION], 'delete');

  useEffect(() => {
    callApi();
  }, []);

  const callApi = () => {
    dispatch(getTaxConditionDetail(id, setLoad));
  };

  useEffect(() => {
    if (searchParams.get('t')) {
      setActiveTab(searchParams.get('t'));
    }
  }, [searchParams])

  const setActive = (tab) => {
    navigate(`${location}?t=${tab}`);
  }

  return (
    <Spin indicator={antIcon} size="large" spinning={load}>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row justify={'space-between'} align={'middle'}>
            <Col flex='auto'>
              <Title level={3} className='mb-0 mt-0'>
                {data?.code}
              </Title>
            </Col>
            <Col flex='auto' className='text-right'>
              <Space size={10}>
                {/* <Button size="large" htmlType='submit' className='green-btn'>Save</Button> */}
                <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/tax-condition')}>{'<'} Back</Button>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AddForm
            loading={load}
            setLoading={setLoad}
            activeTab={activeTab}
            setActiveTab={setActive}
            data={data}
            meta={meta}
            id={id}
            updateTaxCondition={editTaxCondition}
            reloadApi={callApi}
            searchParams={searchParams}
            mode={'edit'}
            navigate={navigate}
            allowedEdit={allowedEdit}
            allowedDelete={allowedDelete}
          />
        </Col>
      </Row>
    </Spin>
  )
}