import React, { useEffect } from 'react';
import { Button, Form, Row, Col } from 'antd';
import { DateField, SelectField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import DateRangeDropdown from 'Modules/Application/components/DateRangeDropdown';

export default (props) => {

  const { control, handleSubmit, setValue, formState: { errors }, getValues } = useForm();

  useEffect(() => {
    if(props) {
      if (!getValues().start_date) {
        setValue('start_date', dayjs());
        setValue('dateRange', { label: 'Today', value: 'today' });
      }
      if (!getValues().end_date) {
        setValue('end_date', dayjs());
      }
    }
  }, [props]);

  const onSubmit = (val) => {
    props.onSearch(val);
  }

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100 inline-form'>
      <Row className='w-100' gutter={[10, 10]}>
        <Col flex="auto">
          <SelectField
            // isRequired={true}
            fieldname='outlets'
            label=''
            class='mb-0 w-100 default-select'
            initValue={''}
            control={control}
            selectOption={props.field1}
            iProps={{ placeholder: 'Outlets', isMulti: true }}
            // rules={{
            //   required: 'Please select at least one outlet',
            // }}
            // validate={errors.outlets && 'error'}
            // validMessage={errors.outlets && errors.outlets.message}
          />
        </Col>

        <DateRangeDropdown control={control} onSetValue={setValue} placeholderValue='Search' isRequired={false} />

        {/* <Col flex="auto">
          <DateField
            fieldname='start_date'
            label=''
            initValue={""}
            control={control}
            class='mb-0 w-100'
            iProps={{ placeholder: 'Search From', size: 'large' }}
          />
        </Col>
        <Col flex="auto">
          <DateField
            fieldname='end_date'
            label=''
            class='mb-0 w-100'
            initValue={""}
            control={control}
            iProps={{ placeholder: 'Search To', size: 'large' }}
          />
        </Col> */}
        <Col flex="70px">
          <Button className='w-100' size='large' type='primary' htmlType='submit'>Search</Button>
        </Col>
      </Row>
    </Form>
  )
}