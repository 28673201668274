import React from 'react';
import { Button, Form, Row, Col } from 'antd';
import { InputField, SelectField, DateField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';

export default (props) => {
  const { searchVal } = props;
  const { control, handleSubmit } = useForm()

  const searchStatusLabel = (searchVal?.group === "" || searchVal?.group) ? true : false

  const onSubmit = (val) => {
    props.onSearch(val);
  };

  const clearProps = () => {
    setValue('group', '');
    setValue('start_date', '');
    setValue('name', '');
    props.onSearch({});
  }

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100 inline-form'>
      <Row className='w-100' gutter={[10, 10]}>
        <Col flex="auto">
          <DateField
            fieldname='start_date'
            label=''
            initValue={searchVal?.start_date ? searchVal?.start_date : ''}
            control={control}
            class='mb-0 w-100'
            iProps={{ placeholder: 'Business Date', size: 'large' }}
          />
        </Col>
        <Col flex="auto">
          <InputField
            fieldname='name'
            label=''
            class='mb-0 w-100'
            initValue={searchVal?.name ? searchVal?.name : ''}
            control={control}
            iProps={{ placeholder: 'Search by POS ID..' }}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='group'
            label=''
            class='mb-0 w-100 default-select'
            initValue={searchStatusLabel ? {label: searchVal?.group === "" ? 'All Outlets' : searchVal?.group, value: searchVal?.group} : ''}
            control={control}
            iProps={{ placeholder: 'Search by outlet code..' }}
            selectOption={props.field1}
          />
        </Col>
        <Col flex="70px">
          <Button className='w-100' danger size='large' type='primary' htmlType='button' onClick={() => clearProps()}>Clear</Button>
        </Col>
        <Col flex="70px">
          <Button className='w-100' size='large' type='primary' htmlType='submit'>Search</Button>
        </Col>
      </Row>
    </Form>
  )
}