import React from 'react';
import { Button, Form, Row, Col } from 'antd';
import { InputField, SelectField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';

export default (props) => {
  const { searchVal } = props;
  const { control, handleSubmit } = useForm()

  const searchStatusLabel = (searchVal?.status === "" || searchVal?.status) ? true : false
  const searchStockTemplateTypes = (searchVal?.stock_template_types === "" || searchVal?.stock_template_types) ? true : false

  const onSubmit = (val) => {
    props.onSearch(val);
  }

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100 inline-form'>
      <Row className='w-100' gutter={[10, 10]}>
        <Col flex="auto">
          <InputField
            fieldname='code'
            label=''
            class='mb-0 w-100'
            initValue={searchVal?.code ? searchVal?.code : ''}
            control={control}
            iProps={{ placeholder: 'Search by code or description..' }}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='stock_template_types'
            label=''
            class='mb-0 w-10 default-select'
            initValue={searchStockTemplateTypes ? {label: searchVal?.stock_template_types === "" ? 'All Status' : searchVal?.stock_template_types, value: searchVal?.stock_template_types} : ''}
            control={control}
            iProps={{ placeholder: 'Stock Template Type' }}
            selectOption={props.field2}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='status'
            label=''
            class='mb-0 w-10 default-select'
            initValue={searchStatusLabel ? {label: searchVal?.status === "" ? 'All Status' : searchVal?.status, value: searchVal?.status} : ''}
            control={control}
            iProps={{ placeholder: 'Status' }}
            selectOption={props.field1}
          />
        </Col>
        <Col flex="70px">
          <Button className='w-100' size='large' type='primary' htmlType='submit'>Search</Button>
        </Col>
      </Row>
    </Form>
  )
}