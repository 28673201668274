import React, { useEffect, useState, Fragment, useCallback, useRef } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Upload } from 'antd';
import DndListCard from 'Molecules/DndListCard';
import HeadingChip from 'Molecules/HeadingChip';
import FormGroup from 'Molecules/FormGroup';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentMethodList } from '../../../../../ducks/actions'
import { editPaymentMethod, addPaymentMethod, removePaymentMethod, bulkRemovePaymentMethods, updatePaymentMethodsOrder } from '../../../../../ducks/services'
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import Search from './Search'
import ActionButton from 'Molecules/ActionButton';
import { dummyRequest, uploadFileV2, getFileName } from 'Features/utility';
import placeholderImg from "Assets/img/product-placeholder.png"
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';

const _ = require('lodash');

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, activeTab, allowedEdit, allowedDelete } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues, clearErrors } = useForm();
  const paymentMethods = useSelector((state) => state.systems.paymentMethods);
  const meta = useSelector((state) => state.systems.paymentMethodOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [load, setLoad] = useState(false);
  const [visible, setVisible] = useState(false);
  const [searchVal, setSearchVal] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileId, setFileId] = useState();
  const [list, setDataList] = useState([]);
  const [hasDirty, setHasDirty] = useState(false);

  const colName = [
    {
      title: 'No.',
      dataIndex: 'index',
      key: 'index',
      width: 30,
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Image',
      dataIndex: 'image_url',
      key: 'image_url',
      className: 'enable-click',
      sorter: true,
      align: 'center',
      render: (text) => <img src={text || placeholderImg} width="50px" />
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Description',
      dataIndex: 'name',
      key: 'name',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Short Description',
      dataIndex: 'external_desc',
      key: 'external_desc',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Cash Drawer Mode',
      dataIndex: 'cash_drawer_mode',
      key: 'cash_drawer_mode',
      className: 'enable-click',
      sorter: true,
      render: (text, record) => <span>{meta?.cash_drawer_modes?.find(x => x.value === parseInt(text))?.label}</span>
    },
    {
      title: 'Print Receipt Qty',
      dataIndex: 'print_receipt_qty',
      key: 'print_receipt_qty',
      className: 'enable-click',
      sorter: true,
      align: 'right',
    },
    {
      title: 'Expense Type',
      dataIndex: 'expense_type',
      key: 'expense_type',
      className: 'enable-click',
      sorter: true,
      render: (text, record) => <span>{meta?.expense_type?.find(x => x.value === parseInt(text))?.label}</span>
    },
    {
      title: 'Is Cash Sales?',
      dataIndex: 'is_cash_sales',
      key: 'is_cash_sales',
      sorter: true,
      align: 'center',
      render: (text) => <span>{text === "1" ? <CheckCircleFilled className='c-success' style={{ fontSize: '20px' }} /> : <CloseCircleFilled className='c-danger' style={{ fontSize: '20px' }} />}</span>
    },
    {
      title: 'Is Kiosk?',
      dataIndex: 'is_kiosk',
      key: 'is_kiosk',
      sorter: true,
      align: 'center',
      render: (text) => <span>{text === "1" ? <CheckCircleFilled className='c-success' style={{ fontSize: '20px' }} /> : <CloseCircleFilled className='c-danger' style={{ fontSize: '20px' }} />}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Active" ? "c-success" : "c-danger"}>
            {text}
          </span>
        </div>
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        <ActionButton
          title="Payment Method"
          btnAction1={() => {
            setVisible(true);
            setValue('id', record.id);
            setValue('name', record.name);
            setValue('code', record.code);
            setValue('external_desc', record.external_desc);
            setValue('print_receipt_qty', { label: record.print_receipt_qty || 1, value: record.print_receipt_qty || 1 });
            setValue('cash_drawer_mode', meta?.cash_drawer_modes?.find(x => x.value === parseInt(record.cash_drawer_mode)));
            setValue('expense_type', meta?.expense_type?.find(x => x.value === parseInt(record.expense_type)));
            setValue('external_transaction_code', record.external_transaction_code);
            setValue('card_issuer_id', record.card_issuer_id);
            setValue('is_cash_sales', record?.is_cash_sales === "1");
            setValue('is_kiosk', record?.is_kiosk === "1");
            if (record?.image_url) {
              setFileList([
                {
                  uid: '-1',
                  name: getFileName(record?.image_url),
                  status: 'done',
                  url: record?.image_url
                }
              ])
            } else {
              setFileList([]);
              setFileId();
            }
          }}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const formFields = [
    {
      name: 'id',
      label: '',
      type: 'input',
      hidden: true,
    },
    {
      name: 'code',
      label: 'Code',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'name',
      label: 'Description',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'external_desc',
      label: 'Short Description',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      static: !allowedEdit
    },
    {
      name: 'cash_drawer_mode',
      label: 'Cash Drawer Mode',
      req: true,
      placeholder: 'Please state',
      type: 'select',
      options: meta?.cash_drawer_modes,
      class: 'default-select',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'print_receipt_qty',
      label: 'Print Receipt Qty',
      req: true,
      placeholder: 'Please state',
      type: 'select',
      options: meta?.receipt_qtys?.map(x => ({label: x, value: x})),
      class: 'default-select',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'expense_type',
      label: 'Expense Type',
      req: true,
      placeholder: 'Please state',
      type: 'select',
      options: meta?.expense_type?.filter(item => !item.is_hide),
      class: 'default-select',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'external_transaction_code',
      label: 'External Transaction Code',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'card_issuer_id',
      label: 'Card Issuer ID',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'is_cash_sales',
      label: 'Is Cash Sales?',
      req: false,
      twocol: true,
      colWidth: '1 0 50%',
      static: !allowedEdit,
      alignEnd: true
    },
    {
      type: 'switch',
      name: 'is_kiosk',
      label: 'Is Kiosk?',
      req: false,
      twocol: true,
      colWidth: '1 0 50%',
      static: !allowedEdit,
      alignEnd: true
    },
  ];

  useEffect(() => {
    if (activeTab === "2")
      dispatch(getPaymentMethodList(1, limit, '', '', null, props.setLoading, id));
  }, [activeTab]);

  useEffect(() => {
    if(paymentMethods) {
      setDataList(paymentMethods.list);
    }
  }, [paymentMethods]);

  const addNew = () => {
    setVisible(true);
    setValue('id', '');
    setValue('name', '');
    setValue('code', '');
    setValue('external_desc', '');
    setValue('cash_drawer_mode', '');
    setValue('expense_type', '');
    setValue('external_transaction_code', '');
    setValue('card_issuer_id', '');
    setValue('is_cash_sales', false);
    setValue('is_kiosk', false);
    setValue('print_receipt_qty', { label: 1, value: 1 });
    setFileList([]);
    clearErrors();
  }

  const btnList = [
    {
      title: "Are you sure to reorder the list?",
      text: "Update Listing",
      classes: 'b-success attendance-success',
      btnHidden: !hasDirty,
      action: () => onUpdateList()
    },
    {
      title: 'Are you sure to remove the selected Tender Media(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New Tender Media',
      classes: 'attendance-success',
      action: () => addNew(),
    }
  ];

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getPaymentMethodList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading, id));
    } else {
      dispatch(getPaymentMethodList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading, id));
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        code: search?.code
      };
      setSearchVal(searching);
      dispatch(getPaymentMethodList(1, limit, '', '', searching, props.setLoading, id));
    } else {
      setSearchVal(null);
      dispatch(getPaymentMethodList(1, limit, '', '', null, props.setLoading, id));
    }
  };

  const handleUpload = async (e) => {
    setLoad(true);
    try {
      let file = await uploadFileV2(e.file.originFileObj);
      setLoad(false);
      setFileId(file?.id);
      setFileList([{ uid: '-1', name: getFileName(file?.url), status: 'done', url: file?.url }]);
    } catch (error) {
      message.error(`Error during file upload: ${error}`);
      setLoad(false);
    }
  };

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      name: val?.name,
      code: val?.code,
      external_desc: ["Cash", "cash"].includes(val?.name) ? "Cash" : val?.external_desc,
      cash_drawer_mode: val?.cash_drawer_mode?.value,
      expense_type: val?.expense_type?.value,
      print_receipt_qty: val?.print_receipt_qty?.value,
      external_transaction_code: val?.external_transaction_code,
      card_issuer_id: val?.card_issuer_id,
      is_cash_sales: val?.is_cash_sales ? "1" : "0",
      is_kiosk: val?.is_kiosk ? "1" : "0",
      blob_id: fileId,
      payment_method_type_id: id,
      payment_method_id: val?.id
    }
    await (val?.id ? editPaymentMethod : addPaymentMethod)(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success(`Tender Media ${val?.id ? 'Updated' : 'Added'} Successfully!`);
        setVisible(false);
        setPage(1);
        setTimeout(() => dispatch(getPaymentMethodList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const onRemove = async (recordId) => {
    setLoad(true);

    const payload = {
      payment_method_id: recordId,
      payment_method_type_id: id,
      status_event: 'remove'
    }
    await removePaymentMethod(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success(`Tender Media Removed Successfully!`);
        setVisible(false);
        setPage(1);
        setTimeout(() => dispatch(getPaymentMethodList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            {getValues('id') ? 'Edit' : 'Add'} Tender Media
          </Title>
          <Row gutter={[10, 10]}>
            <Col span={24} className="text-center">
              <Upload
                disabled={!allowedEdit}
                listType="picture-card"
                className="avatar-uploader larger"
                showUploadList={false}
                accept="image/*"
                maxCount={1}
                fileList={fileList}
                customRequest={dummyRequest}
                onChange={(e) => handleUpload(e)}
              >
                <Space size={4}>
                  {fileList?.length > 0 ? (
                    <img
                      src={fileList[0].url}
                      alt="avatar"
                      style={{
                        width: '100%',
                      }}
                    />
                  ) :
                    <div>
                      <img src={placeholderImg} alt="" className='w-100' />
                    </div>
                  }
                </Space>
              </Upload>
            </Col>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => setVisible(false)}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => setVisible(false)
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      payment_method_type_id: id,
      payment_methods: JSON.stringify(selectedRowKeys)
    }

    await bulkRemovePaymentMethods(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Tender Medias has been removed successfully!");
        setPage(1);
        setSearchVal(null);
        setTimeout(() => dispatch(getPaymentMethodList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const onUpdateList = async () => {
    props.setLoading(true);

    const payload = {
      payment_methods: JSON.stringify(list?.map((d, i) => ({ id: d.id, position: i + 1 })))
    }
    await updatePaymentMethodsOrder(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Tender Medias ordering has been updated successfully.");
        setHasDirty(false);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={''} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="">
          <DndListCard
            setHasDirty={setHasDirty}
            list={list}
            setDataList={setDataList}
            onSearch={onSearch}
            Search={Search}
            rowSelection={allowedEdit && rowSelection}
            ListData={list?.map((x, idx) => ({ ...x, key: x.id, index: idx + 1 }))}
            onChange={onTableChange}
            ListCol={colName}
            pagination={{
              total: paymentMethods?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}