import React from 'react';
import { Row, Typography, Col, Button } from "antd";
import { useTranslate } from 'Translate';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

export default (props) => {
    
    const navigate = useNavigate();
    const i18n = useTranslate();
    const { t } = i18n;

    return (
        <Row gutter={[30, 24]} justify={'center'}>
            <Col span={24}><Button type="link" onClick={() => navigate("/")}>{"<"} Back</Button></Col>
            <Col span={12} className='text-center'>
                <Title level={1}>404</Title>
                <Title level={2}>Page Not Found</Title>
            </Col>
        </Row>
    )
}