import React, { useEffect, useState } from 'react';
import { Row, Col, message, Spin, Form, Space, Typography, Button } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOutlets } from './ducks/actions'
import { removeOutlet, bulkRemoveOutlets, bulkUpdateRegion } from './ducks/services'
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search';
import AllRoles from '../../../routing/config/AllRoles';
import { allowed } from '../../../routing/config/utils';
import { LoadingOutlined, CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { SelectField } from 'Atoms/FormElement';
import { Popup } from 'Atoms/Popup';
import { useForm } from 'react-hook-form';
import { getListQueryItems } from 'Modules/Application/ducks/actions';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const outlets = useSelector((state) => state.outlet.outlets);
  const { regions } = useSelector((state) => state.outlet.outletsMeta);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState(null);
  const [visible, setVisible] = useState(false);
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const regionOptions = regions?.map(({id, code}) => ({label: code, value: id}))
  const globalListQuery = useSelector((state) => state.global.listQueryItems);

  const colName = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Venue',
      dataIndex: 'desc',
      key: 'desc',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Outlet Group',
      dataIndex: 'location_group_code',
      key: 'location_group_code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Outlet Concept',
      dataIndex: 'product_menu_code',
      key: 'product_menu_code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Outlet Manager',
      dataIndex: 'user_name',
      key: 'user_name',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      key: 'roles',
      className: 'enable-click',
      sorter: true,
      render: (text, record) => <span className='listing-pill'>{text?.map((item, index) => <span className='pill-item' key={`${record.id} ${index}`}>{item}</span>)}</span>
    },
    {
      title: 'Region Code',
      dataIndex: 'region_code',
      key: 'region_code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Is Plastic Allowed?',
      dataIndex: 'is_plastic_allowed',
      key: 'is_plastic_allowed',
      className: 'enable-click',
      align: 'center',
      render: (text) => <span>{text === "Yes" ? <CheckCircleFilled className='c-success' style={{ fontSize: '20px' }} /> : <CloseCircleFilled className='c-danger' style={{ fontSize: '20px' }} />}</span>
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        <ActionButton
          title="Outlet"
          btnAction1={() => navigate(`/outlets/${record.id}`)}
          recordId={record.id}
          onRemove={allowed([AllRoles.OUTLET.OUTLET], 'delete') && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const addNew = () => navigate('/outlets/add');

  const btnList = [
    {
      title: 'Are you sure to assign region to selected outlet(s)?',
      permit: true,
      noNeedConfirm: true,
      text: 'Assign Region',
      classes: 'green-btn text-white attendance-success',
      action: () => openAssignRegionPopUp(),
    },
    {
      title: 'Are you sure to remove the selected outlet(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New Outlet',
      classes: 'attendance-success',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    if(!globalListQuery.outletListQuery) {
      dispatch(getOutlets(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('outletListQuery', 1, limit, '', '', null))
    } else {
      const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.outletListQuery
      dispatch(getOutlets(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }, []);

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getOutlets(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
      dispatch(getListQueryItems('outletListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading))
    } else {
      dispatch(getOutlets(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
      dispatch(getListQueryItems('outletListQuery', pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading))
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        name: search.name.value,
        code: search.code,
        menu: search.menu,
        group: search.group
      };
      setSearchVal(searching);
      dispatch(getOutlets(1, limit, '', '', searching, props.setLoading));
      dispatch(getListQueryItems('outletListQuery', 1, limit, '', '', searching, props.setLoading))
    } else {
      setSearchVal(null);
      dispatch(getOutlets(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('outletListQuery', 1, limit, '', '', null, props.setLoading))
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // setRegionBtnVisible(newSelectedRowKeys.length ? true : false)
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRemove = async (id) => {
    props.setLoading(true);

    const payload = {
      id: id,
      status_event: 'remove'
    }

    await removeOutlet(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Outlet has been removed successfully!");
        setTimeout(() => dispatch(getOutlets(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('outletListQuery', page, limit, '', '', searchVal))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      locations: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveOutlets(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Outlets has been removed successfully!");
        setPage(1);
        setSearchVal(null);
        setTimeout(() => dispatch(getOutlets(1, limit, '', '', null, props.setLoading)), 500);
        dispatch(getListQueryItems('outletListQuery', 1, limit, '', '', null))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const openAssignRegionPopUp = async () => {
      setVisible(true);
      setValue('id', null);
      setValue('order_type', '');
      setValue('order_types', '');
  }

  const onAssignRegion = async (val) => {
    props.setLoading(true);

    const payload = {
      locations: JSON.stringify(selectedRowKeys),
      region_id: val?.region?.value,
    }

    await bulkUpdateRegion(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Region has been assigned successfully!");
        setPage(1);
        setSearchVal(null);
        setVisible(false);
        setValue('region', '');
        setTimeout(() => dispatch(getOutlets(1, limit, '', '', null, props.setLoading)), 500);
        dispatch(getListQueryItems('outletListQuery'))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={props.loading}>
      <Form layout="vertical" onFinish={handleSubmit(onAssignRegion)}>
        <Space direction='vertical' size={15} className='w-100'>
          <Title level={4} className='m-0'>
            Assign Region
          </Title>
          <SelectField
                isRequired={visible}
                fieldname='region'
                label='Region'
                class='mb-1 w-100 default-select'
                initValue={''}
                control={control}
                iProps={{ placeholder: 'Please select' }}
                selectOption={regionOptions}
                rules={{
                  required: 'Region is required',
                }}
                validate={errors.region && 'error'}
                validMessage={errors.region && errors.region.message}
              />
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('region', '');
            }}>Cancel</Button></Col>
            <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setValue('region', '');
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click"))
        navigate(`/outlets/${record.id}`)
      },
    };
  };

  return (
    <>
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <HeadingChip title={"Outlets"} btnList={allowed([AllRoles.OUTLET.OUTLET], 'write') ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
      </Col>
      <Col span={24} className="clickRow">
        <ListCard
          onRow={onClickRow}
          Search={Search}
          onSearch={onSearch}
          searchVal={globalListQuery.outletListQuery?.searchValue}
          ListData={outlets?.list?.map(x => ({...x, key: x.id}))}
          onChange={onTableChange}
          ListCol={colName}
          rowSelection={rowSelection}
          pagination={{
            total: outlets?.total_count,
            current: page,
            pageSize: limit
          }}
        />
      </Col>
    </Row>
    <Popup {...popup} />
    </>
  )
}