export default {
    DASHBOARD: {
        DASHBOARD: 'dashboard'
    },
    PRODUCT: {
        PRODUCT: 'product',
        MENU: 'menu',
        ORDERTYPE: 'order_type',
        INGREDIENT: 'ingredient',
        CATEGORY: 'category',
        UOM: 'uom',
        AVAILABILITY: 'availability',
    },
    OUTLET: {
        OUTLET: 'outlet',
        OUTLETGROUP: 'outlet_group',
    },
    SYSTEM: {
        USERS: 'users',
        ROLE: 'role',
        POSUSERS: 'pos_users',
        POSROLE: 'pos_role',
        CURRENCY: 'currency',
        PAYMENTTERM: 'payment_term',
        REGION: 'region',
        ORDER_TYPE_GROUP: 'order_type_group',
        TAX_CONDITION: 'tax_condition',
        REASON: 'reason',
        REMARKS: 'remarks',
        REMARK_TYPE: 'remark_type',
        TAX: 'tax',
        PAYMENT_METHOD_TYPE: 'payment_method_type',
        SETUP: 'setup',
        MERCHANT: 'merchant',
        NETSUITE_SYNC: 'netsuite_sync'
    },
    POS: {
        TERMINAL: 'terminal',
        VOUCHER_TYPE: 'voucher_type',
        VOUCHER_GENERATOR: 'voucher_generator',
        VOUCHERS: 'vouchers',
        DISCOUNT: 'discount',
        KITCHEN_QUEUE: 'kitchen_queue',
        PRINTERS: 'printers',
        AUDIT_LOG: 'audit_log'
    },
    CRM: {
        CUSTOMER: 'customer'
    },
    INVENTORY: {
        VENDOR: 'vendor',
        PURCHASE_ORDER: 'purchase_order',
        GOOD_RECEIVE: 'good_receive',
        TRANSFER_OUT: 'transfer_out',
        TRANSFER_RECEIVE: 'transfer_receive',
        STOCK_WASTE: 'stock_wastage',
        STOCK_ADJUSTMENT: 'stock_adjustment',
        STOCK_TEMPLATE: 'stock_template',
        STOCK_TAKE: 'stock_take',
        STOCK_MOVEMENT: 'stock_movement',
        STOCK_CONVERSION: 'stock_conversion'
    },
    REPORTING: {
        SALES: 'sales',
        DAILY_SUMMARY: 'daily_summary',
        SALES_PAYMENT: 'sales_payment',
        SALES_DETAILS: 'sales_details',
        SALES_SUMMARY: 'sales_summary',
        SALES_HOURLY: 'sales_hourly',
        VOUCHER_LISTING: 'voucher_listing',
        BILL_SUMMARY: 'bill_summary', // with bill_no
        BILL_SUMMARY_LISTING: 'bill_summary_listing',
        SALES_DISCOUNT_LISITNG: 'sales_discount_listing',
        CASH_MANAGEMENT_LISTING: 'cash_management_listing',
        PRODUCT_SALES_LISTING: 'product_sales_listing',
        SPEED_OF_SERVICE_LISTING: 'speed_of_service_listing',
        CANCELLED_SALES_LISTING: 'cancelled_sales_listing',
        TAX_CHARGES_LISTING: 'tax_charges_listing',
        TAX_CHARGES_DETAILS: 'tax_charges_details',
        POS_AUDIT_LOG: 'pos_audit_log',
    },
    INVENTORY_REPORTING: {
        INVENTORY_REPORT: 'inventory_report',
        STOCK_BALANCE: 'stock_balance'
    }
}