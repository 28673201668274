import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, message, Button, Form, Spin, Space, Typography } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getEntityUserList } from '../../../../../ducks/actions'
import { removeEntityUser, bulkRemoveEntityUsers, addEntityUser, updateEntityUser } from '../../../../../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search'
import { Popup } from 'Atoms/Popup';
import { InputField } from 'Atoms/FormElement';
import { LoadingOutlined } from '@ant-design/icons';
import FormGroup from 'Molecules/FormGroup';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, activeTab, allowedEdit, allowedDelete } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues, clearErrors } = useForm();
  const users = useSelector((state) => state.systems.entityUserList);
  const meta = useSelector((state) => state.systems.entityUserListMeta);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState();
  const [visible, setVisible] = useState(false);
  const [reqOnEdit, setReqOnEdit] = useState(false);
  const [hideOnEdit, setHideOnEdit] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (activeTab === "2")
      dispatch(getEntityUserList(1, limit, '', '', null, props.setLoading, id));
  }, [activeTab]);

  const formFields = [
    {
      name: 'id',
      label: '',
      type: 'input',
      hidden: true,
    },
    {
      name: 'user',
      label: 'Email / Username',
      placeholder: 'Please enter',
      type: 'input',
      reqmessage: 'Required',
      req: reqOnEdit,
      hidden: hideOnEdit
    },
    {
      name: 'roles',
      label: 'Roles',
      req: true,
      type: 'select',
      options: meta?.roles?.map((x) => ({ label: x.name, value: x.id })),
      placeholder: 'Please select',
      twocol: false,
      multiple: true,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      class: "default-select",
      static: !allowedEdit
    },
  ];

  const colName = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Name',
      dataIndex: 'user_name',
      key: 'user_name',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Email',
      dataIndex: 'user_email',
      key: 'user_email',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Entity Name',
      dataIndex: 'entity_name',
      key: 'entity_name',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      key: 'roles',
      className: 'enable-click',
      sorter: true,
      render: (text, record) => <span className='listing-pill'>{text?.map((item, index) => <span className='pill-item' key={`${record.id} ${index}`}>{item.name}</span>)}</span>
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        <ActionButton
          title="Collaborator"
          btnAction1={record.role == "staff" ? () => {
            setValue('id', record.id);
            let temp = [];
            record?.roles?.map(role => {
              temp.push({
                label: role.name,
                value: role.id
              })
            })
            setValue('roles', temp);
            setVisible(true);
            setReqOnEdit(false);
            setHideOnEdit(true);
            clearErrors();
          } : null}
          recordId={record.id}
          onRemove={(allowedDelete && record.role == "staff") && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        // allowedDelete={allowed([Roles.USERS], 'delete')}
        />
    }
  ];

  const addNew = () => {
    setVisible(true);
    setValue('user', '');
    setValue('id', '');
    setValue('roles', '');
    setReqOnEdit(true);
    setHideOnEdit(false);
    clearErrors();
  }

  const btnList = [
    {
      title: 'Are you sure to remove the selected user(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New Collaborator',
      classes: 'attendance-success',
      action: () => addNew(),
    },
  ];

  const onSearch = (val) => {
    setPage(1);
    if (val) {
      let searching = {
        name: val?.name,
      }
      setSearchVal(searching)
      dispatch(getEntityUserList(1, limit, '', '', searching, props.setLoading, id));
    } else {
      setSearchVal();
      dispatch(getEntityUserList(1, limit, '', '', null, props.setLoading, id));
    }
  };

  // const onAction = async (locUserId, status) => {
  //   props.setLoading(true);

  //   const payload = {
  //     id: id,
  //     location_user_id: locUserId,
  //     status_event: status === 'Active' ? 'deactivate' : 'activate'
  //   }

  //   await removeEntityUser(payload).then(res => {
  //     props.setLoading(false);
  //     if (res.data.code === 200) {
  //       message.success("User status has been updated successfully!");
  //       setTimeout(() => dispatch(getEntityUserList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
  //     }
  //   }).catch(e => {
  //     props.setLoading(false);
  //     message.error(e.message ?? "Something went wrong");
  //   })
  // }

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getEntityUserList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading, id));
    } else {
      dispatch(getEntityUserList(pagination.current, pagination.pageSize, '', '', null, props.setLoading, id));
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.role === 'owner'
    }),
  };

  const onRemove = async (recordId) => {
    props.setLoading(true);

    const payload = {
      id: id,
      organization_user_id: recordId,
      status_event: 'remove'
    }

    await removeEntityUser(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Collaborator has been removed successfully!");
        setTimeout(() => dispatch(getEntityUserList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      id: id,
      users: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveEntityUsers(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Collaborators has been removed successfully!");
        setTimeout(() => dispatch(getEntityUserList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      id: id,
      roles: JSON.stringify(val?.roles?.map(x => ({ id: x.value }))),
    }

    if(val?.id) payload.organization_user_id = val?.id
    else payload.email = val?.user

    await (val?.id ? updateEntityUser : addEntityUser)(payload).then(res => {
      setLoad(false);
      if(res.data.code === 200) {
        message.success(`Collaborator ${val?.id ? 'updated' : 'added'} successfully!`);
        setVisible(false);
        dispatch(getEntityUserList(1, limit, '', '', searchVal, props.setLoading, id));
      }else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            {getValues('id') ? "Update Collaborator's Role" : "Add a collaborator to this entity"}
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('user', '');
              setValue('id', '');
              setValue('roles', '');
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setValue('id', '');
      setValue('user', '');
      setValue('roles', '');
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (record.role == "owner") {
          return message.warning("Owner of the entity cannot be edited.");
        }
        if (e.target.closest(".enable-click")) {
          setValue("id", record.id);
          let temp = [];
          record?.roles?.map(role => {
            temp.push({
              label: role.name,
              value: role.id
            })
          })
          setValue('roles', temp);
          setVisible(true);
          setReqOnEdit(false);
          setHideOnEdit(true);
          clearErrors();
        }
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Collaborators"} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onRow={onClickRow}
            Search={Search}
            onSearch={onSearch}
            ListData={users?.list?.map(x => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            ListCol={colName}
            rowSelection={rowSelection}
            pagination={{
              total: users?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}