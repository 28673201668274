import React, { useEffect, Fragment, useState } from 'react';
import { Row, Col, Form, Button, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';

const _ = require('lodash');

export default (props) => {
  const { id, addDiscount, updateDiscount, meta, data, mode, reloadApi, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [selected, setSelected] = useState();

  const formFields = [
    {
      name: 'code',
      label: 'Code',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'desc',
      label: 'Description',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'discount_method',
      label: 'Discount Method',
      req: true,
      type: 'select',
      options: meta?.discount_methods?.map((x) => ({ label: x, value: x })),
      placeholder: 'Please select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
      onChange: (e) => {
        setSelected(e.value);
      }
    },
    {
      name: 'discount_amount',
      label: 'Discount Rate',
      req: true,
      number: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'max_discount_value',
      label: 'Max Discount Value',
      req: false,
      number: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
      hidden: selected !== "Percentage"
    },
    {
      name: 'max_discount_perc',
      label: 'Max Discount Percentage',
      req: false,
      number: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
      hidden: selected === "Percentage"
    },
    {
      name: 'limit_by_qty',
      label: 'Min Item Quantity',
      req: false,
      number: true,
      nostep: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'min_net_amount',
      label: 'Minimum Net Amount',
      req: true,
      number: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'tax_included',
      label: 'Minimum Net Amount Tax Inclusive',
      req: false,
      twocol: false,
      colWidth: '0 1 50%',
      alignEnd: false, // move form to top
      labelPosition: 'top',
      static: !allowedEdit
    },
    {
      name: 'rounding_method',
      label: 'Rounding Method',
      req: false,
      type: 'select',
      options: meta?.rounding_methods?.map((x) => ({ label: x.label, value: x.value })),
      placeholder: 'Please select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'rounding_step_size',
      label: 'Rounding Step Size',
      req: false,
      type: 'select',
      options: meta?.rounding_step_sizes?.map((x) => ({ label: x, value: x })),
      placeholder: 'Please select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'is_remark_required',
      label: 'Is Remark Required?',
      req: false,
      twocol: false,
      colWidth: '0 1 50%',
      alignEnd: false, // move form to top
      labelPosition: 'top',
      static: !allowedEdit
    },
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('code', data.code);
      setValue('desc', data.desc);
      setValue('discount_method', data.discount_method ? { label: data.discount_method, value: data.discount_method } : '');
      setValue('discount_amount', data.discount_amount);
      setValue('max_discount_value', data.max_discount_value);
      setValue('max_discount_perc', data.max_discount_perc);
      setValue('min_net_amount', data.min_net_amount);
      setValue('limit_by_qty', data.limit_by_qty);
      setValue('tax_included', data.tax_included === "1");
      setValue('is_remark_required', data.is_remark_required === "1");
      setSelected(data.discount_method);
      setValue('rounding_method', data.rounding_method ? { label: meta?.rounding_methods?.find(x => x.value === data?.rounding_method)?.label, value: data.rounding_method } : '');
      setValue('rounding_step_size', data.rounding_step_size ? { label: data.rounding_step_size, value: data.rounding_step_size } : '');
    }else{
      setValue('limit_by_qty', 1);
      setValue('rounding_method', { label: meta?.rounding_methods?.[0]?.label, value: meta?.rounding_methods?.[0]?.value });
      setValue('rounding_step_size', { label: meta?.rounding_step_sizes?.[0], value: meta?.rounding_step_sizes?.[0] });
    }
  }, [data, meta]);

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      ...val,
      discount_method: val?.discount_method?.value,
      tax_included: val?.tax_included ? 1 : 0,
      is_remark_required: val?.is_remark_required ? 1 : 0,
      discount_id: id,
      rounding_method: val?.rounding_method?.value,
      rounding_step_size: val?.rounding_step_size?.value
    }

    await (mode === "add" ? addDiscount : updateDiscount)(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(`${mode === "add" ? "Created" : "Updated"} Discount successfully!`);
        reloadApi(res.data.data);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>Save</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}