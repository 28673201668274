import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Form, message, Spin } from 'antd';
import InputForm from '../components/InputForm';
import { useForm } from 'react-hook-form';
import { getMerchantDetail } from "../../ducks/actions";
import { revokeApiKey, editMerchant } from "../../ducks/services";
import { useDispatch, useSelector } from 'react-redux';
import AllRoles from '../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../routing/config/utils';

const _ = require('lodash');
const { Title, Text } = Typography;

export default (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { control, handleSubmit, formState: { errors }, setValue } = useForm();
  const merchants = useSelector((state) => state.systems.merchantDetail);
  const merchantsMeta = useSelector((state) => state.systems.merchantDetailMeta);
  const allowedEdit = allowed([AllRoles.SYSTEM.MERCHANT], 'write');

  useEffect(() => {
    dispatch(getMerchantDetail(id, props.setLoading));
  }, []);


  useEffect(() => {
    if(merchants && 'id' in merchants) {
      setValue('email', merchants.email);
      setValue('store_name', merchants.store_name);
      setValue('merchant_type', {label: merchants?.merchant_type?.toUpperCase(), value: merchants.merchant_type})
      setValue('slug', merchants.slug);
      setValue('api_key', merchants.api_key);
    }
  }, [merchants])

  const onFinish = async (val) => {
    props.setLoading(true);

    const payload = {
      merchant_id: id,
      email: val?.email,
      merchant_type: val?.merchant_type.value
    }
    await editMerchant(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Merchant updated successfully!");
        setTimeout(() => navigate("/merchant"), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const onRevoke = async () => {
    const payload = {
      merchant_id: id
    }
    await revokeApiKey(payload).then(res => {
      if(res.data.code === 200) {
        dispatch(getMerchantDetail(id, props.setLoading));
        message.success("API key revoke successfully!");
      }else {
        console.log("error");
      }
    }).catch(e => {
      console.log(e);
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row justify={'space-between'} align={'middle'}>
            <Col flex='auto'>
              <Title level={3} className='mb-0 mt-0'>Edit Merchant</Title>
            </Col>
            <Col flex='auto' className='text-right'>
              <Space size={10}>
                {
                  allowedEdit &&
                  <Button size="large" htmlType='submit' className='green-btn'>Save</Button>
                }
                <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/merchant')}>{'<'} Back</Button>
              </Space>
            </Col>
          </Row>
        </Col>
        <InputForm
          control={control}
          errors={errors}
          options={merchantsMeta}
          allowedEdit={false}
          onRevoke={onRevoke}
          allowedSave={allowedEdit}
        />
      </Row>
    </Form>
  )
}