import React, { Fragment } from 'react';

export default (props) => {
  const { columns, data } = props;

  return (
    <div className="inventory-table-container">
      <div className="inventory-table">
        <table>
          <thead>
            <tr>
              {columns?.tr_descrepancy_details_listing?.list?.map(item =>
                <th className={`${item?.longCol ? 'long-col' : ''} text-${item.align}`} key={`Title ${item.title}`}>
                  {item.title}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data?.list?.map((listItem, listItemIndex) => 
              <Fragment key={`List Item ${listItemIndex}`}>
                <tr>
                  <td>{listItem.date}</td>
                </tr>
                {listItem?.list?.map((childListItem, childListItemIndex) => 
                  <tr key={`List Item ${childListItemIndex}: Child List Item ${childListItemIndex}`}>
                    <td>{childListItem.reference}</td>
                    <td>{childListItem.origin}</td>
                    <td>{childListItem.destination}</td>
                    <td>{childListItem.item}</td>
                    <td>{childListItem.status}</td>
                    <td align='right'>{childListItem.qty}</td>
                    <td align='right'>{childListItem.confirm_qty}</td>
                    <td align='right'>{parseFloat(childListItem.out_inv_cost).toFixed(8)}</td>
                    <td align='right'>{parseFloat(childListItem.in_inv_cost).toFixed(8)}</td>
                  </tr>
                )}
                <tr className='bg-gray text-white'>
                  <td colSpan={4}></td>
                  <td>Subtotal</td>
                  {listItem?.sub_totals?.map((item, index) => 
                    <td key={`Sub Total ${item} ${index}`} align='right'>{parseFloat(item).toFixed(8)}</td>
                  )}
                </tr>
              </Fragment>
            )}

            
            <tr className='bg-gray text-white'>
              <td colSpan={4}></td>
              <td>Grand Total</td>
              {data?.grand_total?.map((item, index) => 
                <td key={`Grand Total ${item} ${index}`} align='right'>{parseFloat(item).toFixed(8)}</td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}