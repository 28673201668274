import React, { Fragment } from 'react';
import FormGroup from 'Molecules/FormGroup';

const _ = require('lodash');

export default (props) => {

  const { errors, control, meta, allowedEdit } = props

  const formFields = [
    {
      name: 'code',
      label: 'Code',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'desc',
      label: 'Description',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'kitchen_queue_type',
      label: 'Queue Type',
      req: true,
      type: 'select',
      options: meta?.queue_types?.map((x) => ({ label: x, value: x })),
      placeholder: 'Please select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'kitchen_display',
      label: 'Kitchen Display',
      req: true,
      type: 'select',
      options: meta?.kitchen_displays?.map((x) => ({ label: x, value: x })),
      placeholder: 'Please select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'slip_settings',
      label: 'Slip Settings',
      req: true,
      type: 'select',
      options: meta?.slip_settings?.map((x) => ({ label: x, value: x })),
      placeholder: 'Please select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'slip_print_mode',
      label: 'Slip Print Mode',
      req: true,
      type: 'select',
      options: meta?.slip_print_modes?.map((x) => ({ label: x, value: x })),
      placeholder: 'Please select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'is_readonly',
      label: 'Readonly Mode',
      twocol: false,
      colWidth: '1 0 50%',
      alignEnd: true,
      static: !allowedEdit
    },
  ];

  return (
    <>
      {formFields.map((item, idx) => (
        <Fragment key={idx}>
          <FormGroup item={item} control={control} errors={errors} />
        </Fragment>
      ))}
    </>
  )
}