import React, { useState, useEffect } from 'react';
import { SelectField, DateField } from 'Atoms/FormElement';
import { Col } from 'antd';
import dayjs from 'dayjs';

const dateRange = [
  {
    label: 'Today',
    value: 'today' 
  },
  {
    label: 'Yesterday',
    value: 'yesterday'
  },
  {
    label: 'Last 7 Days',
    value: 'last7Days'
  },
  {
    label: 'Last 30 Days',
    value: 'last30Days'
  },
  {
    label: 'This Week',
    value: 'thisWeek'
  },
  {
    label: 'Past Week',
    value: 'pastWeek'
  },
  {
    label: 'This Month',
    value: 'thisMonth'
  },
  {
    label: 'Past Month',
    value: 'pastMonth'
  },
  {
    label: 'This Year',
    value: 'thisYear'
  },
  {
    label: 'Past Year',
    value: 'pastYear'
  },
]

export default (props) => {
  const {control, onSetValue, placeholderValue, isRequired, errors} = props;
  const [startDate, setStartDate] = useState(dayjs())
  const [endDate, setEndDate] = useState(dayjs())

  const selectStartDate = (val) => {
    setStartDate(val)
  }

  const selectEndDate = (val) => {
    setEndDate(val)
  }

  useEffect(() => {
    if(startDate > endDate) onSetValue("end_date", "")
  }, [startDate])

  const disableAfterTodayDate = (current) => {
    return current && current > dayjs().endOf('day');
  }
  
  const disabledEndDate = (current) => {
    // Can not select days before start date
    // return current && startDate && current < startDate.endOf('day').subtract(1, 'day');

    // Disable dates before the start date and after today
    return current && startDate && (current.isBefore(startDate, 'day') || current.isAfter(dayjs(), 'day'));
  };

  const changeDateRange = (e) => {
    const filter = e.value;

    switch (filter) {
      case "today":
        onSetValue("start_date", dayjs());
        onSetValue("end_date", dayjs());
        setStartDate(dayjs())
        setEndDate(dayjs())
        break;
      case "yesterday":
        onSetValue("start_date", dayjs().subtract(1, 'day'));
        onSetValue("end_date", dayjs().subtract(1, 'day'));
        setStartDate(dayjs().subtract(1, 'day'))
        setEndDate(dayjs().subtract(1, 'day'))
        break;
      case "last7Days":
        onSetValue("start_date", dayjs().subtract(7, 'day'));
        onSetValue("end_date", dayjs());
        setStartDate(dayjs().subtract(7, 'day'))
        setEndDate(dayjs())
        break;
      case "last30Days":
        onSetValue("start_date", dayjs().subtract(30, 'day'));
        onSetValue("end_date", dayjs());
        setStartDate(dayjs().subtract(30, 'day'))
        setEndDate(dayjs())
        break;
      case "thisWeek":
        onSetValue("start_date", dayjs().startOf('week'));
        // onSetValue("end_date", dayjs().endOf('week'));
        onSetValue("end_date", dayjs());
        setStartDate(dayjs().startOf('week'))
        setEndDate(dayjs())
        // setEndDate(dayjs().endOf('week'))
        break;
      case "pastWeek":
        onSetValue("start_date", dayjs().subtract(1, 'week').startOf('week'));
        onSetValue("end_date", dayjs().subtract(1, 'week').endOf('week'));
        setStartDate(dayjs().subtract(1, 'week').startOf('week'))
        setEndDate(dayjs().subtract(1, 'week').endOf('week'))
        break;
      case "thisMonth":
        onSetValue("start_date", dayjs().startOf('month'));
        // onSetValue("end_date", dayjs().endOf('month'));
        onSetValue("end_date", dayjs());
        setStartDate(dayjs().startOf('month'))
        // setEndDate(dayjs().endOf('month'))
        setEndDate(dayjs())
        break;
      case "pastMonth":
        onSetValue("start_date", dayjs().subtract(1, 'month').startOf('month'));
        onSetValue("end_date", dayjs().subtract(1, 'month').endOf('month'));
        setStartDate(dayjs().subtract(1, 'month').startOf('month'))
        setEndDate(dayjs().subtract(1, 'month').endOf('month'))
        break;
      case "thisYear":
        onSetValue("start_date", dayjs().startOf('year'));
        // onSetValue("end_date", dayjs().endOf('year'));
        onSetValue("end_date", dayjs());
        setStartDate(dayjs().startOf('year'))
        // setEndDate(dayjs().endOf('year'))
        setEndDate(dayjs())
        break;
      case "pastYear":
        onSetValue("start_date", dayjs().subtract(1, 'year').startOf('year'));
        onSetValue("end_date", dayjs().subtract(1, 'year').endOf('year'));
        setStartDate(dayjs().subtract(1, 'year').startOf('year'))
        setEndDate(dayjs().subtract(1, 'year').endOf('year'))
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Col flex="auto">
        <SelectField
          fieldname='dateRange'
          label=''
          class='mb-0 w-100 default-select'
          initValue={''}
          control={control}
          iProps={{ placeholder: 'Date Range' }}
          selectOption={dateRange}
          onChange={changeDateRange}
        />
      </Col>
      <Col flex="auto">
        <DateField
          fieldname='start_date'
          label=''
          initValue={''}
          control={control}
          class='mb-0 w-100'
          iProps={{ placeholder: `${placeholderValue} (From)`, size: 'large' }}
          rules={{
            required: isRequired && 'Please select start date'
          }}
          validate={errors?.start_date && 'error'}
          validMessage={errors?.start_date && errors?.start_date.message}
          onChange={selectStartDate}
          disabledDate={disableAfterTodayDate}
        />
      </Col>
      <Col flex="auto">
        <DateField
          fieldname='end_date'
          label=''
          class='mb-0 w-100'
          initValue={''}
          control={control}
          iProps={{ placeholder: `${placeholderValue} (To)`, size: 'large' }}
          rules={{
            required: isRequired && 'Please select end date'
          }}
          validate={errors?.end_date && 'error'}
          validMessage={errors?.end_date && errors?.end_date.message}
          onChange={selectEndDate}
          disabledDate={disabledEndDate}
        />
      </Col>
    </>
  );
};
