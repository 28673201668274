import React from 'react';
import { Button, Form, Row, Col } from 'antd';
import { InputField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';

export default (props) => {
  const { searchVal } = props;
  const { control, handleSubmit } = useForm()

  const onSubmit = (val) => {
    props.onSearch(val);
  }

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100 inline-form'>
      <Row className='w-100' gutter={[10, 10]}>
        <Col flex="auto">
          <InputField
            fieldname='code'
            label=''
            class='mb-0 w-100'
            initValue={searchVal?.code ? searchVal?.code : ''}
            control={control}
            iProps={{ placeholder: 'Search by code..' }}
          />
        </Col>
        {/* <Col flex="auto">
          <InputField
            fieldname='symbol'
            label=''
            class='mb-0 w-100'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'Search by description..' }}
          />
        </Col> */}
        <Col flex="70px">
          <Button className='w-100' size='large' type='primary' htmlType='submit'>Search</Button>
        </Col>
      </Row>
    </Form>
  )
}