import axios from 'Services/axiosInterceptor';
import * as action_types from './constants';
import { externalBaseUrl } from '../../../../configs/constants';

export const getOutlets = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/locations/list`, {
      q: JSON.stringify({
        code_cont: search?.code,
        id_eq: search?.name,
        location_group_code_cont: search?.group,
        product_menu_code_cont: search?.menu,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.OUTLET_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getOutletOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/locations/option`);
    load && load(false);
    dispatch({
      type: action_types.OUTLET_OPTION,
      data: data,
    });
  };
};

export const getOutlet = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/locations/get`, {
      id: id
    });
    load && load(false);
    dispatch({
      type: action_types.OUTLET_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getOutletGroups = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/location-groups/list`, {
      q: JSON.stringify({
        code_cont: search?.code,
        desc_cont: search?.name,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.OUTLET_GROUP_LIST,
      data: data,
    });
  };
};

export const getOutletGroup = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/location-groups/get`, {
      location_group_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.OUTLET_GROUP_DETAIL,
      data: data,
    });
  };
};

export const updateMenu = (stat) => {
  return (dispatch) => {
    dispatch({
      type: action_types.MENU_STAT,
      data: stat,
    });
  };
};

export const onClear = () => {
  return { type: action_types.DESTROY_SESSION };
};

export const getOutletUserList = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/locations/list`, {
      q: JSON.stringify({
        name_cont: search?.name,
        email_cont: search?.email,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.OUTLET_POS_USER_LIST,
      data: data,
    });
  };
};

export const getOutletPosUserList = (page, limit, order, orderby, search = {}, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/locations/pos-user-list`, {
      q: JSON.stringify({
        user_name_cont: search?.name,
        email_cont: search?.email,
        s: `${orderby} ${ordering}`
      }),
      id: id,
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.OUTLET_POS_USER_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getBlockedOrderTypeList = (page, limit, order, orderby, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/locations/blocked-order-type-list`, {
      q: JSON.stringify({
        s: `${orderby} ${ordering}`
      }),
      id: id,
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.BLOCKED_ORDER_TYPE_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getBlockedProductList = (page, limit, order, orderby, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/locations/blocked-product-list`, {
      q: JSON.stringify({
        s: `${orderby} ${ordering}`
      }),
      id: id,
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.BLOCKED_PRODUCT_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getUploadFiles = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/locations/upload-file-list`, {
      q: JSON.stringify({
        status_eq: search?.status == "success" ? "active" : search?.status,
        s: `${orderby} ${ordering}`
      }),
      outlet: search?.outlet,
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.OUTLET_UPLOAD_FILES,
      data: data,
      meta: meta
    });
  };
};

export const getFaSyncLogs = (page, limit, order, orderby, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/locations/fa-sync-log-list`, {
      q: JSON.stringify({
        s: `${orderby} ${ordering}`
      }),
      id: id,
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.FA_SYNC_LOG_LIST,
      data: data,
      meta: meta
    });
  };
};