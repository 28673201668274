import React, { useState } from 'react';
import { Row, Col, Typography, Form, Button, message, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { InputField } from '../../atoms/FormElement';
import axios from 'Services/axiosInterceptor';
import { externalBaseUrl } from '../../../configs/constants';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { control, formState: { errors }, handleSubmit } = useForm();

  const onFinish = (values) => {
    setLoading(true);
    axios
      .post(`${externalBaseUrl}/users/forgot-password`, {
        email: values.email
      })
      .then((response) => {
        setLoading(false);
        if(response.data.code === 200) {
          message.success(response.data.message);
          navigate('/login')
        }else {
          message.error(response.data.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        message.error(e.message ?? 'Something went wrong');
      });
  };
  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row gutter={[30, 24]}>
        <Col span={24}>
          <Title level={3} className="text-center mb-0">
            Forgot Password
          </Title>
        </Col>
        <Col span={24}>
          <Form name="normal_forget" onFinish={handleSubmit(onFinish)}>
            <InputField
              fieldname="email"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                placeholder: "Email Address"
              }}
              rules={{
                required: 'Please input email',
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: 'Input email without space!',
                },
              }}
              validate={errors.email && 'error'}
              validMessage={errors.email && errors.email.message}
            />
            <Button type="primary" htmlType="submit" className="w-100" size="large">
              Reset Password
            </Button>
          </Form>
        </Col>
        <Col span={24} className="text-center">
          <Link to="/login">Back to Login</Link>
        </Col>
      </Row>
    </Spin>
  );
};
