import React, { useEffect, Fragment, useState } from 'react';
import { Form, Button, Row, Col, message, Spin, Space, Typography } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { updateMallIntegration, generateMallIntegration } from '../../../../ducks/services';

const _ = require('lodash');
const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const { meta, activeTab, data, navigate, addOutlet, editOutlet, mode, id, reloadApi, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const allowedGenerate = queryParameters.get('gen') === "1";
  // const isOutletTab = activeTab === "1";
  // const isSuperAdmin = localStorage.getItem("is_superadmin") === "1";
  // const [visible, setVisible] = useState(false);
  // const [loading, setLoading] = useState(false);

  const formFields = [
    {
      name: 'ftp_server_ip',
      label: 'FTP IP',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'ftp_server_login',
      label: 'FTP Server Login',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'ftp_server_password',
      label: 'FTP Server Password',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'ftp_server_port',
      label: 'FTP Server Port',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'ftp_server_file_dir',
      label: 'FTP Server Folder',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'ftp_server_file_format',
      label: 'FTP Server File Format',
      req: true,
      placeholder: 'Please select',
      type: 'select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      class: 'white-select',
      options: _.map(meta?.malls, (e) => ({ label: e, value: e })),
      static: !allowedEdit
    },
    {
      name: 'machine_id',
      label: 'Machine ID/Tenant Code',
      req: false,
      placeholder: 'Please enter',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: !allowedEdit,
      count: 9
    },
    {
      name: 'mall_code',
      label: 'Mall Code',
      hidden: meta?.country !== "Singapore",
      req: false,
      placeholder: 'Please enter',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: !allowedEdit
    },
    {
      name: 'till_no',
      label: 'Till No.',
      hidden: meta?.country !== "Singapore",
      req: false,
      placeholder: 'Please enter',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: !allowedEdit
    },
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('ftp_server_file_dir', data.ftp_server_file_dir);
      setValue('ftp_server_file_format', data.ftp_server_file_format ? { label: data.ftp_server_file_format, value: data.ftp_server_file_format} : '');
      setValue('ftp_server_ip', data.ftp_server_ip);
      setValue('ftp_server_login', data.ftp_server_login);
      setValue('ftp_server_password', data.ftp_server_password);
      setValue('ftp_server_port', data.ftp_server_port);
      setValue('machine_id', data.machine_id);
      setValue('mall_code', data.mall_code);
      setValue('till_no', data.till_no);
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      ...val,
      ftp_server_file_format: val?.ftp_server_file_format?.value,
      id: id
    }

    await updateMallIntegration(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Mall integrated successfully!");
        // setTimeout(() => navigate("/outlets"), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  const onGenerate = async () => {
    props.setLoading(true);
    const payload = {
      id: id,
      manual_generate: "1"
    }

    await generateMallIntegration(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Mall Sales has been generated!");
        // setTimeout(() => navigate("/outlets"), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  }

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)} scrollToFirstError>
        <Row gutter={[20, 30]}>
          {formFields.map((item, idx) => (
            <Fragment key={idx}>
              <FormGroup item={item} control={control} errors={errors} />
            </Fragment>
          ))}
        </Row>
        {
          allowedEdit &&
          <Row gutter={[10, 10]} className="mt-1">
            <Col span={24} className='text-right'>
              {
                allowedGenerate &&
                <Button size="large" htmlType='button' className='green-btn attendance-success mr-2' onClick={() => onGenerate()}>Generate Mall Sales</Button>
              }
              <Button size="large" htmlType='submit' className='green-btn attendance-success'>Save</Button>
            </Col>
          </Row>
        }
      </Form>
    </>
  )
}