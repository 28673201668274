import React from 'react';
import { Button, Form, Space, Typography } from 'antd';
import { SelectField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';

export default (props) => {
  const { control, handleSubmit } = useForm();

  const onSubmit = (val) => {
    props.onSearch(val);
  };

  return (
    <Space size={15} direction="vertical" className="w-100">
      <Form onFinish={handleSubmit(onSubmit)} layout="inline" className="w-100 inline-form">
        <SelectField
          fieldname="sub_category"
          class="mb-0 w-100 default-select"
          label=""
          control={control}
          iProps={{ placeholder: 'Family Group...' }}
          selectOption={props.field1}
        />
        <Button size="large" type="primary" htmlType="submit">
          Search
        </Button>
      </Form>
    </Space>
  );
};
