import React, { useEffect, useState, Fragment } from 'react';
import { useNavigate, useParams, useLocation, useSearchParams, Link } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Spin, message, Form } from 'antd';
import AddForm from '../components/AddForm';
import { getPurchaseOrder, reloadPurchaseOrder } from "../../ducks/actions";
import { editPurchaseOrder, removePurchaseOrder, exportPoPdf, generateInventoryNo, duplicatePo, sendEmail, createGR } from "../../ducks/services";
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { CheckOutlined, ExportOutlined, CopyOutlined, SendOutlined, PlusOutlined } from '@ant-design/icons';
import AllRoles from '../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../routing/config/utils';
import { useForm } from 'react-hook-form';
import FormGroup from 'Molecules/FormGroup';
import { Popup } from 'Atoms/Popup';

const { Title, Text } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation().pathname;
  const data = useSelector((state) => state.inventory.purchaseOrder);
  const meta = useSelector((state) => state.inventory.option);
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("1");
  const [load, setLoad] = useState(false);
  const allowedEdit = allowed([AllRoles.INVENTORY.PURCHASE_ORDER], 'write');
  const allowedDelete = allowed([AllRoles.INVENTORY.PURCHASE_ORDER], 'delete');
  const { control, formState: { errors }, handleSubmit, setValue, clearErrors } = useForm();
  const [visible, setVisible] = useState(false);
  const [editable, setEditable] = useState(true);

  const formFields = [
    {
      name: 'remarks',
      label: 'Remarks',
      placeholder: 'Please state',
      type: 'input',
      reqmessage: 'Required',
      req: true,
    }
  ];

  useEffect(() => {
    callApi();
  }, []);

  const callApi = () => {
    dispatch(getPurchaseOrder(id, setLoad));
  };

  const reloadApi = (data) => {
    dispatch(reloadPurchaseOrder(data, meta));
  }

  useEffect(() => {
    if (searchParams.get('t')) {
      setActiveTab(searchParams.get('t'));
    }
  }, [searchParams])

  const setActive = (tab) => {
    navigate(`${location}?t=${tab}`);
  }

  const onAction = async (statusEvent, remark = null) => {
    props.setLoading(true);

    const payload = {
      purchase_order_id: data?.id,
      status_event: statusEvent
    }

    if(remark) payload.cancellation_remarks = remark

    await removePurchaseOrder(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(res.data.message);
        reloadApi(res.data.data)
      }else{
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Please try again.");
    })
  }

  const onDuplicatePo = async () => {
    props.setLoading(true);

    const payload = {
      purchase_order_id: data?.id,
    }

    await duplicatePo(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Purchase Order Duplicated Successfully.");
        setTimeout(() => {
          navigate(`/inventory/purchase-order/${res.data.data}`);
          window.location.reload();
        }, 500);
      }else{
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onSendEmail = async () => {
    props.setLoading(true);

    const payload = {
      purchase_order_id: data?.id,
    }

    await sendEmail(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Email Resent Successfully.");
      }else{
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const exportPdf = async () => {
    props.setLoading(true);

    await exportPoPdf(id).then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    })
  };

  const openCancelPopUp = () => {
    setVisible(true);
    setValue('remarks', data?.remarks);
    clearErrors();
  }

  const onFinish = async (val) => {
    onAction('cancel', val?.remarks)
    setVisible(false);
  };

  const onCreateGR = async (poId) => {
    props.setLoading(true);

    const payload = {
      purchase_order_id: poId
    }

    await createGR(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("GR Created Successfully!");
        setTimeout(() => navigate(`/inventory/good-receive/${res.data.data}?isRedirected=true`), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const cancelPopUp = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            Cancel PO
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('remarks', '');
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 650,
    onCancel: () => {
      setVisible(false);
      setValue('remarks', '');
    },
  };

  return (
    <>
      <Spin indicator={antIcon} size="large" spinning={load}>
        <Row gutter={[20, 20]}>
          <Col span={24} style={{ position: "sticky", top: "-30px", backgroundColor: "#f5f5f5", zIndex: 3, padding: "10px" }}>
            <Row justify={'space-between'} align={'middle'}>
              <Col flex='auto'>
                <Title level={3} className='mb-0 mt-0'>
                  {data?.reference}
                  {" (Status: "}
                  <span className={data?.status === "confirmed" ? "c-success" : data?.status === "pending" ? "c-pending" : "c-danger"}>
                    {data?.status?.[0]?.toUpperCase()}{data?.status?.slice(1)}
                  </span>
                  {")"}
                  {
                    data?.stock_in_id &&
                    <Link className='ml-1' to={`/inventory/good-receive/${data?.stock_in_id}`}>{">"} GRN</Link>
                  }
                </Title>
              </Col>
              <Col flex='auto' className='text-right'>
                <Space size={10}>
                  {
                    allowed([AllRoles.INVENTORY.PURCHASE_ORDER], 'read') &&
                    <Button size="medium" htmlType='button' className='green-btn' onClick={() => exportPdf()}>
                      <ExportOutlined /> Export PO
                    </Button>
                  }
                  {
                    data?.can_create_gr && allowedEdit && allowed([AllRoles.INVENTORY.GOOD_RECEIVE], 'write') &&
                    (
                      <Button size="medium" htmlType='button' className='c-success' onClick={() => onCreateGR(data.id)}>
                        <PlusOutlined /> Create GR
                      </Button>
                    )
                  }
                  {
                    (data?.status !== "cancelled" && data?.status !== 'pending') && allowedEdit &&
                    <Button size="medium" htmlType='button' className='green-outlined-btn' onClick={() => onDuplicatePo()}>
                      <CopyOutlined /> Duplicate PO
                    </Button>
                  }
                  {
                    data?.can_resend_email && allowedEdit &&
                    <Button size="medium" htmlType='button' className='pending-outlined-btn' onClick={() => onSendEmail()}>
                      <SendOutlined /> Resend Email
                    </Button>
                  }
                  {
                    data?.status === "pending" && allowedEdit &&
                    data?.item_count > 0 &&
                    <Button size="medium" htmlType='button' className='green-btn' onClick={() => onAction('confirm')}>
                      <CheckOutlined /> Confirm PO
                    </Button>
                  }
                  {
                    (data?.status !== "cancelled" && data?.status !== 'pending' && data?.status !== 'closed') && allowedEdit &&
                    !data?.within_grace_period &&
                    <Button size="medium" htmlType='button' danger onClick={() => openCancelPopUp()}>
                      Cancel PO
                    </Button>
                  }
                  {
                    editable && activeTab === "1" &&
                    <Button size="medium" htmlType='submit' className='green-btn' form="po-details-form">Save</Button>
                  }
                  <Button size="medium" htmlType='button' className='red-btn' onClick={() => navigate('/inventory/purchase-order')}>{'<'} Back</Button>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <AddForm
              loading={props.loading}
              setLoading={setLoad}
              activeTab={activeTab}
              setActiveTab={setActive}
              data={data}
              meta={meta}
              id={id}
              editPurchaseOrder={editPurchaseOrder}
              reloadApi={reloadApi}
              reloadCount={callApi}
              searchParams={searchParams}
              mode={'edit'}
              navigate={navigate}
              allowedEdit={allowedEdit}
              allowedDelete={allowedDelete}
              generateInventoryNo={generateInventoryNo}
              editable={editable}
              setEditable={setEditable}
            />
          </Col>
        </Row>
      </Spin>
      <Popup {...cancelPopUp} />
    </>
  )
}