import React, { useEffect, useState } from 'react';
import { Row, Col, Button, message } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStockTemplateList } from '../ducks/actions'
import { removeStockTemplate, bulkRemoveStockTemplates } from '../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search'
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';

function removeUnderscoreAndCapitalize(value) {
  if(value) return value.split("_").map(item => `${item.slice(0, 1).toUpperCase()}${item.slice(1)}`).join(' ')
  return value
}

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.inventory.stockTemplates);
  const meta = useSelector((state) => state.inventory.stockTemplatesOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState(null);
  const allowedEdit = allowed([AllRoles.INVENTORY.STOCK_TEMPLATE], 'write');
  const allowedDelete = allowed([AllRoles.INVENTORY.STOCK_TEMPLATE], 'delete');
  const [statuses, setStatuses] = useState([]);
  const [stockTemplateTypes, setStockTemplateTypes] = useState([]);
  const globalListQuery = useSelector((state) => state.global.listQueryItems);

  const colName = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Stock Template Type',
      dataIndex: 'stock_template_type',
      key: 'stock_template_type',
      className: 'enable-click',
      sorter: true,
      render: (text) => removeUnderscoreAndCapitalize(text)
    },
    {
      title: 'Created By',
      dataIndex: 'created_by_name',
      key: 'created_by_name',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Active" ? "c-success" : "c-danger"}>
            {text}
          </span>
          {" "}
          {
            allowedEdit &&
            <Button type="link" onClick={() => onAction(record.id, text)}>
              {text === "Active" ? "(Deactivate)" : "(Activate)"}
            </Button>
          }
        </div>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="Stock Template"
          btnAction1={() => navigate(`/inventory/stock-template/${record.id}`)}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ]

  const addNew = () => navigate('/inventory/stock-template/add');

  const btnList = [
    {
      title: 'Are you sure to remove the selected Stock Template(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New Stock Template',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    if(!globalListQuery.stockTemplateListQuery) {
      dispatch(getStockTemplateList(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('stockTemplateListQuery', 1, limit, '', '', null, props.setLoading));
    } else {
      const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.stockTemplateListQuery
      dispatch(getStockTemplateList(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }, []);

  useEffect(() => {
    if(meta) {
      if ("statuses" in meta) {
        let temp2 = [];
        meta.statuses?.map((x, ind) => {
          if (ind === 0) {
            temp2.push({
              label: "All Status",
              value: ""
            });
          }
          temp2.push({
            label: x,
            value: x
          })
        });
        setStatuses(temp2);
      }

      if ("stock_template_types" in meta) {
        let temp3 = [];
        meta.stock_template_types?.map((x, ind) => {
          if (ind === 0) {
            temp3.push({
              label: "All Stock Template Types",
              value: ""
            });
          }
          temp3.push({
            label: removeUnderscoreAndCapitalize(x),
            value: x
          })
        });
        setStockTemplateTypes(temp3);
      }
    }
  }, [meta])

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const onAction = async (templateId, status) => {
    props.setLoading(true);

    const payload = {
      stock_template_id: templateId,
      status_event: status === "Active" ? "deactivate" : "activate"
    }

    await removeStockTemplate(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Stock Template status has been updated successfully!");
        setTimeout(() => dispatch(getStockTemplateList(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('stockTemplateListQuery', page, limit, '', '', searchVal, props.setLoading))
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onRemove = async (templateId) => {
    props.setLoading(true);

    const payload = {
      stock_template_id: templateId,
      status_event: 'remove'
    }

    await removeStockTemplate(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Stock Template has been removed successfully!");
        setTimeout(() => dispatch(getStockTemplateList(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('stockTemplateListQuery', page, limit, '', '', searchVal, props.setLoading))
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      stock_templates: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveStockTemplates(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Stock Templates has been removed successfully!");
        setPage(1);
        setSearchVal(null);
        setTimeout(() => dispatch(getStockTemplateList(1, limit, '', '', null, props.setLoading)), 500);
        dispatch(getListQueryItems('stockTemplateListQuery', 1, limit, '', '', null, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getStockTemplateList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
      dispatch(getListQueryItems('stockTemplateListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading))
    } else {
      dispatch(getStockTemplateList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
      dispatch(getListQueryItems('stockTemplateListQuery', pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading))
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        code: search?.code,
        stock_template_type: search.stock_template_types.value,
        status: search.status.value,
      };
      setSearchVal(searching);
      dispatch(getStockTemplateList(1, limit, '', '', searching, props.setLoading));
      dispatch(getListQueryItems('stockTemplateListQuery', 1, limit, '', '', searching, props.setLoading))
    } else {
      setSearchVal(null);
      dispatch(getStockTemplateList(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('stockTemplateListQuery', 1, limit, '', '', null, props.setLoading))
    }
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click"))
          navigate(`/inventory/stock-template/${record.id}`)
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Stock Templates"} btnList={allowedEdit && btnList} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            Search={Search}
            onSearch={onSearch}
            searchVal={globalListQuery.stockTemplateListQuery?.searchValue}
            onRow={onClickRow}
            // listClass="nopad"
            ListData={orders?.list?.map(x => ({ ...x, key: x.id }))}
            ListCol={colName}
            rowSelection={allowedEdit && rowSelection}
            onChange={onTableChange}
            pagination={{
              total: orders?.total_count,
              current: page,
              pageSize: limit
            }}
            field1={statuses}
            field2={stockTemplateTypes}
          />
        </Col>
      </Row>
    </>
  )
}