import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Empty } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import FormGroup from 'Molecules/FormGroup';
import { useDispatch, useSelector } from 'react-redux';
import { addKitchenQueueAlert, editKitchenQueueAlert, removeKitchenQueueAlert } from '../../../../../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, data, reloadApi, allowedEdit, allowedDelete } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const [visible, setVisible] = useState(false);
  const [load, setLoad] = useState(false)
  
  const colName = [
    {
      title: 'Alert Timer in second(s)',
      dataIndex: 'alert_timer',
      key: 'alert_timer',
      className: 'enable-click',
      sorter: (a,b) => a.alert_timer - b.alert_timer
    },
    {
      title: 'Color Code',
      dataIndex: 'color_code',
      key: 'color_code',
      className: 'enable-click',
      sorter: (a,b) => a.color_code.localeCompare(b.color_code),
      render: (text) => <Space size={10}>
        <div style={{ width: '20px', height: '20px', backgroundColor: text?.includes("#") ? text : `#${text}` }}></div>
        {text}
      </Space>
    },
    {
      title: 'Backgroud Color Opacity (%)',
      dataIndex: 'bg_color_opacity',
      key: 'bg_color_opacity',
      className: 'enable-click',
      sorter: (a,b) => a.bg_color_opacity - b.bg_color_opacity
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 200,
      render: (text, record) =>
        <ActionButton
          title="Kitchen Queue Alert"
          btnAction1={() => {
            setValue('id', record?.id);
            setValue('alert_timer', record?.alert_timer);
            setValue('color_code', record.color_code ? record.color_code : "" );
            setValue('bg_color_opacity', record?.bg_color_opacity);
            setVisible(true);
          }}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={setLoad}
          loading={load}
        />
    }
  ];

  const addNew = () => { 
    setVisible(true);
    setValue('id', null);
    setValue('alert_timer', '');
    setValue('color_code', '');
    setValue('bg_color_opacity', '');
  }

  const btnList = [
    {
      text: '+ New Kitchen Queue Alert',
      classes: 'attendance-success',
      action: () => addNew(),
    },
  ];

  const formFields = [
    {
      name: 'id',
      label: '',
      type: 'input',
      hidden: true,
    },
    {
      name: 'alert_timer',
      label: 'Alert Timer in second(s)',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      number: true,
      nostep: true,
      static: !allowedEdit,
    },
    {
      name: 'color_code',
      label: 'Color Code',
      req: true,
      placeholder: 'Please state',
      type: 'color',
      showText: true,
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit,
    },
    {
      name: 'bg_color_opacity',
      label: 'Backgroud Color Opacity (%)',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      number: true,
      nostep: true,
      min: 0,
      max: 100,
      static: !allowedEdit,
      onChange: (e) => {
        if(+e.target.value > 100) setValue('bg_color_opacity', 100)
      }
    },
  ];

  const onRemove = async (recordId) => {
    setLoad(true);

    const payload = {
      kitchen_queue_alert_id: recordId,
      id: id,
      status_event: 'remove'
    }

    await removeKitchenQueueAlert(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success("Kitchen Queue Alert has been removed successfully!");
        reloadApi()
      }
    }).catch(e => {
      setLoad(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onFinish = async (val) => {
    setLoad(true);
    const payload = {
      alert_timer: val?.alert_timer,
      color_code: val?.color_code,
      bg_color_opacity: val?.bg_color_opacity,
      kitchen_queue_alert_id: val?.id,
      id: id
    }

    await (val?.id ? editKitchenQueueAlert : addKitchenQueueAlert)(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success(`${val?.id ? 'Updated' : 'Added'} Kitchen Queue Alert successfully.`);
        setVisible(false);
        setValue('id', null);
        setValue('alert_timer', '');
        setValue('color_code', '');
        setValue('bg_color_opacity', '');
        reloadApi()
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={15} className='w-100'>
          <Title level={4} className='m-0'>
            {getValues('id') ? 'Edit' : 'Add'} Kitchen Queue Alert
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setValue('id', null);
      setValue('alert_timer', '');
      setValue('color_code', '');
      setValue('bg_color_opacity', '');
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click")) {
          setValue('id', record?.id);
          setValue('alert_timer', record?.alert_timer);
          setValue('color_code', record.color_code ? record.color_code : "" );
          setValue('bg_color_opacity', record?.bg_color_opacity);
          setVisible(true);
        }
      },
    };
  }

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={""} btnList={allowedEdit ? btnList : null} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onRow={onClickRow}
            classes="nopad"
            ListData={data.kitchen_queue_alerts?.map(x => ({ ...x, key: x.id }))}
            ListCol={colName}
            pagination={false}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}