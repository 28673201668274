import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Typography } from 'antd';
import { SelectField, InputField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import DateRangeDropdown from 'Modules/Application/components/DateRangeDropdown';

const { Title } = Typography;

export default (props) => {

  const { searchTitle } = props;
  const { control, handleSubmit, setValue, getValues } = useForm();
  const [productOption, setProductOption] = useState([]);

  useEffect(() => {
    if(props) {
      if (!getValues().is_condiment_required) setValue('is_condiment_required', props.field3[0]);
      if(productOption.length == 0) setProductOption(props.field2);
      if (!getValues().start_date) {
        setValue('start_date', dayjs());
        setValue('dateRange', { label: 'Today', value: 'today' });
      }
      if (!getValues().end_date) {
        setValue('end_date', dayjs());
      }
    }
  }, [props])

  const onSubmit = (val) => {
    props.onSearch(val);
  }

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100'>
      <Row className='w-100' gutter={[10, 10]}>
        <Col flex='auto'>
          <SelectField
            fieldname='outlets'
            label=''
            class='mb-0 w-100 default-select'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'All Outlets', isMulti: true }}
            selectOption={props.field1}
          />
        </Col>
        <Col flex='auto'>
          <SelectField
            fieldname='order_type'
            label=''
            class='mb-0 w-100 default-select'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'Order Type' }}
            selectOption={props.field5}
            isClearable={true}
          />
        </Col>
        <Col flex='auto'>
          <SelectField
            fieldname='non_sales'
            label=''
            class='mb-0 w-100 default-select'
            initValue={{ label: "Non Sales: All", value: "all" }}
            control={control}
            selectOption={[{
              label: "Non Sales: All",
              value: "all"
            },{
              label: "Non Sales: No",
              value: "no"
            }, {
              label: "Non Sales: Yes",
              value: "yes"
            }]}
            iProps={{ placeholder: 'Is Non Sales' }}
          />
        </Col>
        <Col flex='auto'>
          <SelectField
            fieldname='is_condiment_required'
            label=''
            class='mb-0 w-100 default-select'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'Listing Type' }}
            selectOption={props.field3}
            onChange={(e) => {
              if(e?.value === 1) {
                setProductOption(props.field2);
              }else{
                setProductOption(props.field4);
              }
            }}
          />
        </Col>
        <Col flex='auto'>
          <SelectField
            fieldname='products'
            label=''
            class='mb-0 w-100 default-select'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'Item', isMulti: true }}
            selectOption={productOption}
          />
        </Col>
        
        <Col flex='auto'>
          <SelectField
            fieldname='status'
            label=''
            class='mb-0 w-100 default-select'
            initValue={{ label: "Completed", value: "paid" }}
            control={control}
            iProps={{ placeholder: 'Status' }}
            selectOption={[{
              label: "Completed",
              value: "paid"
            }, {
              label: "Refunded",
              value: "refunded"
            }]}
          />
        </Col>
      </Row>
      <Row className='w-100 mt-1' gutter={[10, 10]}>
        <Col flex='auto'>
          <InputField
            fieldname='order_no'
            label=''
            class='mb-0 w-100'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'Order No' }}
          />
        </Col>
        <DateRangeDropdown control={control} onSetValue={setValue} placeholderValue='Business Date' isRequired={false} />

        <Col flex="70px">
          <Button className='w-100' size='large' type='primary' htmlType='submit'>Search</Button>
        </Col>
      </Row>
      {searchTitle &&
        <Row className='mt-1'>
          <Col>
            <Title level={4} className='mb-0 c-gray'> {">"} {searchTitle}</Title>
          </Col>
        </Row>
      }
    </Form>
  )
}