import React, { useEffect, useState } from 'react';
import { Row, Col, Button, message } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getVoucherTypes } from '../ducks/actions'
import { removeVoucherType, bulkRemoveVoucherTypes } from '../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search'
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const voucherTypes = useSelector((state) => state.pos.voucherTypes);
  const meta = useSelector((state) => state.pos.vtOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState(null);
  const [valueTypes, setValueTypes] = useState([]);
  const allowedEdit = allowed([AllRoles.POS.VOUCHER_TYPE], 'write');
  const allowedDelete = allowed([AllRoles.POS.VOUCHER_TYPE], 'delete');
  const globalListQuery = useSelector((state) => state.global.listQueryItems);

  const colName = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 200,
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Submitted" ? "c-success" : "c-pending"}>
            {text}
          </span>
          {
            allowedEdit &&
            <Button type="link" onClick={() => onAction(record.id, text)}>
              {text === "Submitted" ? "(Pending)" : "(Submit)"}
            </Button>
          }
        </div>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 150,
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="Voucher Type"
          btnAction1={record.status === "Pending" ? () => navigate(`/pos/voucher-type/${record.id}`) : false}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ]

  const addNew = () => navigate('/pos/voucher-type/add');

  const btnList = [
    {
      title: 'Are you sure to remove the selected Voucher Type(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New Voucher Type',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    if(!globalListQuery.voucherTypeListQuery) {
      dispatch(getVoucherTypes(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('voucherTypeListQuery', 1, limit, '', '', null, props.setLoading));
    } else {
      const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.voucherTypeListQuery
      dispatch(getVoucherTypes(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }, []);

  useEffect(() => {
    if(meta) {
      if ('voucher_types' in meta) {
        let temp = [];
        meta.voucher_types.map((x, index) => {
          if(index === 0) {
            temp.push({
              label: 'All Types',
              value: ''
            })
          }
          temp.push(
            {
              label: x,
              value: x
            }
          )
        });
        setValueTypes(temp);
      }
    }
  }, [meta]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onAction = async (vtId, status) => {
    props.setLoading(true);

    const payload = {
      voucher_type_id: vtId,
      status_event: status === 'Submitted' ? 'to_pending' : 'submit'
    }

    await removeVoucherType(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Voucher Type status has been updated successfully!");
        setTimeout(() => dispatch(getVoucherTypes(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('voucherTypeListQuery', page, limit, '', '', searchVal, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onRemove = async (id) => {
    props.setLoading(true);

    const payload = {
      voucher_type_id: id,
      status_event: 'remove'
    }

    await removeVoucherType(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Voucher Type has been removed successfully!");
        setTimeout(() => dispatch(getVoucherTypes(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('voucherTypeListQuery', page, limit, '', '', searchVal, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      voucher_types: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveVoucherTypes(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Voucher Types has been removed successfully!");
        setPage(1);
        setSearchVal(null);
        setTimeout(() => dispatch(getVoucherTypes(1, limit, '', '', null, props.setLoading)), 500);
        dispatch(getListQueryItems('voucherTypeListQuery', 1, limit, '', '', null, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getVoucherTypes(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
      dispatch(getListQueryItems('voucherTypeListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading))
    } else {
      dispatch(getVoucherTypes(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
      dispatch(getListQueryItems('voucherTypeListQuery', pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading))
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        name: search.code,
        value_type: search.value_type?.value
      };
      setSearchVal(searching);
      dispatch(getVoucherTypes(1, limit, '', '', searching, props.setLoading));
      dispatch(getListQueryItems('voucherTypeListQuery', 1, limit, '', '', searching, props.setLoading))
    } else {
      setSearchVal(null);
      dispatch(getVoucherTypes(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('voucherTypeListQuery', 1, limit, '', '', null, props.setLoading))
    }
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click"))
          navigate(`/pos/voucher-type/${record.id}`)
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Voucher Types"} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onRow={onClickRow}
            Search={Search}
            onSearch={onSearch}
            searchVal={globalListQuery.voucherTypeListQuery?.searchValue}
            ListData={voucherTypes?.list?.map(x => ({ ...x, key: x.id }))}
            ListCol={colName}
            rowSelection={allowedEdit && rowSelection}
            onChange={onTableChange}
            field1={valueTypes}
            pagination={{
              total: voucherTypes?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
    </>
  )
}