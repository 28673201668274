import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Typography, message, Empty } from 'antd';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getSalesDetails } from '../ducks/actions'
import { exportSalesDetail } from '../ducks/services'
import Search from './components/Search';
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { comma } from 'Features/utility';

const { Title, Text } = Typography;
const _ = require('lodash');

export default (props) => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.reporting.salesDetails);
  const meta = useSelector((state) => state.reporting.salesDetailsOption);
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const [products, setProducts] = useState([]);
  const [itemProducts, setItemProducts] = useState([]);
  const [orderTypes, setOrderTypes] = useState([]);

  const columns = [
    {
      title: 'Outlet',
      dataIndex: 'location_code',
      key: 'location_code',
    },
    {
      title: 'Report Date',
      dataIndex: 'report_date',
      key: 'report_date',
    }
  ]

  const btnList = [
    {
      text: 'Export Report',
      classes: 'green-btn',
      action: () => exportReport(),
    }
  ];

  useEffect(() => {
    dispatch(getSalesDetails({
      is_condiment_required: 1,
      is_non_sales: "all",
      status: "paid"
    }, props.setLoading));
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  useEffect(() => {
    if (meta) {
      if ("locations" in meta) {
        let temp = [];
        meta.locations?.map((x, ind) => {
          temp.push({
            label: `${x.code} ${x.desc}`,
            value: x.id
          })
        });
        setOutlets(temp);
      }
      if ("set_products" in meta) {
        let temp = [];
        meta.set_products?.map((x, ind) => {
          temp.push({
            label: `${x.code} - ${x.name}`,
            value: x.id
          })
        });
        setProducts(temp);
      }
      if ("item_products" in meta) {
        let temp = [];
        meta.item_products?.map((x, ind) => {
          temp.push({
            label: `${x.code} - ${x.name}`,
            value: x.id
          })
        });
        setItemProducts(temp);
      }
      if ("order_types" in meta) {
        let temp = [];

        meta.order_types?.map((x, ind) => {
          if (ind === 0) {
            temp.push({
              label: "All Order Types",
              value: ""
            });
          }

          temp.push({
            label: x.desc,
            value: x.id
          })
        });
        setOrderTypes(temp);
      }
    }
  }, [meta]);

  const onSearch = (search) => {
    if (search && 'outlets' in search) {
      let searching = {};
      let temp2 = [];
      if (search?.products?.length > 0) {
        search?.products?.map(x => {
          temp2.push(x.value)
        });
      }
      searching = {
        products: temp2,
        is_non_sales: search?.non_sales?.value,
        order_no: search?.order_no,
        order_type_id: search?.order_type?.value,
        order_type_desc: search?.order_type?.label,
        is_condiment_required: search?.is_condiment_required?.value,
        date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : "",
        date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : "",
        status: search?.status?.value
      };
      if (search?.outlets?.length > 0) {
        let temp = [];
        search?.outlets?.map(x => {
          temp.push(x.value)
        });
        searching["location_ids"] = temp;
      }
      setSearchVal(searching);
      dispatch(getSalesDetails(searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getSalesDetails(null, props.setLoading));
    }
  };

  const exportReport = async () => {
    if (orders?.total_count === 0) {
      return message.warning("No data to be exported.");
    }
    props.setLoading(true);

    await exportSalesDetail(searchVal).then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    })
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Sales Details Report"} btnList={allowed([AllRoles.REPORTING.SALES_DETAILS], 'write') ? btnList : null} />
        </Col>
        <Col span={24} className="">
          <Card bordered={false}>
            <Search onSearch={onSearch} field1={outlets} field2={products} field3={[{
              label: "By Set",
              value: 1
              }, {
              label: "By Item",
              value: 0
            }]} field4={itemProducts} field5={orderTypes} />

            <div className="table-responsive" style={{ overflowX: 'auto', marginTop: "30px" }}>
              <table className='table table-borderless w-100' style={{ borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th className='text-center'>Item</th>
                    <th className='text-center'>Currency</th>
                    <th className='text-right'>Gross Amount</th>
                    <th className='text-right'>Discount</th>
                    <th className='text-right'>Net Amount</th>
                    <th className='text-right'>Inclusive Tax</th>
                    <th className='text-right'>Net Sales</th>
                    <th className='text-right'>Exclusive Tax</th>
                    <th className='text-right'>Gross Sales</th>
                    <th className='text-right'>Item Qty</th>
                    <th className='text-right'>Trans. Count</th>
                    <th className='text-right'>Inventory Cost</th>
                    <th className='text-right'>Inventory Unit Cost</th>
                    <th className='text-right'>Gross Profit</th>
                    <th className='text-right'>Gross Profit %</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    orders?.list?.length <= 0 &&
                    <tr>
                      <td colSpan="13">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  }
                  {
                    orders?.list?.length > 0 &&
                    orders?.list?.map((x, ind) => {
                      return (
                        <Fragment key={ind}>
                          <tr>
                            <td colSpan="2" className='fwbold'>{"Business Date: "} {x.date}</td>
                          </tr>
                          {
                            x.list?.map((y, yIndex) => {
                              return (
                                <Fragment key={yIndex}>
                                  <tr>
                                    <td className='fwbold' style={{ paddingLeft: "20px" }}>{y.location_code}</td>
                                  </tr>
                                  {
                                    y.list.map((z, zIndex) => {
                                      return (
                                        <tr key={zIndex}>
                                          <td style={{ paddingLeft: "35px" }}>{z.code}-{z.name}</td>
                                          <td className='text-center'>{z.currency_code}</td>
                                          <td align='right'>{comma(parseFloat(z.sub_total).toFixed(2))}</td>
                                          <td align='right'>{comma(parseFloat(z.discount_amount).toFixed(2))}</td>
                                          <td align='right'>{comma(parseFloat(z.net_amount).toFixed(2))}</td>
                                          <td align='right'>{comma(parseFloat(z.inclusive_tax_amount).toFixed(2))}</td>
                                          <td align='right'>{comma(parseFloat(z.net_sales).toFixed(2))}</td>
                                          <td align='right'>{comma(parseFloat(z.exclusive_tax_amount).toFixed(2))}</td>
                                          <td align='right'>{comma(parseFloat(z.gross_sales).toFixed(2))}</td>
                                          <td align='right'>{comma(z.quantity)}</td>
                                          <td align='right'>{comma(z.trans_count)}</td>
                                          <td align='right'>{comma(parseFloat(z.cost).toFixed(8))}</td>
                                          <td align='right'>{comma(parseFloat(z.unit_cost).toFixed(8))}</td>
                                          <td align='right'>{comma(parseFloat(z.profit).toFixed(2))}</td>
                                          <td align='right'>{comma(parseFloat(z.profit_perc).toFixed(2))}</td>
                                        </tr>
                                      )
                                    })
                                  }
                                  {
                                    x.list.length > 1 &&
                                    <tr className='bg-gray text-white fwbold'>
                                      <td align='' colSpan="2" style={{ paddingLeft: "20px" }}>SubTotal</td>
                                      {
                                        y?.sub_totals && Object.entries(y?.sub_totals)?.map(([key, data], zIndex) => {
                                          return (
                                            <td key={zIndex} align='right'>{comma(data)}</td>
                                          )
                                        })
                                      }
                                    </tr>
                                  }
                                </Fragment>
                              )
                            })
                          }
                          <tr className='bg-gray text-white fwbold'>
                            <td align='' colSpan="2">SubTotal</td>
                            {
                              x?.sub_totals && Object.entries(x?.sub_totals)?.map(([key, data], zIndex) => {
                                return (
                                  <td key={zIndex} align='right'>{comma(data)}</td>
                                )
                              })
                            }
                          </tr>
                        </Fragment>
                      )
                    })
                  }
                  <tr className='bg-gray fwbold text-white'>
                    {
                      orders?.list?.length <= 0
                        ? <td colSpan="15">Grand Total</td>
                        : <td align='' colSpan="2">Grand Total</td>
                    }
                    {
                      orders?.grand_totals && Object.entries(orders?.grand_totals)?.map(([key, data], index) => {
                        return (
                          <td key={index} align='right'>
                            {comma(data)}
                          </td>
                        )
                      })
                    }
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}