import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Typography, message, Spin, Radio, Card, Pagination, Button, Form, Space, Empty } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslate } from 'Translate';
import { LoadingOutlined, LeftOutlined } from '@ant-design/icons';
import { getBrands, updateOrganization, addOrganization }  from './ducks/services';
import { logout } from '../../../features/userSlice';
import { useDispatch } from 'react-redux';
import { onClear } from 'Modules/Application/ducks/actions';
import { useForm } from 'react-hook-form';
import PlusImg from 'Assets/img/plus.png';
import { Popup } from 'Atoms/Popup';
import FormGroup from 'Molecules/FormGroup';
import EntityImg from 'Assets/img/entity-placeholder.png';

const antIcon = <LoadingOutlined spin />;
const { Title, Text } = Typography;

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [meta, setMeta] = useState(null);
  const [checkedValue, setCheckedValue] = useState('');
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [visible, setVisible] = useState(false);
  const isSuperAdmin = localStorage.getItem("is_superadmin") === "1";

  const i18n = useTranslate();
  const { t } = i18n;

  const formFields = [
    {
      name: 'country',
      label: 'Country',
      req: true,
      placeholder: 'Please select',
      type: 'select',
      options: meta?.available_countries?.map(x => ({label: x, value: x})),
      twocol: false,
      colWidth: '0 1 100%',
      class: 'default-select',
      reqmessage: 'Country is Required'
    },
    {
      name: 'name',
      label: 'Entity Name',
      req: true,
      placeholder: 'Please enter',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Entity Name is Required'
    }
  ];

  const logoutHandler = () => {
    props.setFlexcol("0 1 536px");
    dispatch(logout());
    dispatch(onClear());
    localStorage.clear();
    navigate('/login');
  };

  const onFinish = async (item) => {
    setLoading(true);
    await updateOrganization(item.id).then((response) => {
      setLoading(false);
      if (response.data.code === 200) {
        let data = response.data.data;
        let user = data.user;
        localStorage.setItem('organization_name', data.name);
        localStorage.setItem('current_organization_id', data.id);
        localStorage.setItem('userid', JSON.stringify(user.id));
        if (user.name) localStorage.setItem('userName', user.name);
        if (user.role_list) localStorage.setItem('access', JSON.stringify(user.role_list));
        if (user.photo_url) localStorage.setItem('userImage', user.photo_url);
        navigate('/outlets');
      } else {
        message.error(response.data.message);
      }
    }).catch((e) => {
      setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    });
  };

  const onChange = async (e) => {
    setCheckedValue(e.target.value);
    setPage(1);
    callApi(1, e.target.value);
  };

  useEffect(() => {
    callApi();
    return () => {
      setBrands();
      setMeta();
      setCheckedValue();
      setPage(1);
      setCount(0)
    }
  }, []);

  const onPageChange = (pg) => {
    setPage(pg);
    callApi(pg);
  };

  const callApi = async (pg = 1, country = null) => {
    setLoading(true);
    const payload = {
      country_eq: country,
      page: pg,
      limit: 6
    }
    await getBrands(payload).then(res => {
      setLoading(false);
      if (res.data.code === 200) {
        setCount(res.data.data.total_count);
        setMeta(res.data.meta);
        setCheckedValue(res.data.data.country_eq)
        let brandList = res.data.data.list;
        setBrands(brandList);
        // if (brandList.length > 0) {
          props.setFlexcol("0 1 75%");
        // } else {
        //   props.setFlexcol("0 1 536px");
        // }
      } else {
        message.error(res.data.message);
      }
    }).catch(() => {
      setLoading(false);
    })
  };

  const onAdd = () => {
    setValue('country', {label: checkedValue, value: checkedValue});
    setValue('name', '');
    setVisible(true);
  };

  const onFinishAdd = async (val) => {
    setLoading(true);
    const payload = {
      country: val?.country?.value,
      name: val?.name
    }
    await addOrganization(payload).then((response) => {
      setLoading(false);
      if (response.data.code === 200) {
        onChange({target: {
          value: response.data.data
        }});
        setVisible(false);
      } else {
        message.error(response.data.message);
      }
    }).catch((e) => {
      setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    });
  };

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={loading}>
      <Form layout="vertical" onFinish={handleSubmit(onFinishAdd)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            New Entity
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
            }}>Cancel</Button></Col>
            {
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 650,
    onCancel: () => {
      setVisible(false);
      setValue('id', '');
      setValue('phone_no', '');
      setValue('address', '');
      setValue('address_2', '');
      setValue('postcode', '');
      setValue('city', '');
      setValue('state', '');
      setValue('default_shipping_address', false);
      setValue('default_billing_address', false);
    },
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row gutter={[30, 24]}>
        <Col span={24} className='text-center'>
          <Title className='m-0' level={2}>Select Entity</Title>
        </Col>
        <Col span={12}>
          <Button
            type="link"
            className="c-primary px-0"
            onClick={() => logoutHandler()}
            icon={<LeftOutlined />}
          >
            Back to Login
          </Button>
        </Col>
        {
          isSuperAdmin &&
          <Col span={12} className='text-right'>
            <Button onClick={() => onAdd()}>
              + Add New Entity
            </Button>
          </Col>
        }
        <Col span={24} className=''>
          {/* <Radio.Group onChange={onChange} defaultValue={data?.country_eq}> */}
            {
              meta?.countries?.map((c, ind) => {
                return (
                  <Radio.Button key={ind} value={c} checked={checkedValue === c} onChange={onChange}>{c}</Radio.Button>
                )
              })
            }
          {/* </Radio.Group> */}
        </Col>
        {
          brands && brands.length > 0 ?
          brands.map((item, index) => {
            return (
              <Col className="pointer" span={8} key={index} onClick={() => onFinish(item)}>
                <Card
                  className='brand-card'
                  bordered={false}
                  cover={
                    <img
                      className='entity-img'
                      alt="example"
                      src={item.image_url || EntityImg}
                    />
                    
                  }
                >
                  <b>{item.name}</b>
                </Card>
                {/* <Card title={item.name} bordered={false}>
                  
                </Card> */}
              </Col>
            )
          })
          :
          <Col span={24}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        }
        <Col span={24} className="text-right">
          <Pagination
            pageSize={6}
            current={page}
            hideOnSinglePage={true}
            showSizeChanger={false}
            onChange={onPageChange}
            total={count}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </Spin>
  );
};
