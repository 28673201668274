import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Button, Row, Col, Typography } from 'antd';
import AddForm from '../components/AddForm';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerOption } from "../../ducks/actions";
import { addCustomer } from "../../ducks/services"

const _ = require('lodash');
const { Title } = Typography;

export default (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1");
  const meta = useSelector((state) => state.crm.customerOption);

  useEffect(() => {
    dispatch(getCustomerOption(props.setLoading))
  }, []);

  return (
    <Row gutter={[20, 30]}>
      <Col span={24}>
        <Row justify={'space-between'} align={'middle'}>
          <Col flex='auto'>
            <Title level={3} className='mb-0 mt-0'>New Customer</Title>
          </Col>
          <Col flex='auto' className='text-right'>
            <Space size={10}>
              <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/crm/customers')}>{'<'} Back</Button>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <AddForm
          loading={props.loading}
          setLoading={props.setLoading}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          oneTab={true}
          mode={'add'}
          addCustomer={addCustomer}
          navigate={navigate}
          meta={meta}
          reloadApi={(propId) => navigate(`/crm/customers/${propId}`)}
          allowedEdit={true}
        />
      </Col>
    </Row>
  )
}