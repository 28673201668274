import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Empty, message, Space } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getSpeedOfServiceListing } from '../ducks/actions'
import { exportSalesReport } from '../ducks/services'
import Search from './components/Search';
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { comma } from 'Features/utility';

const { Title, Text } = Typography;
const _ = require('lodash');

function secToMin(seconds) {
  return seconds / 60;
}

export default (props) => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.reporting.speedOfServiceListing);
  const meta = useSelector((state) => state.reporting.speedOfServiceListingOption);
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const [products, setProducts] = useState([]);

  const btnList = [
    {
      text: 'Export Report',
      classes: 'green-btn',
      action: () => exportReport(),
    }
  ];

  useEffect(() => {
    dispatch(getSpeedOfServiceListing(searchVal, props.setLoading, "1"));
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  useEffect(() => {
    if (meta) {
      if ("locations" in meta) {
        let temp = [];
        meta.locations?.map((x, ind) => {
          temp.push({
            label: `${x.code} - ${x.desc}`,
            value: x.id
          })
        });
        setOutlets(temp);
      }
      if ("products" in meta) {
        let temp = [];
        meta.products?.map((x, ind) => {
          temp.push({
            label: `${x.code} - ${x.name}`,
            value: x.id
          })
        });
        setProducts(temp);
      }
    }
  }, [meta]);

  const onSearch = (search) => {
    if (search && 'outlets' in search) {
      let searching = {};
      let temp = [];
      if (search?.products?.length > 0) {
        search?.products?.map(x => {
          temp.push(x.value)
        });
      }
      searching = {
        date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : "",
        date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : "",
        products: temp
      };
      if (search?.outlets?.length > 0) {
        let temp2 = [];
        search?.outlets?.map(x => {
          temp2.push(x.value)
        });
        searching["location_ids"] = JSON.stringify(temp2);
      }
      setSearchVal(searching);
      dispatch(getSpeedOfServiceListing(searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getSpeedOfServiceListing(null, props.setLoading));
    }
  };

  const exportReport = async () => {
    if (orders?.total_count === 0) {
      return message.warning("No data to be exported.");
    }
    props.setLoading(true);

    await exportSalesReport(searchVal, "speed_of_service").then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    })
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Speed of Service Report"} btnList={allowed([AllRoles.REPORTING.SALES_PAYMENT], 'write') ? btnList : null} />
        </Col>
        <Col span={24} className="">
          <Card bordered={false}>
            <Space direction='vertical' size={20} style={{width: "100%"}}>
              <Search onSearch={onSearch} field1={outlets} field2={products} />

              <div className="table-responsive" style={{ overflowX: 'auto' }}>
                <table className='table table-borderless w-100' style={{ borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th style={{ minWidth: "100px" }}>Report Date</th>
                      <th style={{ minWidth: "100px" }}>Outlet</th>
                      <th style={{ minWidth: "180px" }}>Bill No.</th>
                      <th style={{ minWidth: "180px" }}>Item</th>
                      <th className='text-center' style={{ minWidth: "180px" }}>Checkout Time</th>
                      <th className='text-center' style={{ minWidth: "180px" }}>Bump Time. Per Item</th>
                      <th className='text-center' style={{ minWidth: "180px" }}>Bump All Time</th>
                      <th className='text-center' style={{ minWidth: "180px" }}>Avg. Bump Time</th>
                      <th className='text-center' style={{ minWidth: "180px" }}>Close Order Time</th>
                      {/* <th style={{ minWidth: "180px" }} className='text-right'>Gross Amount</th>
                      <th style={{ minWidth: "180px" }} className='text-right'>Discount</th>
                      <th style={{ minWidth: "180px" }} className='text-right'>Net Sales</th>
                      <th style={{ minWidth: "180px" }} className='text-right'>Tax</th>
                      <th style={{ minWidth: "180px" }} className='text-right'>Item Qty</th>
                      <th style={{ minWidth: "180px" }} className='text-right'>Inv. Cost</th>
                      <th style={{ minWidth: "180px" }} className='text-right'>Gross Profit</th>
                      <th style={{ minWidth: "180px" }} className='text-right'>Gross Profit %</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      orders?.list?.length <= 0 &&
                      <tr>
                        <td colSpan="8">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </td>
                      </tr>
                    }
                    {
                      orders?.list?.length > 0 ?
                        <>
                          {
                            orders?.list?.map((y, yind) => {
                              return (
                                y?.list?.length > 0 &&
                                y?.list?.map((x, ind) => {
                                  return (
                                    <tr key={ind}>
                                      <td>{y.date}</td>
                                      <td>{x.location_code} {x.location_desc}</td>
                                      <td>{x.order_no}</td>
                                      <td>{x.code}-{x.name}</td>
                                      <td className='text-center'>{x.paid_sec} {`(${secToMin(x.paid_sec).toFixed(2)} mins)`}</td>
                                      <td className='text-center'>{x.bump_sec} {`(${secToMin(x.bump_sec).toFixed(2)} mins)`}</td>
                                      <td className='text-center'>{x.prepared_sec} {`(${secToMin(x.prepared_sec).toFixed(2)} mins)`}</td>
                                      <td className='text-center'>{x.average_bump_sec} {`(${secToMin(x.average_bump_sec).toFixed(2)} mins)`}</td>
                                      <td className='text-center'>{x.collected_sec} {`(${secToMin(x.collected_sec).toFixed(2)} mins)`}</td>
                                    </tr>
                                  )
                                })
                              )
                            })
                          }
                        </>
                        : null
                    }
                    {/* <tr className='bg-gray fwbold text-white'>
                      {
                        orders?.list?.length <= 0
                          ? <td colSpan="15">Grand Total</td>
                          : <td align='right' colSpan="6">Grand Total</td>
                      }
                      {
                        orders?.grand_totals && Object.entries(orders?.grand_totals)?.map(([key, data], index) => {
                          return (
                            <td key={index} align='right'>
                              {comma(data)}
                            </td>
                          )
                        })
                      }
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </Space>
          </Card>
        </Col>
      </Row>
    </>
  )
}