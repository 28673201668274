import React, { useEffect, Fragment } from 'react';
import { Button, Row, Col, Form, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';

const _ = require('lodash');

export default (props) => {
  const { id, data, addIngredient, editIngredient, convertIngredient, reloadApi, mode, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();

  const formFields = [
    {
      name: 'code',
      label: 'Ingredient Code',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit || data?.editable === "0"
    },
    {
      name: 'name',
      label: 'Name',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit || data?.editable === "0"
    },
    {
      name: 'unit_cost',
      label: 'Default Inv. Unit Cost',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
      allowNegDecNum: true
    },
  ];

  useEffect(() => {
    if (data && 'id' in data && mode !== 'add') {
      setValue('code', data.code);
      setValue('name', data.name);
      setValue('unit_cost', data.unit_cost);
    }
  }, [data]);

  const onFinishAdd = async (val) => {
    props.setLoading(true);

    await addIngredient({ ...val }).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Ingredient created successfully!");
        reloadApi(res.data.data);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const onFinish = async (val) => {
    if(mode === 'add') {
      return onFinishAdd(val);
    }
    props.setLoading(true);
    const payload = {
      ...val,
      id: id
    }

    await editIngredient(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Ingredient updated successfully!");
        setTimeout(() => reloadApi(), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const convertToSemi = async () => {
    if(data?.product_type !== "ingredient") {
      return message.error("This ingredient cannot be converted.");
    }

    if(confirm("Are you sure to convert this ingredient now?")){
      props.setLoading(true);
      const payload = {
        id: id
      }

      await convertIngredient(payload).then(res => {
        props.setLoading(false);
        if (res.data.code === 200) {
          message.success("Ingredient converted successfully!");
          setTimeout(() => reloadApi(), 500);
        } else {
          message.error(res.data.message);
        }
      }).catch(e => {
        props.setLoading(false);
        message.error(e.message ?? "Something went wrong");
      })
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            {
              mode !== "add" && data?.product_type === "ingredient" &&
              <Button size="large" className='black-btn mr-1' onClick={() => convertToSemi()}>Convert to Semi Product</Button>
            }
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>Save</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}