import React, { useEffect, Fragment } from 'react';
import { Button, Row, Col, Form, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';

const _ = require('lodash');

export default (props) => {
  const { id, data, updateCategory, reloadApi, mode, meta, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();

  const formFields = [
    {
      name: 'code',
      label: 'Category Code',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'name',
      label: 'Description',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      type: 'select',
      label: 'Remark Types',
      name: 'remark_types',
      twocol: false,
      req: false,
      multiple: true,
      options: meta?.remark_types?.map((x) => ({ label: `${x.code} ${x.desc}`, value: x.id })),
      placeholder: 'Please select',
      colWidth: '0 1 100%',
      static: !allowedEdit
    }
  ];

  useEffect(() => {
    if (data && 'id' in data && mode !== 'add') {
      setValue('code', data.code);
      setValue('name', data.name);
      let temp = [];
      data?.remark_types?.map(rt => {
        temp.push({
          label: `${rt.code} ${rt.desc}`,
          value: rt.id
        })
      })
      setValue('remark_types', temp);
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      ...val,
      remark_types: JSON.stringify(val?.remark_types?.map(x => ({ id: x.value }))),
      category_id: id
    }

    await updateCategory(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Updated Category successfully!");
        reloadApi();
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>Save</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}