import React, { useEffect, Fragment, useState } from 'react';
import { Button, Row, Col, Form, message, Upload, Space, Typography } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { dummyRequest, getFileName, uploadFileV2 } from 'Features/utility';
import { useForm } from 'react-hook-form';
import { LoadingOutlined } from '@ant-design/icons';
import placeholderImg from "Assets/img/product-placeholder.png"

const _ = require('lodash');
const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const { id, editOrganization, meta, data, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [fileList, setFileList] = useState([]);
  const [fileId, setFileId] = useState();

  const formFields = [
    {
      name: 'receipt_hide_logo',
      label: 'Hide Logo?',
      req: false,
      type: 'switch',
      twocol: false,
      colWidth: '0 1 100%',
      static: !allowedEdit
    },
    {
      name: 'receipt_hide_zero_amount',
      label: 'Hide Zero Base Amount?',
      req: false,
      type: 'switch',
      twocol: false,
      colWidth: '0 1 100%',
      static: !allowedEdit
    },
    {
      name: 'name',
      label: 'Entity Name',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Name Required',
      static: !allowedEdit
    },
    {
      name: 'registration_no',
      label: 'Registration No.',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Registration No Required',
      static: !allowedEdit
    },
    {
      name: 'tax_label',
      label: 'Tax Label',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Tax Label Required',
      static: !allowedEdit
    },
    {
      name: 'tax_no',
      label: 'Tax No.',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Tax No Required',
      static: !allowedEdit
    },
    {
      name: 'receipt_header_desc',
      label: 'Receipt Header Description',
      req: false,
      placeholder: 'Please state',
      type: 'textarea',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Receipt Header Description Required',
      tips: "It is capped at 48 characters per line.",
      static: !allowedEdit
    },
    {
      subheader: 'Receipt Footer',
      name: 'receipt_footer_desc',
      label: 'Receipt Footer Description',
      req: false,
      placeholder: 'Please state',
      type: 'textarea',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Receipt Footer Description Required',
      tips: "It is capped at 48 characters per line.",
      static: !allowedEdit
    },
    {
      name: 'qr_value',
      label: 'QR Code Value',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'QR Code Required',
      static: !allowedEdit
    },
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('receipt_hide_logo', data.receipt_hide_logo === "1");
      setValue('receipt_hide_zero_amount', data.receipt_hide_zero_amount === "1");
      setValue('name', data.name);
      setValue('registration_no', data.registration_no);
      setValue('tax_label', data.tax_label);
      setValue('tax_no', data.tax_no);
      setValue('qr_value', data.qr_value);
      setValue('receipt_header_desc', data.receipt_header_desc?.replace(/\|/g, "\n"));
      setValue('receipt_footer_desc', data.receipt_footer_desc?.replace(/\|/g, "\n"));
      if (data?.image_url) {
        setFileList([
          {
            uid: '-1',
            name: getFileName(data?.image_url),
            status: 'done',
            url: data?.image_url
          }
        ])
      } else {
        setFileList();
        setFileId();
      }
    }
  }, [data]);

  const handleUpload = async (e) => {
    props.setLoading(true);
    try {
      let file = await uploadFileV2(e.file.originFileObj);
      props.setLoading(false);
      setFileId(file?.id);
      setFileList([{ uid: '-1', name: getFileName(file?.url), status: 'done', url: file?.url }]);
    } catch (error) {
      message.error(`Error during file upload: ${error}`);
      props.setLoading(false);
    }
  };

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      ...val,
      receipt_header_desc: val?.receipt_header_desc?.replace(/\n/g, "|"),
      receipt_footer_desc: val?.receipt_footer_desc?.replace(/\n/g, "|"),
      receipt_hide_logo: val?.receipt_hide_logo ? 1 : 0,
      receipt_hide_zero_amount: val?.receipt_hide_zero_amount ? 1 : 0,
      blob_id: fileId,
      id: id
    }

    await editOrganization(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Entity Updated successfully!");
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Row gutter={[20, 30]}>
          <Col span={24}>
            <Title className="mb-0" level={4}><u>Receipt Header</u></Title>
          </Col>
          <Col span={24} className="text-center">
            <Upload
              disabled={!allowedEdit}
              listType="picture-card"
              className="avatar-uploader larger h-100"
              showUploadList={false}
              accept="image/*"
              maxCount={1}
              fileList={fileList}
              customRequest={dummyRequest}
              onChange={(e) => handleUpload(e)}
            >
              <Space size={4} className='h-100'>
                {fileList?.length > 0 ? (
                  <div className='center-image'>
                    <img
                      src={fileList[0].url}
                      alt="avatar"
                      style={{
                        width: '100%',
                        height: 'auto'
                      }}
                    />
                  </div>
                ) :
                  <div className='center-image'>
                    <img src={placeholderImg} alt="" className='w-100' />
                  </div>
                }
              </Space>
            </Upload>
          </Col>
          {formFields.map((item, idx) => (
            <Fragment key={idx}>
              {item?.subheader && (
                <Col span={24}>
                  <Title className="mb-0" level={4}>
                    <u>{item.subheader}</u>
                  </Title>
                </Col>
              )}
              <FormGroup item={item} control={control} errors={errors} />
            </Fragment>
          ))}
          {
            allowedEdit &&
            <Col span={24} className='text-right'>
              <Button size="large" htmlType='submit' className='green-btn attendance-success'>Save</Button>
            </Col>
          }
        </Row>
      </Form>
    </>
  )
}