import React, { useEffect, useState } from 'react';
import { Row, Col, message } from 'antd';
import ListCard from 'Molecules/ListCard';
import { useDispatch, useSelector } from 'react-redux';
import { getAllActivities } from '../ducks/actions'
import Search from './Search';
import dayjs from 'dayjs';
import HeadingChip from 'Molecules/HeadingChip';
import { getListQueryItems } from 'Modules/Application/ducks/actions';

export default (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const activities = useSelector((state) => state.pos.allActivities);
  const meta = useSelector((state) => state.pos.allActivitiesMeta);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);

  const colName = [
    {
      title: 'Business Date',
      dataIndex: 'date',
      key: 'date',
      sorter: true
    },
    {
      title: 'Outlet',
      dataIndex: 'location_code',
      key: 'location_code',
      sorter: true
    },
    {
      title: 'POS ID',
      dataIndex: 'login_username',
      key: 'login_username',
      sorter: true
    },
    {
      title: 'Type',
      dataIndex: 'activity_type',
      key: 'activity_type',
      sorter: true
    },
    {
      title: 'Source',
      dataIndex: 'source_name',
      key: 'source_name',
      sorter: true
    },
    {
      title: 'Cashier',
      dataIndex: 'user_name',
      key: 'user_name',
      sorter: true
    }
  ];

  useEffect(() => {
    dispatch(getAllActivities(1, limit, '', '', null, props.setLoading));
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  useEffect(() => {
    if (meta) {
      if ('locations' in meta) {
        let temp = [];
        meta?.locations?.map((item, index) => {
          if (index === 0) {
            temp.push({
              label: "All Outlets",
              value: ""
            })
          }
          temp.push({
            label: `${item.code} ${item.desc}`,
            value: item.id
          })
        });
        setOutlets(temp);
      }
    }
  }, [meta]);

  const onTableChange = (pagination, filters, sorter) => {
    if(activities.total_count > 0) {
      setPage(pagination.current);
      setLimit(pagination.pageSize);
      if (sorter.order) {
        dispatch(getAllActivities(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
      } else {
        dispatch(getAllActivities(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
      }
    }
  };

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        name: search.name, // login_username
        start_date: search.start_date ? dayjs(search.start_date).format("YYYY-MM-DD") : "",
        group: search.group?.value //location_code
      };
      setSearchVal(searching);
      dispatch(getAllActivities(1, limit, '', '', searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getAllActivities(1, limit, '', '', null, props.setLoading));
    }
  };

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <HeadingChip title={"Audit Logs"} />
      </Col>
      <Col span={24}>
        <ListCard
          Search={Search}
          onSearch={onSearch}
          ListData={activities?.list?.map(x => ({ ...x, key: x.id }))}
          onChange={onTableChange}
          ListCol={colName}
          field1={outlets}
          pagination={{
            total: activities?.total_count,
            current: page,
            pageSize: limit
          }}
        />
      </Col>
    </Row>
  )
}