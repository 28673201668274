import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Form, message } from 'antd';
import InputForm from '../components/InputForm';
import { useForm } from 'react-hook-form';
import { addUom } from "../../ducks/services"

const _ = require('lodash');
const { Title } = Typography;

export default (props) => {

  const navigate = useNavigate();
  const { control, handleSubmit, formState: { errors } } = useForm();

  const onFinish = async (val) => {
    props.setLoading(true);

    await addUom({...val}).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("UOM created successfully!");
        setTimeout(() => navigate("/product/uom"), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row justify={'space-between'} align={'middle'}>
            <Col flex='auto'>
              <Title level={3} className='mb-0 mt-0'>New UOM</Title>
            </Col>
            <Col flex='auto' className='text-right'>
              <Space size={10}>
                <Button size="large" htmlType='submit' className='green-btn'>Save</Button>
                <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/product/uom')}>{'<'} Back</Button>
              </Space>
            </Col>
          </Row>
        </Col>
        <InputForm
          control={control}
          errors={errors}
          allowedEdit={true}
        />
      </Row>
    </Form>
  )
}