import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import FormGroup from 'Molecules/FormGroup';
import { useDispatch, useSelector } from 'react-redux';
import { getProductUoms } from '../../../../../ducks/actions'
import { addProductUom, editProductUom, removeProductUom, bulkRemoveProductUoms } from '../../../../../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, meta, activeTab, allowedEdit, allowedDelete } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const productUomsData = useSelector((state) => state.product.productUomsData);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [rec, setRecord] = useState();
  const [load, setLoad] = useState(false);
  const [isBaseUom, setIsBaseUom] = useState(false);

  const colName = [
    {
      title: 'UOM Code',
      dataIndex: 'uom_code',
      key: 'uom_code',
      className: 'enable-click',
      sorter: true,
    },
    {
      title: 'Base Conversion Rate',
      dataIndex: 'base_conversion_rate',
      key: 'base_conversion_rate',
      className: 'enable-click',
      sorter: true,
      render: (text) => <span>{productUomsData.uom_code ? `${text} (${productUomsData.uom_code})` : text}</span>

    },
    {
      title: 'Is Base UOM',
      dataIndex: 'is_base_uom',
      key: 'is_base_uom',
      className: 'enable-click',
      sorter: true,
      render: (text) => <span>{text === "1" ? 'Yes' : "No"}</span>
    },
    {
      title: 'Is Inventory UOM',
      dataIndex: 'is_inventory_uom',
      key: 'is_inventory_uom',
      className: 'enable-click',
      sorter: true,
      render: (text) => <span>{text === "1" ? 'Yes' : "No"}</span>
    },
    {
      title: 'Is Purchase UOM',
      dataIndex: 'is_purchase_uom',
      key: 'is_purchase_uom',
      className: 'enable-click',
      sorter: true,
      render: (text) => <span>{text === "1" ? 'Yes' : "No"}</span>
    },
    {
      title: 'Is Batch UOM',
      dataIndex: 'is_batch_uom',
      key: 'is_batch_uom',
      className: 'enable-click',
      sorter: true,
      render: (text) => <span>{text === "1" ? 'Yes' : "No"}</span>
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 200,
      render: (text, record) =>
        <ActionButton
          title="UOM"
          btnAction1={() => {
            setVisible(true);
            setRecord(record);
            setValue('base_conversion_rate', record?.base_conversion_rate);
            setValue('uom_code', record?.uom_code && record?.uom_id ? { label: record.uom_code, value: record?.uom_id } : '');
            setValue('is_base_uom', record.is_base_uom == "1");
            setIsBaseUom(record.is_base_uom == "1");
            setValue('is_inventory_uom', record.is_inventory_uom == "1");
            setValue('is_purchase_uom', record.is_purchase_uom == "1");
            setValue('is_batch_uom', record.is_batch_uom == "1");
          }}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={setLoad}
          loading={load}
        />
    }
  ];

  const formFields = [
    {
      type: 'select',
      label: 'UOM',
      name: 'uom_code',
      twocol: false,
      options: meta?.uoms?.map((x) => ({ label: x.code, value: x.id })),
      req: false,
      placeholder: 'Please select',
      reqmessage: 'UOM Required',
      colWidth: '0 1 100%',
      class: 'default-select',
      static: !allowedEdit
    },
    {
      name: 'base_conversion_rate',
      label: 'Base Conversion Rate',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      number: true,
      static: !allowedEdit,
      onChange: (e) => {
        if(isBaseUom) {
          if(e.target.value > 1) {
            setValue('base_conversion_rate', 1)
          }
        }
      }
    },
    {
      type: 'switch',
      name: 'is_base_uom',
      label: 'Is Base UOM',
      req: false,
      twocol: true,
      colWidth: '1 0 50%',
      alignEnd: true,
      static: !allowedEdit,
      onChange: (e) => {
        if(e) {
          setIsBaseUom(true)
          setValue('base_conversion_rate', 1)
        } else {
          setIsBaseUom(false)
          setValue('base_conversion_rate', '')
        }
      }
    },
    {
      type: 'switch',
      name: 'is_inventory_uom',
      label: 'Is Inventory UOM',
      req: false,
      twocol: true,
      colWidth: '1 0 50%',
      alignEnd: true,
      static: !allowedEdit,
    },
    {
      type: 'switch',
      name: 'is_purchase_uom',
      label: 'Is Purchase UOM',
      req: false,
      twocol: true,
      colWidth: '1 0 50%',
      alignEnd: true,
      static: !allowedEdit,
    },
    {
      type: 'switch',
      name: 'is_batch_uom',
      label: 'Is Batch UOM',
      req: false,
      twocol: true,
      colWidth: '1 0 50%',
      alignEnd: true,
      static: !allowedEdit,
    },
  ];

  const addNew = () => { 
    setVisible(true);
    setRecord();
    setValue('uom_code', '');
    setValue('base_conversion_rate', '');
    setValue('is_base_uom', false);
    setValue('is_inventory_uom', false);
    setValue('is_purchase_uom', false);
    setValue('is_batch_uom', false);
    setIsBaseUom(false);
  }

  const btnList = [
    {
      title: 'Are you sure to remove the selected UOM(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New UOM',
      classes: 'attendance-success',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    if (activeTab === "2")
    dispatch(getProductUoms(1, limit, '', '', props.setLoading, id));
  }, [activeTab]);

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getProductUoms(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, props.setLoading, id));
    } else {
      dispatch(getProductUoms(pagination.current, pagination.pageSize, '', '', props.setLoading, id));
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRemove = async (uomId) => {
    setLoad(true);

    const payload = {
      status_event: 'remove',
      product_uom_id: uomId,
    }

    await removeProductUom(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success("UOM has been removed successfully!");
        setTimeout(() => dispatch(getProductUoms(1, limit, '', '', props.setLoading, id)), 500);
      }else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      id: id,
      product_uoms: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveProductUoms(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("UOMs has been removed successfully!");
        setTimeout(() => dispatch(getProductUoms(1, limit, '', '', props.setLoading, id)), 500);
      }else {
        message.error(res.data.message)
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      base_conversion_rate: val?.base_conversion_rate,
      uom_id: val?.uom_code.value,
      is_base_uom: val?.is_base_uom ? 1 : 0,
      is_inventory_uom: val?.is_inventory_uom ? 1 : 0,
      is_purchase_uom: val?.is_purchase_uom ? 1 : 0,
      is_batch_uom: val?.is_batch_uom ? 1 : 0,
      id: id,
    }

    if(rec?.id) payload.product_uom_id = rec.id

    await (rec?.id ? editProductUom : addProductUom)(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success(`${rec?.id ? 'Updated' : 'Added'} UOM successfully.`);
        setVisible(false);
        setRecord();
        setValue('uom_code', '');
        setValue('base_conversion_rate', '');
        setValue('is_base_uom', false);
        setValue('is_inventory_uom', false);
        setValue('is_purchase_uom', false);
        setValue('is_batch_uom', false);
        setIsBaseUom(false);
        setTimeout(() => dispatch(getProductUoms(1, limit, '', '', props.setLoading, id)), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={20} className='w-100'>
          <Title level={4} className='m-0'>
            {rec ? 'Edit' : 'Add'} UOM
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('uom_code', '');
              setValue('base_conversion_rate', '');
              setValue('is_base_uom', false);
              setValue('is_inventory_uom', false);
              setValue('is_purchase_uom', false);
              setValue('is_batch_uom', false);
              setIsBaseUom(false);
              rec && setRecord();
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setValue('uom_code', '');
      setValue('base_conversion_rate', '');
      setValue('is_base_uom', false);
      setValue('is_inventory_uom', false);
      setValue('is_purchase_uom', false);
      setValue('is_batch_uom', false);
      setIsBaseUom(false);
      setRecord();
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click")) {
          console.log(record)
          setValue('base_conversion_rate', record?.base_conversion_rate);
          setValue('uom_code', record?.uom_code && record?.uom_id ? { label: record.uom_code, value: record?.uom_id } : '');
          setValue('is_base_uom', record.is_base_uom == "1");
          setIsBaseUom(record.is_base_uom == "1");
          setValue('is_inventory_uom', record.is_inventory_uom == "1");
          setValue('is_purchase_uom', record.is_purchase_uom == "1");
          setValue('is_batch_uom', record.is_batch_uom == "1");
          setRecord(record);
          setVisible(true);
        }
      },
    };
  }

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={""} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onRow={onClickRow}
            listClass="nopad"
            ListData={productUomsData?.list?.map(x => ({ ...x, key: x.id }))}
            ListCol={colName}
            rowSelection={allowedEdit && rowSelection}
            onChange={onTableChange}
            pagination={{
              total: productUomsData?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}