import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, message } from 'antd';
import AddForm from '../components/AddForm';
import { useDispatch, useSelector } from 'react-redux';
import { getStockTakeOption } from '../../ducks/actions'
import { addStockTake, generateInventoryNo, exportStockTakePdf } from "../../ducks/services"
import { ExportOutlined } from '@ant-design/icons';

const _ = require('lodash');
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("1");
  const meta = useSelector((state) => state.inventory.stockTakeOption);
  const [templateId, setTemplateId] =  useState();

  useEffect(() => {
    dispatch(getStockTakeOption(props.setLoading));
  }, []);

  const exportPdf = async () => {
    props.setLoading(true);

    await exportStockTakePdf(templateId, true).then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    })
  };

  return (
    <Row gutter={[20, 30]}>
      <Col span={24} style={{ position: "sticky", top: "-30px", backgroundColor: "#f5f5f5", zIndex: 3, padding: "10px" }}>
        <Row justify={'space-between'} align={'middle'}>
          <Col flex='auto'>
            <Title level={3} className='mb-0 mt-0'>New Stock Take</Title>
          </Col>
          <Col flex='auto' className='text-right'>
            <Space size={10}>
              {
                templateId &&
                <Button size="large" htmlType='button' className='green-btn' onClick={() => exportPdf()}>
                  <ExportOutlined /> Export Template
                </Button>  
              }
              <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/inventory/stock-take')}>{'<'} Back</Button>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <AddForm
          loading={props.loading}
          setLoading={props.setLoading}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          oneTab={true}
          mode={'add'}
          meta={meta}
          addStockTake={addStockTake}
          navigate={navigate}
          reloadApi={(propId) => navigate(`/inventory/stock-take/${propId}?t=2`)}
          allowedEdit={true}
          generateInventoryNo={generateInventoryNo}
          setTemplateId={setTemplateId}
        />
      </Col>
    </Row>
  )
}