import React, { useEffect } from 'react';
import { Button, Form, Row, Col } from 'antd';
import { InputField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';

export default (props) => {
  const { searchVal } = props;
  const { control, handleSubmit, setValue, getValues } = useForm()

  const onSubmit = (val) => {
    props.onSearch(val);
  }

  useEffect(() => {
    if(props.field1 && props.field1?.length > 0 && !getValues('payment_method_type')) {
      setValue('payment_method_type', props.field1[0]);
    }
  }, [props]);

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100 inline-form'>
      <Row className='w-100' gutter={[10, 10]}>
        {/* <Col flex="auto">
          <SelectField
            fieldname="payment_method_type"
            class="mb-0 w-100 default-select"
            label=""
            control={control}
            iProps={{ placeholder: 'Payment Method Type' }}
            selectOption={props.field1}
          />
        </Col> */}
        <Col flex="auto">
          <InputField
            fieldname='code'
            label=''
            class='mb-0 w-100'
            initValue={searchVal?.code ? searchVal?.code : ''}
            control={control}
            iProps={{ placeholder: 'Search by description..' }}
          />
        </Col>
        <Col flex="70px">
          <Button className='w-100' size='large' type='primary' htmlType='submit'>Search</Button>
        </Col>
      </Row>
    </Form>
  )
}