import React, { Fragment } from 'react';

export default (props) => {
  const { columns, data } = props;

  return (
    <div className="inventory-table-container">
      <div className='fwbold mt-2'>
        {data?.report_date && <div>Business Date: {data?.report_date}</div>}
        {data?.location_name && <div>Outlet: {data?.location_name}</div>}
      </div>

      <div className="inventory-table">
        <table>
          <thead>
            <tr>
              {columns?.product_recipe_listing?.list?.map(item =>
                <th className={`${item?.longCol ? 'long-col' : ''} text-${item.align}`} key={`Title ${item.title}`}>
                  {item.title}
                </th>
              )}
              <th colSpan={20}></th>
            </tr>
          </thead>
          <tbody>
            {data?.list?.map((listItem, listItemIndex) => 
              <Fragment key={`List Item ${listItemIndex}`}>
                <tr>
                  <td>{listItem.product_code}</td>
                  <td>{listItem.uom}</td>
                  <td align='right'>{listItem.item_count}</td>
                  <td colSpan={20}></td>
                </tr>
                <tr>
                  <th colSpan={2}></th>
                  <th className="nested-header" align='left'>Item</th>
                  <th className="nested-header" align='left'>UOM</th>
                  <th className="nested-header" align='right'>Recipe Rate</th>
                  <th className="nested-header" align='right'>Yield Perc</th>
                  <th className="nested-header" align='right'>Item Qty</th>
                </tr>
                {listItem?.list?.map((childListItem, childListItemIndex) => 
                  <tr key={`List Item ${listItemIndex}: Child List Item ${childListItemIndex}`}>
                    <td colSpan={2}></td>
                    <td>{childListItem.item}</td>
                    <td>{childListItem.uom}</td>
                    <td align='right'>{childListItem.recipe_rate}</td>
                    <td align='right'>{childListItem.yield_perc}</td>
                    <td align='right'>{childListItem.item_qty}</td>
                  </tr>
                )}
              </Fragment>
            )}

            
            <tr className='bg-gray text-white'>
              <td colSpan={1}></td>
              <td>Grand Total</td>
              {data?.grand_total?.map((item, index) => 
                <td key={`Grand Total ${item} ${index}`} align='right'>{item}</td>
              )}
              <td colSpan={20}></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}