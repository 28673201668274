import React, { useState } from 'react';
import { Button, Form, Row, Col, Input } from 'antd';
import { DateField, SelectField, InputField, SwitchField, TimeField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';
import DateRangeDropdown from 'Modules/Application/components/DateRangeDropdown';
import dayjs from 'dayjs';

const customParseFormat = require('dayjs/plugin/customParseFormat');

// Extend Day.js with customParseFormat plugin
dayjs.extend(customParseFormat);

// Set the locale to start the week from Monday
dayjs.locale({
  name: 'custom',
  weekStart: 1, // Monday (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
});

// Use the custom locale
dayjs.locale('custom');

export default (props) => {

  const { 
    isMultiOutlet, 
    setIsMultiOutlet, 
    dateFromOnly, 
    setDateFromOnly, 
    stockMovementFilter, 
    setStockMovementFilter, 
    trDescList, 
    setTrDescList, 
    productRecipeFilter, 
    setProductRecipeFilter, 
    materialCon, 
    setMaterialCon,
    setStatuses,
    meta
  } = props;
  const { control, handleSubmit, watch, setValue, getValues, formState: { errors } } = useForm();
  const [isOptionalOutlet, setOptionalOutlet] = useState(false);
  

  const onSubmit = (val) => {
    props.onSearch(val);
  }

  const onExport = () => {
    const allValues = getValues();
    props.exportReport(allValues);
  };

  const changeFilterField = (e) => {
    if(isMultiOutlet) {
      setValue('outlet', '');
    }
    if(dateFromOnly) {
      setValue('end_date', '')
      setValue('dateRange', '')
    }
    
    if(e.value === 'stock_movement_listing' || e.value === 'po_details_listing') {
      setStockMovementFilter(true)
    } else {
      setStockMovementFilter(false)
    }

    if(e.value === 'tr_descrepancy_details_listing') {
      setTrDescList(true)
    } else {
      setTrDescList(false)
    }

    if(e.value === 'product_recipe_listing') {
      setProductRecipeFilter(true)
    } else {
      setProductRecipeFilter(false)
    }

    if(e.value === 'po_summary_listing') {
      const poStatuses = [{ label: "All Status", value: "" }, ...meta?.statuses.filter(item => item !== 'posted').map(x => ({ label: x, value: x }))];
      setStatuses(poStatuses);
    } else {
      let temp = meta?.statuses.map(x => ({ label: x, value: x }))
      temp = [{ label: "All Status", value: "" }, ...temp]

      setStatuses(temp);
    }

    if(e.noStatus) {
      setMaterialCon(false)
    } else {
      setMaterialCon(true)
    }

    setIsMultiOutlet(e.isMultiOutlet);
    setDateFromOnly(e.dateFromOnly);
    setOptionalOutlet(e.value === "po_details_listing");
  }

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100'>
      <Row className='w-100' gutter={[10, 10]}>
        <Col flex="auto">
          <SelectField
            fieldname='report_type'
            label=''
            class='mb-0 w-100 default-select'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'Select Report Type' }}
            selectOption={props.field1}
            rules={{
              required: 'Please select report type'
            }}
            validate={errors.report_type && 'error'}
            validMessage={errors.report_type && errors.report_type.message}
            onChange={(e) => changeFilterField(e)}
          />
        </Col>
        <Col flex="70px">
          <Button className='w-100' size='large' type='primary' htmlType='submit'>Search</Button>
        </Col>
        <Col flex="70px">
          <Button className='w-100 b-success' size='large' htmlType='button' onClick={() => onExport()}>Export Report</Button>
        </Col>
      </Row>
      <Row className='w-100 mt-1-5' gutter={[10, 10]}>
        
        {(!dateFromOnly && !productRecipeFilter) && <DateRangeDropdown placeholderValue="Date" control={control} onSetValue={setValue} errors={errors} isRequired={true} />}

        {/* || productRecipeFilter */}
        {dateFromOnly && 
          <Col flex="auto">
            <DateField
              fieldname='start_date'
              label=''
              initValue={''}
              control={control}
              class='mb-0 w-100'
              iProps={{ placeholder: `Date (From)`, size: 'large' }}
              rules={{
                required: 'Please select start date'
              }}
              validate={errors?.start_date && 'error'}
              validMessage={errors?.start_date && errors?.start_date.message}
            />
          </Col>
        }

      </Row>
      {!trDescList && <Row className='w-100 mt-1-5' gutter={[10, 10]}>
        <Col flex="auto">
            {isMultiOutlet ?
              <SelectField
                fieldname='outlet'
                label=''
                class='mb-0 w-100 default-select'
                initValue={''}
                control={control}
                iProps={{ placeholder: 'All Outlets', isMulti: true }}
                selectOption={props.field2}
                rules={{
                  required: false
                }}
                validate={errors.outlet && 'error'}
                validMessage={errors.outlet && errors.outlet.message}
              />
              :
              <SelectField
                fieldname='outlet'
                label=''
                class='mb-0 w-100 default-select'
                initValue={''}
                control={control}
                iProps={{ placeholder: 'Select Outlet' }}
                selectOption={props.field2}
                isClearable={isOptionalOutlet}
                rules={{
                  required: isOptionalOutlet ? false : 'Please select Outlet'
                }}
                validate={errors.outlet && 'error'}
                validMessage={errors.outlet && errors.outlet.message}
              />
            }
          </Col>
          {materialCon &&
          <Col flex="auto">
            <SelectField
              fieldname='status'
              label=''
              class='mb-0 w-100 default-select'
              initValue={''}
              control={control}
              iProps={{ placeholder: 'Select Status' }}
              selectOption={props.field3}
            />
          </Col>}

          {stockMovementFilter && 
            <Col flex="auto">
              <SelectField
                fieldname='products'
                label=''
                class='mb-0 w-100 default-select'
                initValue={''}
                control={control}
                iProps={{ placeholder: 'Select Ingredients', isMulti: true }}
                selectOption={props.field5}
              />
            </Col>
          }
      </Row>}
    </Form>
  )
}