import React, { useEffect, useState } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Empty } from 'antd';
import DndListCard from 'Molecules/DndListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getMenuTypes } from '../../../../../ducks/actions'
import { addMenuType, editMenuType, removeMenuType, bulkRemoveMenuTypes, updateMenuTypesOrder } from '../../../../../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined, CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { SelectField, InputField, CheckboxGroup, SwitchField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, meta, activeTab, allowedEdit, allowedDelete } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const menuType = useSelector((state) => state.product.menuType);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [orderTypeList, setOrderTypeList] = useState([]);
  const [hasDirty, setHasDirty] = useState(false);
  const [data, setData] = useState([])

  const colName = [
    {
      title: 'Order Type Code',
      dataIndex: 'order_type_code',
      key: 'order_type_code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Order Type Desc',
      dataIndex: 'order_type_desc',
      key: 'order_type_desc',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Is Stock Conversion?',
      dataIndex: 'is_stock_conversion',
      key: 'is_stock_conversion',
      className: 'enable-click',
      align: 'center',
      render: (text) => <span>{text === "1" ? <CheckCircleFilled className='c-success' style={{ fontSize: '20px' }} /> : <CloseCircleFilled className='c-danger' style={{ fontSize: '20px' }} />}</span>
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 200,
      render: (text, record) =>
        <ActionButton
          title="Menu Type"
          btnAction1={() => {
            setValue('id', record?.id);
            setValue('order_type', { label: record?.order_type_desc, value: record?.order_type_id });
            setValue("is_stock_conversion", record?.is_stock_conversion === "1");
            setVisible(true);
            let temp = orderTypeList;
            temp.push({
              label: record?.order_type_desc,
              value: record?.order_type_id
            });
            setOrderTypeList(temp);
          }}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const addNew = () => { 
    setVisible(true);
    setValue('id', null);
    setValue('order_type', '');
    setValue('order_types', '');
    setValue("is_stock_conversion", false);
    resetOrderTypeList();
  }

  const btnList = [
    {
      title: "Are you sure to reorder the list?",
      text: "Update Listing",
      classes: 'b-success attendance-success',
      btnHidden: !hasDirty,
      action: () => onUpdateList()
    },
    {
      title: 'Are you sure to remove the selected Menu Type(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New Menu Type',
      classes: 'attendance-success',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    if (activeTab === "2")
    dispatch(getMenuTypes(1, limit, '', '', props.setLoading, id));
  }, [activeTab]);

  useEffect(() => {
    if (meta && 'order_types' in meta) {
      resetOrderTypeList();
    }
  }, [meta, menuType]);

  useEffect(() => {
    if (menuType) {
      setData(menuType?.list);
    }
  }, [menuType]);

  const resetOrderTypeList = () => {
    let temp = meta?.order_types?.map(x => ({ label: x.desc, value: x.id }))
    temp = temp?.filter(x => menuType?.list?.filter(y => y.order_type_id === x.value).length <= 0)
    setOrderTypeList(temp);
  }

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getMenuTypes(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, props.setLoading, id));
    } else {
      dispatch(getMenuTypes(pagination.current, pagination.pageSize, '', '', props.setLoading, id));
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRemove = async (menuTypeId) => {
    props.setLoading(true);

    const payload = {
      product_menu_id: id,
      product_menu_type_id: menuTypeId,
      status_event: 'remove'
    }

    await removeMenuType(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Menu Type has been removed successfully!");
        setTimeout(() => dispatch(getMenuTypes(1, limit, '', '', props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      product_menu_id: id,
      menu_types: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveMenuTypes(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Menu Types has been removed successfully!");
        setTimeout(() => dispatch(getMenuTypes(1, limit, '', '', props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      order_type_id: val?.order_type?.value,
      order_type_ids: JSON.stringify(val.order_types) || "[]",
      product_menu_id: id,
      product_menu_type_id: val?.id,
      is_stock_conversion: val?.is_stock_conversion ? "1" : "0"
    }

    await (val?.id ? editMenuType : addMenuType)(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(`${val?.id ? 'Updated' : 'Added'} Menu Type successfully.`);
        setVisible(false);
        setValue('id', null);
        setValue('order_type', '');
        setValue('order_types', '');
        setTimeout(() => dispatch(getMenuTypes(1, limit, '', '', props.setLoading, id)), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={props.loading}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={15} className='w-100'>
          <Title level={4} className='m-0'>
            {getValues('id') ? 'Edit' : 'Add'} Menu Type
          </Title>
          <InputField
            fieldname='id'
            label=''
            class='d-none'
            initValue={''}
            control={control}
          />
          {
            getValues('id') ?
              <>
                <SelectField
                  isRequired={visible}
                  fieldname='order_type'
                  label='Order Type'
                  class='mb-1 w-100 default-select'
                  initValue={''}
                  control={control}
                  iProps={{ placeholder: 'Please select', isDisabled: !allowedEdit }}
                  selectOption={orderTypeList}
                  rules={{
                    required: 'Menu Type is required',
                  }}
                  validate={errors.order_type && 'error'}
                  validMessage={errors.order_type && errors.order_type.message}
                />
                <SwitchField
                  fieldname={"is_stock_conversion"}
                  label={"Is Stock Conversion?"}
                  control={control}
                  iProps={{ size: 'large' }}
                  initValue={false}
                />
              </>
            :
              (
                orderTypeList.length === 0 ?
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  :
                  // <Space size={10} className='w-100' style={ orderTypeList?.length > 2 ? {display: 'flex'} : {}}>
                  <CheckboxGroup
                    disabled={!allowedEdit}
                    fieldname={'order_types'}
                    label={''}
                    class={`mb-1 fullWidth-checbox`}
                    control={control}
                    initValue={[]}
                    option={orderTypeList}
                    rules={{
                      required: 'Order Type is required',
                    }}
                    validate={errors.order_types && 'error'}
                    validMessage={errors.order_types && errors.order_types.message}
                  />
                // </Space>
              )
          }
          <Row gutter={10} justify={'center'}>
            <Col span={orderTypeList?.length === 0 && !getValues('id') ? 24 : 12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('id', '');
              setValue('order_type', '');
              setValue('order_types', '');
              setValue("is_stock_conversion", false);
              resetOrderTypeList();
            }}>Cancel</Button></Col>
            {
              (orderTypeList?.length > 0 || getValues('id')) && allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setValue('id', null);
      setValue('order_type', '');
      setValue('order_types', '');
      setValue("is_stock_conversion", false);
      resetOrderTypeList();
    },
  };

  const onUpdateList = async () => {
    props.setLoading(true);

    const payload = {
      menu_types: JSON.stringify(data?.map((d, i) => ({ id: d.id, position: i + 1 })))
    }
    await updateMenuTypesOrder(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Menu Types Order updated successfully.");
        setHasDirty(false);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={""} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <DndListCard
            setHasDirty={setHasDirty}
            setDataList={setData}
            list={data}
            classes="nopad"
            ListData={data?.map(x => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            ListCol={colName}
            rowSelection={rowSelection}
            pagination={{
              total: menuType?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}