import React, { useEffect, useState } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Empty } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getAlacartes } from '../../../../../ducks/actions'
import { bulkRemoveAlacartes } from '../../../../../ducks/services'
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { SelectField, InputField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, meta, activeTab, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const alacartes = useSelector((state) => state.product.alacartes);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [bulkRemoveVisible, setBulkRemoveVisible] = useState(false);
  const [load, setLoad] = useState(false);

  const colName = [
    {
      title: 'Product Code',
      dataIndex: 'code',
      key: 'code',
      sorter: true
    },
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true
    },
  ];

  const btnList = [
    {
      title: 'Are you sure to change ingredient for the selected Alacarte(s)?',
      text: 'Change Ingredient',
      classes: 'red-btn text-white attendance-success',
      btnHidden: selectedRowKeys.length <= 0,
      action: () => {
        setBulkRemoveVisible(true);
        setValue('ingredient', null);
      }
    }
  ];

  useEffect(() => {
    if (activeTab === "3")
      dispatch(getAlacartes(1, limit, '', '', props.setLoading, id));
  }, [activeTab]);

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getAlacartes(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, props.setLoading, id));
    } else {
      dispatch(getAlacartes(pagination.current, pagination.pageSize, '', '', props.setLoading, id));
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onBulkRemove = async (val) => {
    setLoad(true);

    const payload = {
      id: id,
      ingredient_id: val?.ingredient?.value,
      // uom_id: val?.uom?.value,
      qty: val?.quantity,
      alacartes: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveAlacartes(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        setBulkRemoveVisible(false);
        message.success("Successfully Changed Ingredient for selected Alacartes!");
        setTimeout(() => dispatch(getAlacartes(1, limit, '', '', props.setLoading, id)), 500);
      }else{
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const popup2 = {
    closable: false,
    visibility: bulkRemoveVisible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onBulkRemove)}>
        <Space direction='vertical' size={15} className='w-100'>
          <Title level={4} className='m-0'>
            Change Ingredient
          </Title>
          <SelectField
            isRequired={bulkRemoveVisible}
            fieldname='ingredient'
            label='Ingredient'
            class='mb-0 w-100 default-select'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'Please select', isDisabled: !allowedEdit }}
            selectOption={meta?.ingredients?.map(x => ({ label: `${x.code} ${x.name}`, value: x.id }))}
            rules={{
              required: bulkRemoveVisible ? 'Ingredient is required' : false,
            }}
            validate={errors.ingredient && 'error'}
            validMessage={errors.ingredient && errors.ingredient.message}
          />
          <div>
            <InputField
              // isRequired={bulkRemoveVisible}
              fieldname='quantity'
              label='Quantity'
              class='mb-0 w-100'
              initValue={''}
              control={control}
              iProps={{ placeholder: 'Please enter quantity', type: 'number', disabled: !allowedEdit }}
              rules={{
                // required: bulkRemoveVisible ? 'Quantity is required' : false,
                pattern: { value: /^[1-9]\d*(\.\d+)?$/, message: 'Only valid numbers are are allowed' }
              }}
              validate={errors.quantity && 'error'}
              validMessage={errors.quantity && errors.quantity.message}
            />
            <span className='c-gray'>Entering 0 or leaving the field blank will maintain the current item quantity unchanged</span>
          </div>
          <Row gutter={10}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => setBulkRemoveVisible(false)}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setBulkRemoveVisible(false);
    },
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={""} btnList={allowedEdit ? btnList : null} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            listClass="nopad"
            ListData={alacartes?.list?.map(x => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            ListCol={colName}
            rowSelection={allowedEdit && rowSelection}
            pagination={{
              total: alacartes?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup2} />
    </>
  )
}