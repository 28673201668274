import React, { useEffect, Fragment } from 'react';
import { Space, Button, Row, Col, Typography, Form, message, Spin } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { addUser, editUser } from '../../../../../ducks/services'

const _ = require('lodash');

export default (props) => {
  const { id, mode, errors, control, meta, data, setValue, allowedEdit, handleSubmit, reloadApi } = props;

  const formFields = [
    {
      name: 'username',
      label: 'Username',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit,
      nospace: true
    },
    {
      name: 'name',
      label: 'Name',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: true,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'email',
      label: 'Email',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      email: true,
      twocol: true,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'roles',
      label: 'Roles',
      req: true,
      type: 'select',
      options: meta?.roles?.map((x) => ({ label: x.name, value: x.id })),
      placeholder: 'Please select',
      twocol: false,
      multiple: true,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      let temp = [];
      data?.roles?.map(role => {
        temp.push({
          label: role.name,
          value: role.id
        })
      });
      setValue('username', data.username);
      setValue('name', data.name);
      setValue('email', data.email);
      setValue('roles', temp);
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      ...val,
      roles: JSON.stringify(val.roles.map(x => ({id: x.value}))),
      id: id
    }

    await (mode === "add" ? addUser : editUser)(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("User edited successfully!");
        reloadApi(res.data.data);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };


  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>Save</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}