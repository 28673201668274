import React, { useEffect, Fragment, useReducer } from 'react';
import { Row, Col, Typography } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useFieldArray } from 'react-hook-form';
import ArrayForm from 'Atoms/ArrayForm';
import { availabilityType } from '../../../../../../configs/constantData';
import dayjs from 'dayjs';

const _ = require('lodash');

const custom = [
  {
    day: 'Monday',
    work_hour_type: availabilityType[0],
    start_time: "",
    end_time: "",
  },
  {
    day: 'Tuesday',
    work_hour_type: availabilityType[0],
    start_time: "",
    end_time: ""
  },
  {
    day: 'Wednesday',
    work_hour_type: availabilityType[0],
    start_time: "",
    end_time: ""
  },
  {
    day: 'Thursday',
    work_hour_type: availabilityType[0],
    start_time: "",
    end_time: ""
  },
  {
    day: 'Friday',
    work_hour_type: availabilityType[0],
    start_time: "",
    end_time: ""
  },
  {
    day: 'Saturday',
    work_hour_type: availabilityType[0],
    start_time: "",
    end_time: ""
  },
  {
    day: 'Sunday',
    work_hour_type: availabilityType[0],
    start_time: "",
    end_time: ""
  },
];

export default (props) => {
  const { data, errors, control, setValue, reset, getValues, clearErrors, allowedEdit } = props;
  const { Title } = Typography;

  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const { fields, replace } = useFieldArray({
    control: control,
    name: 'work_hours'
  });

  const formFields = [
    {
      label: 'Template',
      name: 'template_name',
      type: 'input',
      twocol: true,
      placeholder: 'Type template name',
      req: true,
      reqmessage: 'Template Name required',
      static: !allowedEdit
    },
    {
      type: 'array',
      name: 'work_hours',
      twocol: false,
      colWidth: '0 1 100%',
      single: false,
      noCard: true,
      child: [
        {
          type: 'input',
          name: 'day',
          label: '',
          static: true,
          req: false,
          twocol: false,
          colWidth: '1 0 100px',
          static: !allowedEdit
        },
        {
          type: 'select',
          name: 'work_hour_type',
          label: '',
          placeholder: 'Please Select',
          req: true,
          options: availabilityType,
          twocol: false,
          colWidth: '1 0 70px',
          getFieldName: true,
          static: !allowedEdit,
          onChange: (fieldname) => {
            if(getValues(`${fieldname}.work_hour_type`)?.value === "Unavailable") {
              clearErrors([`${fieldname}.start_time`, `${fieldname}.end_time`]); 
            }
            forceUpdate();
          }
        },
        {
          type: 'time',
          name: 'start_time',
          // options: days,
          placeholder: 'Please Select',
          format: 'h:mm a',
          static: false,
          req: true,
          reqmessage: 'Required',
          twocol: false,
          colWidth: '1 0 105px',
          static: !allowedEdit
        },
        {
          type: 'time',
          name: 'end_time',
          // options: days,
          placeholder: 'Please Select',
          format: 'h:mm a',
          static: false,
          req: true,
          reqmessage: 'Required',
          twocol: false,
          colWidth: '1 0 105px',
          static: !allowedEdit
        }
      ],
    },
  ];

  useEffect(() => {
    if (data && 'shifts' in data) {
      let working_hours = data?.shifts.map((value) => ({
        id: value?.id,
        day: value?.dow,
        work_hour_type: { label: value?.shift_type, value: value?.shift_type },
        start_time: value?.start_time ? dayjs(value?.start_time, 'h:mm') : '',
        end_time: value?.end_time ? dayjs(value?.end_time, 'h:mm') : ''
      }));
      setValue('template_name', data.name);
      replace(working_hours);
    } else {
      reset();
      replace(custom);
    }
  }, [data]);

  return (
    <>
      <Row gutter={[24, 30]}>
        <Col span={24}>
          <Row gutter={[20, 20]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                {item?.subheader && (
                  <Col span={24}>
                    <Title level={item?.subheadlevel ? item?.subheadlevel : 5} className="mb-0 c-default">
                      {item.subheader}
                    </Title>
                  </Col>
                )}
                {item.type == 'array' ? (
                  <Col span={item.twocol ? 12 : 24}>
                    <Row gutter={[20, 30]}>
                      <Col span={24}>
                        <ArrayForm
                          fields={fields}
                          item={item}
                          control={control}
                          errors={errors}
                          getValues={getValues}
                        />
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <FormGroup item={item} control={control} errors={errors} />
                )}
              </Fragment>
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};