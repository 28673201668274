import React, { Fragment } from 'react';
import FormGroup from 'Molecules/FormGroup';
import { Space, Row, Col, Upload, message } from 'antd';
import { dummyRequest, uploadFileV2, getFileName } from 'Features/utility';
import placeholderImg from "Assets/img/product-placeholder.png"

const _ = require('lodash');

export default (props) => {

  const { errors, control, fileList, setFileList, setFileId, meta, allowedEdit } = props;

  const formFields = [
    {
      name: 'code',
      label: 'Order Type Code',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: true,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'desc',
      label: 'Description',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: true,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      type: 'select',
      label: 'Order Type Group',
      name: 'order_type_group',
      twocol: false,
      req: true,
      options: meta?.order_type_groups?.map((x) => ({ label: `${x.code} - ${x.desc}`, value: x.id })),
      placeholder: 'Please select',
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      type: 'select',
      label: 'Tax Condition',
      name: 'tax_condition',
      twocol: false,
      req: true,
      options: meta?.tax_conditions?.map((x) => ({ label: `${x.code} - ${x.desc}`, value: x.id })),
      placeholder: 'Please select',
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'is_fa',
      label: 'Is Food Aggregator (FA)',
      req: false,
      twocol: false,
      colWidth: '1 0 50%',
      alignEnd: false, // move form to top
      labelPosition: 'top', // move label to top
      static: !allowedEdit
    },
  ];

  const handleUpload = async (e) => {
    props.setLoading(true);
    try {
      let file = await uploadFileV2(e.file.originFileObj);
      props.setLoading(false);
      setFileId(file?.id);
      setFileList([{ uid: '-1', name: getFileName(file?.url), status: 'done', url: file?.url }]);
    } catch (error) {
      message.error(`Error during file upload: ${error}`);
      props.setLoading(false);
    }
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={24} className="text-center">
          <Upload
            disabled={!allowedEdit}
            listType="picture-card"
            className="avatar-uploader larger"
            showUploadList={false}
            accept="image/*"
            maxCount={1}
            fileList={fileList}
            customRequest={dummyRequest}
            onChange={(e) => handleUpload(e)}
          >
            <Space size={4}>
              {fileList?.length > 0 ? (
                <img
                  src={fileList[0].url}
                  alt="avatar"
                  style={{
                    width: '100%',
                  }}
                />
              ) :
                <div>
                  <img src={placeholderImg} alt="" className='w-100' />
                </div>
              }
            </Space>
          </Upload>
        </Col>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
      </Row>
    </>
  )
}