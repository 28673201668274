import React, { useEffect, Fragment } from 'react';
import { Button, Row, Col, Form, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';

const _ = require('lodash');

export default (props) => {
  const { id, data, addCustomer, editCustomer, reloadApi, mode, meta, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();

  const formFields = [
    {
      name: 'first_name',
      label: 'First Name',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'last_name',
      label: 'Last Name',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      type: 'select',
      label: 'Calling Code',
      name: 'calling_code',
      twocol: false,
      options: meta?.calling_codes?.map((x) => ({ label: `${x.name} (+${x.calling_code})`, value: x.calling_code })),
      req: true,
      placeholder: 'Please select',
      reqmessage: 'Required',
      colWidth: '0 1 50%',
      static: !allowedEdit
    },
    {
      type: 'input',
      name: 'phone',
      label: 'Phone Number',
      req: true,
      placeholder: 'Please state',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      className: 'default-select',
      static: !allowedEdit
    },
    // {
    //   type: 'phone',
    //   name: 'phone',
    //   label: 'Phone Number',
    //   req: true,
    //   placeholder: 'Please state',
    //   twocol: false,
    //   phone: true,
    //   colWidth: '0 1 50%',
    //   reqmessage: 'Required',
    //   className: 'default-select',
    //   static: !allowedEdit
    // },
    {
      name: 'email',
      label: 'Email',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      email: true,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'dob',
      label: 'Date of Birth',
      req: false,
      placeholder: 'Please state',
      type: 'date',
      format: 'YYYY-MM-DD',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      type: 'select',
      label: 'Gender',
      name: 'gender',
      twocol: false,
      options: meta?.genders?.map((x) => ({ label: x, value: x })),
      req: false,
      placeholder: 'Please select',
      reqmessage: 'Gender Required',
      colWidth: '0 1 50%',
      static: !allowedEdit
    }
  ];

  useEffect(() => {
    if (data && 'id' in data && mode !== 'add') {
      if(data.calling_code) { 
        let temp = meta?.calling_codes?.find(x => x.calling_code == data.calling_code);
        setValue('calling_code', { label: `${temp.name} (+${temp.calling_code})`, value: temp.calling_code });
      }
      setValue('first_name', data.first_name);
      setValue('last_name', data.last_name);
      setValue('phone', data.phone);
      setValue('email', data.email);
      setValue('dob', data?.dob ? dayjs(data?.dob) : '');
      setValue('gender', data?.gender ? { label: data.gender, value: data.gender } : '');
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      ...val,
      gender: val?.gender?.value,
      calling_code: val?.calling_code?.value,
      dob: val?.dob ? dayjs(val?.dob).format("YYYY-MM-DD") : '',
      user_id: id
    }

    await (id ? editCustomer : addCustomer)(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(`Customer ${id ? "Updated" : "Created"} successfully!`);
        setTimeout(() => reloadApi(res.data.data), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>Save</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}