import React, { useEffect, useState } from 'react';
import { Row, Col, message, Button } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTaxList } from '../ducks/actions'
import { removeTax } from '../ducks/services'
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const taxes = useSelector((state) => state.systems.taxes);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [searchVal, setSearchVal] = useState(null);
  const allowedEdit = allowed([AllRoles.SYSTEM.TAX], 'write');
  const globalListQuery = useSelector((state) => state.global.listQueryItems);

  const colName = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Active" ? "c-success" : "c-danger"}>
            {text}
          </span>
          {
            allowedEdit &&
            <Button type="link" onClick={() => onAction(record.id, text)}>
              {text === "Active" ? "(Deactivate)" : "(Activate)"}
            </Button>
          }
        </div>
    },
  ];

  useEffect(() => {
    if(!globalListQuery.taxListQuery) {
      dispatch(getTaxList(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('taxListQuery', 1, limit, '', '', null, props.setLoading));
    } else {
      const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.taxListQuery
      dispatch(getTaxList(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }, []);

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getTaxList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
      dispatch(getListQueryItems('taxListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading))
    } else {
      dispatch(getTaxList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
      dispatch(getListQueryItems('taxListQuery', pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading))
    }
  }

  const onAction = async (tcId, status) => {
    props.setLoading(true);

    const payload = {
      tax_id: tcId,
      status_event: status === "Active" ? "deactivate" : "activate"
    }

    await removeTax(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Tax status has been updated successfully!");
        setTimeout(() => dispatch(getTaxList(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('taxListQuery', page, limit, '', '', searchVal, props.setLoading))
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <HeadingChip title={"Tax"} />
      </Col>
      <Col span={24} className="">
        <ListCard
          ListData={taxes?.list?.map(x => ({ ...x, key: x.id }))}
          onChange={onTableChange}
          ListCol={colName}
          pagination={{
            total: taxes?.total_count,
            current: page,
            pageSize: limit
          }}
        />
      </Col>
    </Row>
  )
}