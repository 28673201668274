import React, { useEffect, useState } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Upload, Empty } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getBoms } from '../../../../ducks/actions'
import { addBom, editBom, removeBom, bulkRemoveBoms } from '../../../../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { SelectField, CheckboxGroup } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';
import { dummyRequest, getFileName, uploadFileV2 } from 'Features/utility';
import { InputField } from '../../../../../../atoms/FormElement';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, meta, activeTab, allowedEdit, allowedDelete } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const boms = useSelector((state) => state.product.boms);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [rec, setRecord] = useState();
  const [fileList, setFileList] = useState([]);
  const [fileId, setFileId] = useState();
  const [load, setLoad] = useState(false);
  const [orderTypeList, setOrderTypeList] = useState([]);

  const colName = [
    {
      title: 'Image',
      dataIndex: 'image_url',
      key: 'image_url',
      render: (text, record) => <img width="100px" src={text}/>,
      className: 'enable-click',
      width: 150
    },
    {
      title: 'Order Type Group Code',
      dataIndex: 'order_type_group_code',
      key: 'order_type_code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Order Type Group Desc',
      dataIndex: 'order_type_group_desc',
      key: 'order_type_desc',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Selling Price',
      dataIndex: 'price_raw',
      key: 'price_raw',
      className: 'enable-click',
      sorter: true,
      align: 'right'
    },
    {
      title: 'Promo Price',
      dataIndex: 'promo_price',
      key: 'promo_price',
      className: 'enable-click',
      sorter: true,
      align: 'right'
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 200,
      render: (text, record) =>
        <ActionButton
          title="Bom"
          btnAction1={() => {
            setVisible(true);
            setRecord(record);
            setValue('order_type_group', { label: record?.order_type_group_code, value: record?.order_type_group_id });
            setValue('price', record.price_raw);
            setValue('promo_price', record.promo_price);
            let temp = orderTypeList;
            temp.push({
              label: record?.order_type_group_code,
              value: record?.order_type_group_id
            });
            setOrderTypeList(temp);
            if (record?.image_url) {
              setFileList([
                {
                  uid: '-1',
                  name: getFileName(record?.image_url),
                  status: 'done',
                  url: record?.image_url
                }
              ])
            }else {
              setFileList();
              setFileId();
            }
          }}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const addNew = () => { 
    setVisible(true);
    setRecord();
    setFileId();
    setFileList([]);
    resetOrderTypeList();
    setValue('order_type_group', '');
    setValue('order_type_groups', '');
    setValue('price', '');
    setValue('promo_price', '');
  }

  const btnList = [
    {
      title: 'Are you sure to remove the selected Order Type Group(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New Order Type Group',
      classes: 'attendance-success',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    if (activeTab === "2")
    dispatch(getBoms(1, limit, '', '', props.setLoading, id));
  }, [activeTab]);

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getBoms(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, props.setLoading, id));
    } else {
      dispatch(getBoms(pagination.current, pagination.pageSize, '', '', props.setLoading, id));
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRemove = async (bomId) => {
    props.setLoading(true);

    const payload = {
      product_id: id,
      bom_id: bomId,
      status_event: 'remove'
    }

    await removeBom(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Order Type Group has been removed successfully!");
        setTimeout(() => dispatch(getBoms(1, limit, '', '', props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      product_id: id,
      boms: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveBoms(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Order Type Groups has been removed successfully!");
        setTimeout(() => dispatch(getBoms(1, limit, '', '', props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      order_type_group_id: val?.order_type_group?.value,
      order_type_group_ids: JSON.stringify(val.order_type_groups) || "[]",
      product_id: id,
      blob_id: fileId,
      price: val?.price,
      promo_price: val?.promo_price,
      bom_id: rec?.id // bom_id
    }

    rec?.id ?
    await editBom(payload).then(res => {
      setLoad(false);
      if(res.data.code === 200) {
        message.success("Order Type Group updated successfully.");
        setVisible(false);
        setRecord();
        setFileId();
        setValue('order_type_group', '');
        setValue('order_type_groups', '');
        setValue('price', '');
        setValue('promo_price', '');
        setTimeout(() => dispatch(getBoms(1, limit, '', '', props.setLoading, id)), 500);
      }else {
        message.error(res.data.message);
      }
    }).catch(e =>{
      setLoad(false);
      message.error(e.message ?? "Something went wrong");
    }) :
    await addBom(payload).then(res => {
      setLoad(false);
      if(res.data.code === 200) {
        message.success("Order Type Group added successfully.");
        setVisible(false);
        setFileId();
        setValue('order_type_group', '');
        setValue('order_type_groups', '');
        setValue('price', '');
        setValue('promo_price', '');
        setTimeout(() => dispatch(getBoms(1, limit, '', '', props.setLoading, id)), 500);
      }else {
        message.error(res.data.message);
      }
    }).catch(e =>{
      setLoad(false);
      message.error(e.message ?? "Something went wrong");
    });
  };

  const handleUpload = async (e) => {
    setLoad(true);
    try {
      let file = await uploadFileV2(e.file.originFileObj);
      setLoad(false);
      setFileId(file?.id);
      setFileList([{ uid: '-1', name: getFileName(file?.url), status: 'done', url: file?.url }]);
    } catch (error) {
      message.error(`Error during file upload: ${error}`);
      setLoad(false);
    }
  };

  useEffect(() => {
    if(meta && 'order_type_groups' in meta) {
      resetOrderTypeList();
    }
  }, [meta, boms]);

  const resetOrderTypeList = () => {
    let temp = meta?.order_type_groups?.map(x => ({ label: x.desc, value: x.id }))
    temp = temp?.filter(x => boms?.list?.filter(y => y.order_type_group_id === x.value).length <= 0);
    setOrderTypeList(temp);
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={20} className='w-100'>
          <Title level={4} className='m-0'>
            {rec ? 'Edit' : 'Add'} Order Type Group
          </Title>
          {
            rec ?
              <>
                <div className='text-center'>
                  <Upload
                    disabled={!allowedEdit}
                    listType="picture-card"
                    className="avatar-uploader larger"
                    showUploadList={false}
                    accept="image/*"
                    maxCount={1}
                    fileList={fileList}
                    customRequest={dummyRequest}
                    onChange={(e) => handleUpload(e)}
                  >
                    <Space size={4}>
                      {fileList?.length > 0 ? (
                        <img
                          src={fileList[0].url}
                          alt="avatar"
                          style={{
                            width: '100%',
                          }}
                        />
                      ) :
                        <div>
                          <PlusOutlined />
                          <div
                            style={{
                              marginTop: 8,
                            }}
                          >
                            Upload
                          </div>
                        </div>
                      }
                    </Space>
                  </Upload>
                </div>
                <SelectField
                  isRequired={visible}
                  fieldname='order_type_group'
                  label='Order Type Group'
                  class='mb-1 w-100 default-select'
                  initValue={rec ? { label: rec?.order_type_group_code, value: rec?.order_type_group_id } : ''}
                  control={control}
                  iProps={{ placeholder: 'Please select', isDisabled: !allowedEdit }}
                  selectOption={orderTypeList}
                  rules={{
                    required: 'Order Type Group is required',
                  }}
                  validate={errors.order_type_group && 'error'}
                  validMessage={errors.order_type_group && errors.order_type_group.message}
                />
                <InputField
                  isRequired={visible}
                  fieldname='price'
                  label='Selling Price'
                  class='mb-1 w-100'
                  initValue={''}
                  control={control}
                  iProps={{ placeholder: 'Enter Selling Price', readOnly: !allowedEdit }}
                  rules={{
                    required: 'Selling Price is required',
                  }}
                  validate={errors.price && 'error'}
                  validMessage={errors.price && errors.price.message}
                />
                <InputField
                  fieldname='promo_price'
                  label='Promo Price'
                  class='mb-1 w-100'
                  initValue={''}
                  control={control}
                  iProps={{ placeholder: 'Enter Promo Price', readOnly: !allowedEdit }}
                />
              </>
            :
            (
              orderTypeList?.length === 0 ?
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              :
                // <Space size={10} className='w-100' style={ orderTypeList?.length > 2 ? {display: 'flex'} : {}}>
                  <CheckboxGroup
                    disabled={!allowedEdit}
                    fieldname={'order_type_groups'}
                    label={''}
                    class={`mb-1 fullWidth-checbox`}
                    control={control}
                    initValue={[]}
                    option={orderTypeList}
                    rules={{
                      required: 'Order Type Group is required',
                    }}
                    validate={errors.order_type_groups && 'error'}
                    validMessage={errors.order_type_groups && errors.order_type_groups.message}
                  />
                // </Space>
            )
          }
          <Row gutter={10} justify={'center'}>
            <Col span={orderTypeList?.length === 0 && !rec ? 24 : 12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('order_type_group', '');
              setValue('order_type_groups', '');
              setValue('price', '');
              setValue('promo_price', '');
              rec && setRecord();
              resetOrderTypeList();
            }}>Cancel</Button></Col>
            {
              (orderTypeList?.length > 0 || rec) && allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setValue('order_type_group', '');
      setValue('order_type_groups', '');
      setValue('price', '');
      setValue('promo_price', '');
      setRecord();
      resetOrderTypeList();
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click")){
          setVisible(true);
          setRecord(record);
          setValue('order_type_group', { label: record?.order_type_group_code, value: record?.order_type_group_id });
          let temp = orderTypeList;
          temp.push({
            label: record?.order_type_group_code,
            value: record?.order_type_group_id
          });
          setOrderTypeList(temp);
          setValue('price', record?.price_raw);
          setValue('promo_price', record?.promo_price);
          record?.image_url ? setFileList([
            {
              uid: '-1',
              name: getFileName(record?.image_url),
              status: 'done',
              url: record?.image_url
            }
          ]) : setFileList([]);
        }
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={""} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onRow={onClickRow}
            listClass="nopad"
            ListData={boms?.list?.map(x => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            ListCol={colName}
            rowSelection={rowSelection}
            pagination={{
              total: boms?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}