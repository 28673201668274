import React, { Fragment, useEffect, useState } from 'react';
import { Select, Form, Row, Col, Space, Typography, Spin, Button, message, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined, PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { InputField, SelectField, TimeField } from 'Atoms/FormElement';
import { addMenuCategory, editMenuCategory, removeMenuCategory, updateMenuCategoryOrder, duplicateMenuCategory, syncMenu } from '../../../../../../../../ducks/services'
import { dummyRequest, getFileName, uploadFileV2 } from 'Features/utility';
import DndListCard from 'Molecules/DndListCard';
import ActionButton from 'Molecules/ActionButton';
import dayjs from 'dayjs';
import FormGroup from 'Molecules/FormGroup';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const { id, activeTab, navigate, isKiosk, getMenuCategoriesOption, subTab, reloadApi, allowedEdit, allowedDelete } = props;
  const dispatch = useDispatch();
  const { control, formState: { errors }, handleSubmit, setValue, clearErrors } = useForm();
  const productMenu = useSelector((state) => state.product.productMenu);
  const option = useSelector((state) => state.product.menuCategoryOption);
  const selectedMenuType = useSelector((state) => state.product.selectedMenuType);
  const [visible, setVisible] = useState(false);
  const [duplicateVisible, setDuplicateVisible] = useState(false);
  // const [syncVisible, setSyncVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileId, setFileId] = useState();
  const [load, setLoad] = useState(false);
  const [hasDirty, setHasDirty] = useState(false);
  const [data, setData] = useState([])

  const ListCol = [
    {
      title: 'Image',
      dataIndex: 'image_url',
      key: 'image_url',
      sorter: true,
      width: 150,
      className: 'enable-click',
      render: (text) => <img width="100px" src={text} />
    },
    {
      title: 'Name',
      dataIndex: 'desc',
      key: 'desc',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Availability',
      dataIndex: 'work_schedule_name',
      key: 'work_schedule_name',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Start Time',
      dataIndex: 'valid_from',
      key: 'valid_from',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'End Time',
      dataIndex: 'valid_to',
      key: 'valid_to',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Products',
      dataIndex: 'products_count',
      key: 'products_count',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Active" ? "c-success" : "c-pending"}>
            {text}
          </span>
          {
            allowedEdit &&
            <Button type="link" onClick={() => onAction(record.id, text)}>
              {text === "Active" ? "(Draft)" : "(Activate)"}
            </Button>
          }
        </div>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) =>
        <ActionButton
          title="Menu Category"
          btnAction1={() => navigate(`category/${record.id}/items?isKiosk=${isKiosk}`)}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ]

  useEffect(() => {
    if (activeTab && activeTab === "3" && (subTab === "1" && isKiosk === "1" || subTab === "2" && isKiosk === "2")) {
      callApi();
    }
  }, [activeTab, isKiosk, subTab]);

  useEffect(() => {
    if(selectedMenuType && 'menu_categories' in selectedMenuType) {
      setData(selectedMenuType?.menu_categories);
      // if(selectedMenuType?.can_sync === "1") setSyncVisible(true)
      // else setSyncVisible(false)
    } else {
      setData([])
      // setSyncVisible(false)
    }
  }, [selectedMenuType]);

  const callApi = () => {
    dispatch(getMenuCategoriesOption(isKiosk - 1, id, props.setLoading)); // minus 1 because antd tab value starts at 1 and backend accepts 0 and 1 only
  }

  const onFinish = async (val) => {
    setLoad(true);

    let validToDate = '';
    let validToTime = '';

    if(val?.valid_to) validToDate = dayjs(val?.valid_to).format("YYYY-MM-DD");

    if(val?.valid_to_time) validToTime = dayjs(val?.valid_to_time).format("HH:mm")

    const payload = {
      product_menu_id: id,
      product_menu_type_id: selectedMenuType?.id,
      valid_from: dayjs(val?.valid_from).format("YYYY-MM-DD") + " " + dayjs(val?.valid_from_time).format("HH:mm"),
      valid_to: `${validToDate} ${validToTime}`.trim(),
      work_schedule_id: val?.availability?.value,
      apply_promo_price: val?.apply_promo_price ? 1 : 0,
      desc: val?.desc,
      menu_category_id: val?.id,
      blob_id: fileId,
      is_kiosk: isKiosk - 1, // minus 1 because antd tab value starts at 1 and backend accepts 0 and 1 only
      auto_sort_by_sales: val?.auto_sort_by_sales ? 1 : 0,
      auto_sort_scope: val?.auto_sort_scope?.value,
      auto_populate_item_by_sales: val?.auto_populate_item_by_sales ? 1 : 0,
      auto_populate_item_count: val?.auto_populate_item_count,
      is_upsell: val?.is_upsell ? 1 : 0,
      hide_kiosk: val?.hide_kiosk ? 1 : 0,
    }

    val?.id ?
      await editMenuCategory(payload).then(res => {
        setLoad(false);
        if (res.data.code === 200) {
          message.success("Menu Category updated successfully.");
          setVisible(false);
          setTimeout(() => callApi(), 500);
        } else {
          message.error(res.data.message);
        }
      }).catch(e => {
        setLoad(false);
        message.error(e.message ?? "Something went wrong");
      }) :
      await addMenuCategory(payload).then(res => {
        setLoad(false);
        if (res.data.code === 200) {
          message.success("Menu Category added successfully.");
          setVisible(false);
          setTimeout(() => callApi(), 500);
        } else {
          message.error(res.data.message);
        }
      }).catch(e => {
        setLoad(false);
        message.error(e.message ?? "Something went wrong");
      });
  };

  const onUpdateList = async (datalist) => {
    props.setLoading(true);

    const payload = {
      menu_categories: JSON.stringify(datalist?.map((d, i) => ({id: d.id, position: i + 1})))
    }
    await updateMenuCategoryOrder(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Menu Category Order updated successfully.");
        setVisible(false);
        setTimeout(() => callApi(), 500);
        setHasDirty(false);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onDuplicate = async (val) => {
    setLoad(true);

    const payload = {
      product_menu_type_id: val?.duplicate?.value,
      duplicate_id: selectedMenuType?.id
    }
    await duplicateMenuCategory(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success("Menu Category Duplicated Successfully.");
        setDuplicateVisible(false);
        setTimeout(() => { 
          callApi();
        }, 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onAction = async (recordId, status) => {
    props.setLoading(true);

    const payload = {
      product_menu_type_id: selectedMenuType?.id,
      menu_category_id: recordId,
      status_event: status === 'Active' ? 'draft' : 'activate'
    }

    await removeMenuCategory(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Menu Category status has been updated successfully!");
        callApi();
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onRemove = async (cid) => {
    props.setLoading(true);

    const payload = {
      product_menu_type_id: selectedMenuType?.id,
      menu_category_id: cid,
      status_event: 'remove'
    }

    await removeMenuCategory(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Menu Category has been removed successfully!");
        callApi();
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const handleUpload = async (e) => {
    setLoad(true);
    try {
      let file = await uploadFileV2(e.file.originFileObj);
      setLoad(false);
      setFileId(file?.id);
      setFileList([{ uid: '-1', name: file?.filename, status: 'done', url: file?.url }]);
    } catch (error) {
      message.error(`Error during file upload: ${error}`);
      props.setLoading(false);
    }
  };

  const onSync = async () => {
    props.setLoading(true);

    const payload = {
      product_menu_type_id: selectedMenuType?.id,
      product_menu_id: id,
    }

    await syncMenu(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Sync successfully!");
        reloadApi();
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const formFields = [
    {
      name: 'desc',
      label: 'Name',
      placeholder: 'Please enter',
      req: true,
      type: 'input',
      reqmessage: 'Required',
      twocol: false,
      colWidth: '1 0 100%',
      static: !allowedEdit
    },
    {
      name: 'availability',
      label: 'Availability',
      placeholder: 'Please select',
      req: true,
      type: 'select',
      reqmessage: 'Required',
      twocol: false,
      options: option?.work_schedules?.map((x) => ({ label: x.name, value: x.id })),
      colWidth: '1 0 100%',
      class: 'default-select',
      static: !allowedEdit
    },
    {
      name: 'valid_from',
      label: 'Valid From Date',
      placeholder: 'Please select',
      req: true,
      type: 'date',
      reqmessage: 'Date required',
      twocol: false,
      format: 'YYYY-MM-DD',
      colWidth: '1 0 50%',
      static: !allowedEdit
    },
    {
      name: 'valid_from_time',
      label: 'Time',
      placeholder: 'Please select',
      req: true,
      type: 'time',
      reqmessage: 'Time required',
      twocol: false,
      format: 'HH:mm',
      colWidth: '1 0 50%',
      static: !allowedEdit
    },
    {
      name: 'valid_to',
      label: 'Valid To Date',
      placeholder: 'Please select',
      // req: true,
      type: 'date',
      reqmessage: 'Date required',
      twocol: false,
      format: 'YYYY-MM-DD',
      colWidth: '1 0 50%',
      static: !allowedEdit
    },
    {
      name: 'valid_to_time',
      label: 'Time',
      placeholder: 'Please select',
      // req: true,
      type: 'time',
      reqmessage: 'Time required',
      twocol: false,
      format: 'HH:mm',
      colWidth: '1 0 50%',
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'auto_sort_by_sales',
      label: 'Auto Sort By Sales',
      req: false,
      twocol: false,
      colWidth: '1 0 50%',
      alignEnd: true,
      alignEnd: false, // move form to top
      labelPosition: 'top', // move label to top
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'apply_promo_price',
      label: 'Apply Promo Price?',
      req: false,
      twocol: false,
      colWidth: '1 0 50%',
      alignEnd: true,
      alignEnd: false, // move form to top
      labelPosition: 'top', // move label to top
      static: !allowedEdit
    },
    // {
    //   name: 'auto_sort_scope',
    //   label: 'Auto Sort By Sales Scope',
    //   placeholder: 'Please select',
    //   req: false,
    //   type: 'select',
    //   reqmessage: 'Required',
    //   twocol: false,
    //   options: option?.sales_scopes?.map((x) => ({ label: x, value: x })),
    //   colWidth: '1 0 50%',
    //   class: 'default-select'
    // },
    {
      type: 'switch',
      name: 'auto_populate_item_by_sales',
      label: 'Auto Populated Item By Sales',
      req: false,
      twocol: false,
      colWidth: '1 0 50%',
      alignEnd: true,
      alignEnd: false, // move form to top
      labelPosition: 'top',
      static: !allowedEdit
    },
    {
      name: 'auto_populate_item_count',
      label: 'Auto Populated Item Count',
      placeholder: 'Please enter',
      req: false,
      type: 'input',
      reqmessage: 'Required',
      twocol: false,
      colWidth: '1 0 50%',
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'is_upsell',
      label: 'Is Upsell?',
      req: false,
      twocol: false,
      colWidth: '1 0 50%',
      alignEnd: false, // move form to top
      labelPosition: 'top', // move label to top
      hidden: isKiosk === "1",
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'hide_kiosk',
      label: 'Hide in Kiosk?',
      req: false,
      twocol: false,
      colWidth: '1 0 50%',
      alignEnd: true,
      alignEnd: false, // move form to top
      labelPosition: 'top',
      static: !allowedEdit,
      hidden: isKiosk === "2",
    },
  ];

  const duplicateFormFields = [
    {
      name: 'duplicate',
      label: 'Menu Type',
      placeholder: 'Please select',
      req: true,
      type: 'select',
      reqmessage: 'Required',
      twocol: false,
      options: option?.product_menu_types?.filter(x => x.id !== selectedMenuType?.id)?.map((x) => ({ label: x.order_type_desc, value: x.id })),
      colWidth: '1 0 100%',
      class: 'default-select',
      static: !allowedEdit
    }
  ];

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={15} className='w-100'>
          <Title level={4} className='m-0'>
            Add Menu Category
          </Title>
          <InputField
            fieldname='id'
            label=''
            class='d-none'
            initValue={''}
            control={control}
          />
          <div className='text-center'>
            <Upload
              disabled={!allowedEdit}
              listType="picture-card"
              className="avatar-uploader larger"
              showUploadList={false}
              accept="image/*"
              maxCount={1}
              fileList={fileList}
              customRequest={dummyRequest}
              onChange={(e) => handleUpload(e)}
            >
              <Space size={4}>
                {fileList?.length > 0 ? (
                  <img
                    src={fileList[0].url}
                    alt="avatar"
                    style={{
                      width: '100%',
                    }}
                  />
                ) :
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </div>
                }
              </Space>
            </Upload>
          </div>
          <Row gutter={[10, 10]}>
            {formFields?.map((x, idx) => (
              <Fragment key={idx}>
                <FormGroup item={x} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              clearErrors();
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      clearErrors();
    },
  };

  const popup2 = {
    closable: false,
    visibility: duplicateVisible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onDuplicate)}>
        <Space direction='vertical' size={15} className='w-100'>
          <Title level={4} className='m-0'>
            Duplicate Menu Category
          </Title>
          <Row gutter={[10, 10]}>
            {duplicateFormFields?.map((x, idx) => (
              <Fragment key={idx}>
                <FormGroup item={x} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setDuplicateVisible(false);
              setValue('duplicate', '');
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setDuplicateVisible(false);
      setValue('duplicate', '');
    },
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={24} className='text-right btn-list-container'>
          <Space size={10}>
            {
              hasDirty && allowedEdit &&
              <Button type='primary' htmlType='button' className='b-success attendance-success' onClick={() => onUpdateList(data)}>
                Update Listing
              </Button>
            }
            {
              allowedEdit &&
              <>
                {/* <Button type='primary' htmlType='button' className='b-success attendance-success' disabled={productMenu?.sync_completed == "0"} onClick={() => onSync()}>
                  Sync Menu
                </Button> */}
                <Button type='primary' htmlType='button' className='b-success attendance-success' onClick={() => {
                  setDuplicateVisible(true);
                  setValue('duplicate', '');
                }}>
                  Duplicate Menu Category
                </Button>
                <Button type='primary' htmlType='button' className='attendance-success' onClick={() => {
                  setVisible(true);
                  setValue('id', null);
                  setValue('desc', '');
                  setValue('availability', null);
                  setValue('apply_promo_price', false);
                  setValue('valid_from', '');
                  setValue('valid_to', '');
                  setValue('valid_from_time', '');
                  setValue('valid_to_time', '');
                  setValue('auto_sort_by_sales', false);
                  setValue('auto_sort_scope', false);
                  setValue('auto_populate_item_by_sales', null);
                  setValue('auto_populate_item_count', '');
                  setFileList([]);
                  setFileId();
                }}>
                  + Add New Menu Category
                </Button> 
              </>
            }
          </Space>
        </Col>

        <Col span={24} className="clickRow">
          <DndListCard
            setHasDirty={setHasDirty}
            setDataList={setData}
            list={data}
            classes="nopad"
            ListCol={ListCol}
            ListData={data?.map(x => ({ ...x, key: x.id })) || []}
            pagination={false}
          />
        </Col>
      </Row>
      <Popup {...popup} />
      <Popup {...popup2} />
    </>
  );
}