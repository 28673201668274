import React, { useEffect, useState } from 'react';
import { Row, Col, Button, message, Space, Popconfirm } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerList } from '../ducks/actions'
import { removeCustomer, bulkRemoveCustomers } from '../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search'
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.crm.customers);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState(null);
  const globalListQuery = useSelector((state) => state.global.listQueryItems);

  const colName = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
      className: 'enable-click',
      sorter: true,
      render: (text, record) => <span>+{record?.calling_code}{text}</span>
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      className: 'enable-click',
      sorter: true,
      render: (text) => <span>{text === "M" ? "Male" : text === "F" ? "Female" : text}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Active" ? "c-success" : "c-danger"}>
            {text}
          </span>
          {
            allowed([AllRoles.CRM.CUSTOMER], 'write') &&
            <Button type="link" onClick={() => onAction(record.id, text)}>
              {text === "Active" ? "(Deactivate)" : "(Activate)"}
            </Button>
          }
        </div>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="Customer"
          btnAction1={() => navigate(`/crm/customers/${record.id}`)}
          recordId={record.id}
          onRemove={allowed([AllRoles.CRM.CUSTOMER], 'delete') && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ]

  const addNew = () => navigate('/crm/customers/add');

  const btnList = [
    {
      title: 'Are you sure to remove the selected Customer(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New Customer',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    if(!globalListQuery.customerListQuery) {
      dispatch(getCustomerList(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('customerListQuery', 1, limit, '', '', null, props.setLoading));
    } else {
      const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.customerListQuery
      dispatch(getCustomerList(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }, []);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onAction = async (customerId, status) => {
    props.setLoading(true);

    const payload = {
      user_id: customerId,
      status_event: status === 'Active' ? 'deactivate' : 'activate'
    }

    await removeCustomer(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Customer status has been updated successfully!");
        setTimeout(() => dispatch(getCustomerList(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('customerListQuery', page, limit, '', '', searchVal, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onRemove = async (id) => {
    props.setLoading(true);

    const payload = {
      user_id: id,
      status_event: 'remove'
    }

    await removeCustomer(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Customer has been removed successfully!");
        setTimeout(() => dispatch(getCustomerList(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('customerListQuery', page, limit, '', '', searchVal, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      customers: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveCustomers(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Customers has been removed successfully!");
        setPage(1);
        setSearchVal(null);
        setTimeout(() => dispatch(getCustomerList(1, limit, '', '', null, props.setLoading)), 500);
        dispatch(getListQueryItems('customerListQuery', 1, limit, '', '', null, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getCustomerList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
      dispatch(getListQueryItems('customerListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading))
    } else {
      dispatch(getCustomerList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
      dispatch(getListQueryItems('customerListQuery', pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading))
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        name: search.name
      };
      setSearchVal(searching);
      dispatch(getCustomerList(1, limit, '', '', searching, props.setLoading));
      dispatch(getListQueryItems('customerListQuery', 1, limit, '', '', searching, props.setLoading))
    } else {
      setSearchVal(null);
      dispatch(getCustomerList(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('customerListQuery', 1, limit, '', '', null, props.setLoading))
    }
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click"))
          navigate(`/crm/customers/${record.id}`)
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Customers"} btnList={allowed([AllRoles.CRM.CUSTOMER], 'write') ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            Search={Search}
            onSearch={onSearch}
            searchVal={globalListQuery.customerListQuery?.searchValue}
            onRow={onClickRow}
            // listClass="nopad"
            ListData={customers?.list?.map(x => ({ ...x, key: x.id }))}
            ListCol={colName}
            rowSelection={rowSelection}
            onChange={onTableChange}
            pagination={{
              total: customers?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
    </>
  )
}