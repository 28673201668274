import React, { useEffect, useState } from 'react';
import { Row, Typography, Col, Card, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getStatistics } from './ducks/actions';
import Search from './components/Search';
import dayjs from 'dayjs';
import { 
  LineChartOutlined, CalculatorOutlined, DeleteOutlined,
  FormOutlined, ShoppingCartOutlined, DislikeOutlined, TagsOutlined,
  TeamOutlined, PieChartOutlined, BarChartOutlined,
  AppstoreOutlined, ShoppingOutlined, ToolOutlined,
  PayCircleOutlined
} from '@ant-design/icons';
import { comma } from 'Features/utility';
import { useResponsiveState } from '../../contexts/ResponsiveContext';

const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.global.entityStats);
  const meta = useSelector((state) => state.global.statsMeta);
  const [searchVal, setSearchVal] = useState(null);
  const [regions, setRegions] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const {isMobile} = useResponsiveState();

  useEffect(() => {
    dispatch(getStatistics(searchVal, props.setLoading));
  }, []);

  useEffect(() => {
    if(meta) {
      if ('locations' in meta) {
        let temp = [];
        meta?.locations?.map((item, index) => {
          if (index === 0) {
            temp.push({
              label: "All Outlets",
              value: ""
            })
          }
          temp.push({
            label: `${item.code} ${item.desc}`,
            value: item.id,
            region_id: item.region_id
          })
        });
        setOutlets(temp);
      }

      if ('regions' in meta) {
        let temp = [];
        meta?.regions?.map((item, index) => {
          if (index === 0) {
            temp.push({
              label: "All Regions",
              value: ""
            })
          }
          temp.push({
            label: item.code,
            value: item.id
          })
        });
        setRegions(temp);
      }

      if(meta?.organization_name) {
        localStorage.setItem("organization_name", meta?.organization_name);
      }
    }
  }, [meta]);

  const onSearch = (search) => {
    if (search) {
      let searching = {};
      searching = {
        date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : "",
        date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : "",
        is_non_sales: search?.is_non_sales?.value
      };
      if (search?.outlets?.length > 0) {
        let temp = [];
        search?.outlets?.map(x => {
          temp.push(x.value)
        });
        searching["location_ids"] = temp;
      }
      if (search?.regions?.length > 0) {
        let temp2 = [];
        search?.regions?.map(x => {
          temp2.push(x.value)
        });
        searching["region_ids"] = temp2;
      }
      setSearchVal(searching);
      dispatch(getStatistics(searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getStatistics(null, props.setLoading));
    }
  };

  const iconList = {
    LineChartOutlined,
    CalculatorOutlined,
    DeleteOutlined,
    FormOutlined,
    ShoppingCartOutlined,
    DislikeOutlined,
    TagsOutlined,
    TeamOutlined,
    PieChartOutlined,
    BarChartOutlined,
    AppstoreOutlined,
    ShoppingOutlined,
    ToolOutlined,
    PayCircleOutlined
  }

  const chartList = [
    {
      title: "Net Sales",
      value: "net_sales",
      icon: "LineChartOutlined",
      fontColor: "white",
      color: "#3a7ee3",
      color2: "#639be8"
    },
    {
      title: "Discount",
      value: "discount",
      icon: "CalculatorOutlined",
      fontColor: "white",
      color: "#e72826",
      color2: "#eb5453"
    },
    {
      title: "Tax",
      value: "tax",
      icon: "ToolOutlined",
      fontColor: "white",
      color: "#020305",
      color2: "#141e27"
    },
    {
      title: "Charges",
      value: "charges",
      icon: "TagsOutlined",
      fontColor: "white",
      color: "#35957a",
      color2: "#41bd9b"
    },
    {
      title: "Quantity",
      value: "quantity",
      icon: "ShoppingCartOutlined",
      fontColor: "white",
      color: "#f27821",
      color2: "#fb9239"
    },
    {
      title: "Transaction Count",
      value: "trans_count",
      icon: "FormOutlined",
      fontColor: "black",
      color: "#f0cb34",
      color2: "#f8d74a"
    },
    {
      title: "Voided Trans. Count",
      value: "voided_trans_count",
      icon: "DeleteOutlined",
      fontColor: "white",
      color: "#b78624",
      color2: "#d8a635"
    },
    {
      title: "Cancelled Trans. Count",
      value: "cancelled_trans_count",
      icon: "DislikeOutlined",
      fontColor: "black",
      color: "#fa1b90",
      color2: "#feadd8"
    },
    {
      title: "Average Value",
      value: "average_value",
      icon: "PieChartOutlined",
      fontColor: "black",
      color: "#393f4f",
      color2: "#dde7e9"
    },
    {
      title: "Average Unit",
      value: "average_unit",
      icon: "BarChartOutlined",
      fontColor: "white",
      color: "#5749a0",
      color2: "#7366b8"
    },
    {
      title: "Customer Count",
      value: "customer_count",
      icon: "TeamOutlined",
      fontColor: "white",
      color: "#e40ad2",
      color2: "#ef33e1"
    },
    {
      title: "Customer Average Value",
      value: "customer_average_value",
      icon: "PayCircleOutlined",
      fontColor: "black",
      color: "#43bc9b",
      color2: "#60ceb3"
    },
    {
      title: "Open Order Quantity",
      value: "open_order_quantity",
      value: "customer_average_value",
      icon: "AppstoreOutlined",
      fontColor: "black",
      color: "#ffdeb1",
      color2: "#fde5b7"
    },
    {
      title: "Open Order Amount",
      value: "open_order_amount",
      icon: "ShoppingOutlined",
      fontColor: "black",
      color: "#d1b58d",
      color2: "#f4deb5"
    }
  ];

  return (
    <Row gutter={[20, 20]}>
      {/* <Col span={24}>
        <Title level={3} className='text-center mb-0'>Welcome to <br/> {company}</Title>
      </Col> */}
      <Col span={24}>
        <Title level={3} className='mb-0'>Statistics Dashboard</Title>
      </Col>

      {/* allowed([Roles.ATTENDANCE_INDIVIDUAL], 'read') && */}
      <Col span={24}>
        <Card bordered={false}>
          <Search onSearch={onSearch} field1={regions} field2={outlets} />
        </Card>
      </Col>

      <Col span={24}>
        <Row gutter={[20, 20]} className=''>
          {
            chartList?.map((item, index) => {
              const IconComp = iconList[item.icon];
              return (
                <Col span={isMobile ? 24 : 6} key={index}>
                  <div className='brand-card' style={{ border: "", borderRadius: "5px", background: item.color2 }}>
                    <div className="d-flex align-items-center">
                      <div style={{ borderRight: `1px solid ${item.color}`, borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", padding: "30px", background: item.color }}>
                        <IconComp style={{ fontSize: "30px", color: "white" }} />
                      </div>
                      <div className="w-100">
                        <Space direction={'vertical'} size={10} className='text-center w-100' style={{color: item.fontColor}}>
                          <h3 className='mb-0'>{comma(data?.[item.value])}</h3>
                          <span>{item.title}</span>
                        </Space>
                      </div>
                    </div>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Col>
    </Row>
  );
};
