import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Typography, Table, message, Empty } from 'antd';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getCancelledSalesListing } from '../ducks/actions'
import { exportSalesReport } from '../ducks/services'
import Search from './components/Search';
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { comma } from 'Features/utility';

const { Title, Text } = Typography;
const _ = require('lodash');

export default (props) => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.reporting.cancelledSalesListing);
  const meta = useSelector((state) => state.reporting.cancelledSalesListingOption);
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);

  const btnList = [
    {
      text: 'Export Report',
      classes: 'green-btn',
      action: () => exportReport(),
    }
  ];

  useEffect(() => {
    dispatch(getCancelledSalesListing(null, props.setLoading, true));
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  useEffect(() => {
    if (meta) {
      if ("locations" in meta) {
        let temp = [];
        meta.locations?.map((x, ind) => {
          temp.push({
            label: `${x.code} ${x.desc}`,
            value: x.id
          })
        });
        setOutlets(temp);
      }
    }
  }, [meta]);

  const onSearch = (search) => {
    if (search && 'outlets' in search) {
      let searching = {};
      searching = {
        date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : "",
        date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : "",
      };
      if (search?.outlets?.length > 0) {
        let temp2 = [];
        search?.outlets?.map(x => {
          temp2.push(x.value)
        });
        searching["location_ids"] = JSON.stringify(temp2);
      }
      setSearchVal(searching);
      dispatch(getCancelledSalesListing(searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getCancelledSalesListing(null, props.setLoading));
    }
  };

  const exportReport = async () => {
    if (!orders?.total_count || orders?.total_count === 0) {
      return message.warning("No data to be exported.");
    }
    props.setLoading(true);

    await exportSalesReport(searchVal, "cancelled_sales_listing").then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    })
  };

  // test this
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          {/* update roles to SALES_SUMMARY, create new permission */}
          <HeadingChip title={"Refunded Sales Report"} btnList={allowed([AllRoles.REPORTING.SALES_DETAILS], 'write') ? btnList : null} />
        </Col>
        <Col span={24} className="">
          <Card bordered={false}>
            <Search onSearch={onSearch} field1={outlets} />

            <div className="table-responsive" style={{ overflowX: 'auto', marginTop: "30px" }}>
              <table className='table table-borderless w-100' style={{ borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th className='text-center'>Outlet</th>
                    <th className=''>Bill No.</th>
                    <th>Authorise POS User</th>
                    <th>Item</th>
                    <th className='text-center'>Refunded Date</th>
                    <th className='text-right'>Unit Price</th>
                    <th className='text-right'>Item Qty</th>
                    <th className='text-right'>Gross Amount</th>
                    <th className='text-right'>Discount</th>
                    <th className='text-right'>Net Sales</th>
                    <th className='text-right'>Tax</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    orders?.list?.length <= 0 &&
                    <tr>
                      <td colSpan="11">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  }
                  {
                    orders?.list?.length > 0 &&
                    orders?.list?.map((x, ind) => {
                      return (
                        <Fragment key={ind}>
                          <tr key={ind}>
                            <td colSpan="11" className='fwbold' style={{paddingLeft: "30px"}}>{"Business Date: "} {x.date}</td>
                          </tr>
                          {
                            x.list?.map((y, yIndex) => {
                              return (
                                <Fragment key={yIndex}>
                                  <tr>
                                    <td colSpan="11" className='fwbold' style={{ paddingLeft: "45px" }}>{"Reason: "} {y.reason}</td>
                                  </tr>
                                  {
                                    y.list.map((z, zIndex) => {
                                      return (
                                        <tr key={zIndex}>
                                          <td style={{ paddingLeft: "45px" }}>{z.location_code}</td>
                                          <td>{z.order_no}</td>
                                          <td>{z.authorized_by}</td>
                                          <td>{z.item}</td>
                                          <td className='text-center'>{z.cancelled_at}</td>
                                          <td align='right'>{comma(z.unit_price)}</td>
                                          <td align='right'>{comma(z.item_qty)}</td>
                                          <td align='right'>{comma(z.gross_amount_excl)}</td>
                                          <td align='right'>{comma(z.discount)}</td>
                                          <td align='right'>{comma(z.net_sales)}</td>
                                          <td align='right'>{comma(z.tax_amount)}</td>
                                        </tr>
                                      )
                                    })
                                  }
                                  <tr className='bg-gray text-white fwbold'>
                                    <td colSpan={5}>SubTotal</td>
                                    {y.sub_totals.map((z, zIndex) => (
                                      <td align='right' key={zIndex}>{z}</td>
                                    ))}
                                  </tr>
                                </Fragment >
                              )
                            })
                          }
                          {
                            x.list.length > 1 &&
                            <tr className='bg-gray text-white fwbold'>
                              <td align='' colSpan="5">SubTotal</td>
                              {
                                x?.sub_totals && Object.entries(x?.sub_totals)?.map(([key, data], zIndex) => {
                                  return (
                                    <td key={zIndex} align='right'>{comma(data)}</td>
                                  )
                                })
                              }
                            </tr>
                          }
                        </Fragment>
                      )
                    })
                  }
                  <tr className='bg-gray fwbold text-white'>
                    {
                      orders?.list?.length <= 0
                        ? <td colSpan="12">Grand Total</td>
                        : <td align='' colSpan="5">Grand Total</td>
                    }
                    {
                      orders?.grand_totals && Object.entries(orders?.grand_totals)?.map(([key, data], index) => {
                        return (
                          <td key={index} align='right'>
                            {comma(data)}
                          </td>
                        )
                      })
                    }
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}