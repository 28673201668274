import axios from 'Services/axiosInterceptor';
import { externalBaseUrl } from '../../../../configs/constants';

export const exportSoReport = (reportType, search = {}, selectedRowKeys) => {
  let q = JSON.stringify({
    order_no_cont: search?.order_no,
    order_type_id_eq: search?.order_type,
    report_date_gteq: search?.start_date,
    report_date_lteq: search?.end_date,
    payments_payment_method_id_eq: search?.tender_type,
    is_non_sales_eq: search?.is_non_sales,
    status_eq: search?.status,
    outlets: search?.outlet,
  });
  return axios.get(`${externalBaseUrl}/reportings/export-so-report?report_type=${reportType}&q=${q}&so_ids=${JSON.stringify(selectedRowKeys)}`, { responseType: 'blob'});
};

export const exportDailySummaryReport = (search = {}) => {
  let q = JSON.stringify({
    ...search
  });
  return axios.get(`${externalBaseUrl}/reportings/export-daily-summary-report?q=${q}`, { responseType: 'blob'});
};

export const exportSalesPayment = (search = {}) => {
  let q = JSON.stringify({
    ...search
  });
  return axios.get(`${externalBaseUrl}/reportings/export-sales-payment-report?q=${q}`, { responseType: 'blob'});
};

export const exportSalesDetail = (search = {}) => {
  let q = JSON.stringify({
    ...search
  });
  return axios.get(`${externalBaseUrl}/reportings/export-sales-detail-report?q=${q}`, { responseType: 'blob'});
};

export const exportSalesSummary = (search = {}) => {
  var obj = { ...search };
  var queryString = Object.keys(obj).map(key => key + "=" + encodeURIComponent(obj[key])).join("&");

  return axios.get(`${externalBaseUrl}/reportings/export-sales-reports?${queryString}`, { responseType: 'blob'});
};

export const exportSalesReport = (search = {}, type, rowkeys) => {
  var obj = { ...search, report_type: type, rowkeys: rowkeys };
  obj = removeUndefinedObject(obj);
  var queryString = Object.keys(obj).map(key => key + "=" + encodeURIComponent(obj[key])).join("&");

  return axios.get(`${externalBaseUrl}/reportings/export-sales-reports?${queryString}`, { responseType: 'blob'});
};

export const getInventoryList = (payload) => {
  return axios.post(`${externalBaseUrl}/sales-orders/inventory-list`, payload);
};

export const getSalesOrderItemList = (payload) => {
  return axios.post(`${externalBaseUrl}/sales-orders/sales-order-item-list`, payload);
};

function removeUndefinedObject(obj) {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  }
  return obj;
}