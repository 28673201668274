import React, { useEffect, Fragment, useState } from 'react';
import { Form, Button, Row, Col, message, Spin, Space, Typography } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getFaSyncLogs } from '../../../../ducks/actions';
import { updateFaIntegration, resyncSingleFa } from '../../../../ducks/services';
import { LoadingOutlined } from '@ant-design/icons';
import { Popup } from 'Atoms/Popup';
import { InputPassword } from 'Atoms/FormElement';

const _ = require('lodash');

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const logs = useSelector((state) => state.outlet.faSyncLogs);
  const { data, id, allowedEdit, meta, reloadApi } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const { control: control2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, setValue: setValue2 } = useForm();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const singapore = meta?.country === "Singapore"; // check by organization.country

  const colName = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true
    },
    {
      title: 'FA Type',
      dataIndex: 'reference',
      key: 'reference',
      sorter: true
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      render: (text) => <span>{text || "-"}</span>
    },
    {
      title: 'Detail',
      dataIndex: 'source_data',
      key: 'source_data',
      width: "65%",
      render: (text) => <pre style={{ whiteSpace: "break-spaces" }}><code>{JSON.stringify(text, null, 2)}</code></pre>
    }
  ]

  const formFields = [
    {
      name: 'grab_reference',
      label: 'Grab ID',
      req: false,
      placeholder: 'E.g: 1-D2TDFB3JDP6BRD',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
      addOnButton: 'Resync',
      isDisabledAddOnBtn: !data.grab_reference,
      onBtnClick: () => onResyncFa("grabfood")
    },
    {
      name: 'grab_last_sync_at',
      label: 'Grab Menu Last Synced',
      req: false,
      static: true,
      placeholder: 'Timestamp',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
    },
    {
      name: 'foodpanda_reference',
      label: 'Foodpanda ID',
      req: false,
      placeholder: 'E.g: z91d',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
      addOnButton: 'Resync',
      isDisabledAddOnBtn: !data.foodpanda_reference,
      onBtnClick: () => onResyncFa("foodpanda")
    },
    {
      name: 'foodpanda_last_sync_at',
      label: 'Foodpanda Last Synced',
      req: false,
      static: true,
      placeholder: 'Timestamp',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
    },
    {
      name: 'shopeefood_reference',
      label: 'ShopeeFood ID',
      req: false,
      placeholder: 'E.g: 1234567',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
      addOnButton: 'Resync',
      isDisabledAddOnBtn: !data.shopeefood_reference,
      onBtnClick: () => onResyncFa("shopeefood")
    },
    {
      name: 'shopeefood_last_sync_at',
      label: 'ShopeeFood Last Synced',
      req: false,
      static: true,
      placeholder: 'Timestamp',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
    },
    {
      name: 'o2o_reference',
      label: 'O2O ID',
      req: false,
      placeholder: 'E.g: 100001',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
      addOnButton: 'Resync',
      isDisabledAddOnBtn: !data.o2o_reference,
      onBtnClick: () => onResyncFa("o2o")
    },
    {
      name: 'o2o_last_sync_at',
      label: 'O2O Last Synced',
      req: false,
      static: true,
      placeholder: 'Timestamp',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
    },
    {
      name: 'deliveroo_reference',
      label: 'Deliveroo ID',
      req: false,
      placeholder: 'E.g: comp-abc-sg1',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      hidden: !singapore,
      static: !allowedEdit,
      addOnButton: 'Resync',
      isDisabledAddOnBtn: !data.deliveroo_reference,
      onBtnClick: () => onResyncFa("deliveroo")
    },
    {
      name: 'deliveroo_last_sync_at',
      label: 'Deliveroo Last Synced',
      req: false,
      static: true,
      placeholder: 'Timestamp',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      hidden: !singapore,
      reqmessage: 'Required',
    }
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('grab_reference', data.grab_reference);
      setValue('grab_last_sync_at', data.grab_last_sync_at);
      setValue('foodpanda_reference', data.foodpanda_reference);
      setValue('foodpanda_last_sync_at', data.foodpanda_last_sync_at);
      setValue('shopeefood_reference', data.shopeefood_reference);
      setValue('shopeefood_last_sync_at', data.shopeefood_last_sync_at);
      setValue('o2o_reference', data.o2o_reference);
      setValue('o2o_last_sync_at', data.o2o_last_sync_at);
      setValue('deliveroo_reference', data.deliveroo_reference);
      setValue('deliveroo_last_sync_at', data.deliveroo_last_sync_at);
    }
  }, [data]);

  useEffect(() => {
    reloadLogApi();
  }, []);

  const reloadLogApi = (pageSize = 1, limitSize = 25, orderSeq = '', orderCol = '') => {
    dispatch(getFaSyncLogs(pageSize, limitSize, orderSeq, orderCol, props.setLoading, id))
  };

  const onFinish = async (val) => {
    setLoading(true);
    const payload = {
      ...val,
      id: id
    }

    await updateFaIntegration(payload).then(res => {
      setLoading(false);
      if (res.data.code === 200) {
        message.success("FA Integration Updated Successfully!");
        setVisible(false);
        reloadApi();
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoading(false);
      message.error(e.message);
    })
  };

  const onConfirm = (val2) => {
    let val = {
      ...getValues(),
      ...val2
    };
    onFinish(val);
  };

  const onResyncFa = async (faType) => {
    props.setLoading(true);
    const payload = {
      fa_type: faType,
      id: id
    }

    await resyncSingleFa(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(res.data.message);
        reloadLogApi();
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={loading}>
      <Form layout="vertical" onFinish={handleSubmit2(onConfirm)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            Update FA Integration Details
          </Title>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <InputPassword
                isRequired={true}
                fieldname="password"
                label="Please enter your password to proceed"
                control={control2}
                initValue=""
                iProps={{ size: 'large' }}
                rules={{required: "Password cannot be blank"}}
                validate={errors2?.password && 'error'}
                validMessage={errors2?.password && errors2?.password.message}
              />
            </Col>
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('password', null);
            }}>Cancel</Button></Col>
            {
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 650,
    onCancel: () => {
      setVisible(false);
      setValue('password', '');
    },
  };

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      reloadLogApi(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey)
    } else {
      reloadLogApi(pagination.current, pagination.pageSize)
    }
  }

  return (
    <>
      <Form layout="vertical" scrollToFirstError>
        <Row gutter={[20, 30]}>
          {formFields.map((item, idx) => (
            <Fragment key={idx}>
              <FormGroup item={item} control={control} errors={errors} />
            </Fragment>
          ))}
        </Row>
        {
          allowedEdit &&
          <Row gutter={[10, 10]} className="mt-1">
            <Col span={24} className='text-right'>
              <Button size="large" htmlType='button' className='green-btn attendance-success' onClick={() => {
                setVisible(true);
                setValue('password', null);
              }}>Save</Button>
            </Col>
          </Row>
        }
      </Form>

      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"FA Sync Logs"} />
        </Col>
        <Col span={24}>
          <ListCard
            ListData={logs?.list?.map(x => ({ ...x, key: x.id }))}
            ListCol={colName}
            onChange={onTableChange}
            pagination={{
              total: logs?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}