import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Button, Row, Col, Typography } from 'antd';
import AddForm from '../components/AddForm';
import { useDispatch, useSelector } from 'react-redux';
import { getStockConversionOption } from '../../ducks/actions'
import { addStockConversion, generateInventoryNo } from "../../ducks/services"

const _ = require('lodash');
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("1");
  const meta = useSelector((state) => state.inventory.stockConversionOption);

  useEffect(() => {
    dispatch(getStockConversionOption(props.setLoading));
  }, [])

  return (
    <Row gutter={[20, 30]}>
      <Col span={24} style={{ position: "sticky", top: "-30px", backgroundColor: "#f5f5f5", zIndex: 3, padding: "10px" }}>
        <Row justify={'space-between'} align={'middle'}>
          <Col flex='auto'>
            <Title level={3} className='mb-0 mt-0'>New Stock Conversion</Title>
          </Col>
          <Col flex='auto' className='text-right'>
            <Space size={10}>
              <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/inventory/stock-conversion')}>{'<'} Back</Button>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <AddForm
          loading={props.loading}
          setLoading={props.setLoading}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          oneTab={true}
          mode={'add'}
          meta={meta}
          addStockConversion={addStockConversion}
          navigate={navigate}
          reloadApi={(propId) => navigate(`/inventory/stock-conversion/${propId}?t=2`)}
          allowedEdit={true}
          generateInventoryNo={generateInventoryNo}
        />
      </Col>
    </Row>
  )
}