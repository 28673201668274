import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Button, Row, Col, Typography } from 'antd';
import AddForm from '../components/AddForm';
import { addPaymentMethodType } from "../../ducks/services"

const _ = require('lodash');
const { Title, Text } = Typography;

export default (props) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("1");

  return (
    <Row gutter={[20, 30]}>
      <Col span={24}>
        <Row justify={'space-between'} align={'middle'}>
          <Col flex='auto'>
            <Title level={3} className='mb-0 mt-0'>New Tender Media Type</Title>
          </Col>
          <Col flex='auto' className='text-right'>
            <Space size={10}>
              <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/payment-method-type')}>{'<'} Back</Button>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <AddForm
          loading={props.loading}
          setLoading={props.setLoading}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          oneTab={true}
          mode={'add'}
          // meta={meta}
          addPaymentMethodType={addPaymentMethodType}
          navigate={navigate}
          reloadApi={(propId) => navigate(`/payment-method-type/${propId}`)}
          allowedEdit={true}
        />
      </Col>
    </Row>
  )
}