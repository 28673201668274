import React, { Fragment } from 'react';
import FormGroup from 'Molecules/FormGroup';

const _ = require('lodash');

export default (props) => {

  const { errors, control, meta, allowedEdit } = props

  const formFields = [
    {
      name: 'code',
      label: 'Code',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'name',
      label: 'Description',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'printer_type',
      label: 'Printer Type',
      req: true,
      type: 'select',
      options: meta?.printer_types?.map((x) => ({ label: x, value: x })),
      placeholder: 'Please select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    }
  ];

  return (
    <>
      {formFields.map((item, idx) => (
        <Fragment key={idx}>
          <FormGroup item={item} control={control} errors={errors} />
        </Fragment>
      ))}
    </>
  )
}