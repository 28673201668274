import React, { Fragment, useEffect, useState } from 'react';
import { Select, Form, Row, Col, Space, Typography, Spin, Button, message, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined, PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { InputField, SelectField, TimeField } from 'Atoms/FormElement';
import FormGroup from 'Molecules/FormGroup';
import { addAd, editAd, removeAd, updateAdOrder } from '../../../../../../../../ducks/services'
import { dummyRequest, getFileName, uploadFileV2 } from 'Features/utility';
import DndListCard from 'Molecules/DndListCard';
import ActionButton from 'Molecules/ActionButton';
import dayjs from 'dayjs';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { Controller } from 'react-hook-form';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const { id, activeTab, navigate, isKiosk, getAdList, subTab, allowedEdit, allowedDelete } = props;
  const dispatch = useDispatch();
  const { control, formState: { errors }, handleSubmit, setValue, clearErrors, getValues } = useForm();
  const adList = useSelector((state) => state.systems.adList);
  const adListMeta = useSelector((state) => state.systems.adListMeta);
  const [visible, setVisible] = useState(false);
  const [fileListImage, setFileListImage] = useState([]);
  const [fileId, setFileId] = useState();
  const [load, setLoad] = useState(false);
  const [hasDirty, setHasDirty] = useState(false);
  const [data, setData] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState(null);
  const kioskText = isKiosk === "1" ? 'kiosk' : 'qms';

  const ListCol = [
    {
      title: 'Image',
      dataIndex: 'tablet_cover_url',
      key: 'tablet_cover_url',
      sorter: true,
      width: 150,
      className: 'enable-click',
      render: (text, record) => <img width="100px" src={text ? text : record.tablet_url} />
    },
    {
      title: 'Type',
      dataIndex: 'content_type',
      key: 'content_type',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Transition Delay in second(s)',
      dataIndex: 'transition_delay',
      key: 'transition_delay',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Region(s)',
      dataIndex: 'regions',
      key: 'regions',
      className: 'enable-click',
      sorter: true,
      render: (text) => <span>{text?.map((x) => x.code).join(", ") || "-"}</span>
    },
    {
      title: 'Is Popup',
      dataIndex: 'is_popup',
      key: 'is_popup',
      className: 'enable-click',
      sorter: true,
      hidden: isKiosk === '0',
      align: 'center',
      render: (text) => <span>{text === "1" ? <CheckCircleFilled className='c-success' style={{ fontSize: '20px' }} /> : <CloseCircleFilled className='c-danger' style={{ fontSize: '20px' }} />}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Active" ? "c-success" : "c-danger"}>
            {text}
          </span>
          {
            allowedEdit &&
            <Button type="link" onClick={() => onAction(record.id, text)}>
              {text === "Active" ? "(Deactivate)" : "(Activate)"}
            </Button>
          }
        </div>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) =>
        <ActionButton
          title="Ad"
          btnAction1={() => {
            setVisible(true); 
            setValue('id', record.id);
            let temp = [];
            record?.regions?.map(region => {
              temp.push({
                label: region.code,
                value: region.id
              })
            })
            setValue('regions', temp);
            if (record?.tablet_cover_url || record?.tablet_url) {
              setFileListImage([
                {
                  uid: '-1',
                  name: getFileName(record?.tablet_cover_url || record?.tablet_url),
                  status: 'done',
                  url: record?.tablet_cover_url || record?.tablet_url
                }
              ])
            } else {
              setFileListImage();
              setFileId();
            }
            setValue('transition_delay', record?.transition_delay);
            setValue('is_popup', record.is_popup == "1");
          }}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={setLoad}
          loading={load}
        />
    }
  ]

  const formFields = [
    {
      name: 'transition_delay',
      label: 'Transition Delay in second(s)',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      number: true,
      nostep: true,
      static: !allowedEdit
    },
    {
      type: 'select',
      label: 'Regions',
      name: 'regions',
      twocol: false,
      req: false,
      multiple: true,
      options: adListMeta?.regions?.map((x) => ({ label: x.code, value: x.id })),
      placeholder: 'Please select',
      class: "default-select",
      colWidth: '0 1 100%',
      tips: "If blank input, the ad will be applied to the entire entity",
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'is_popup',
      label: 'Is Popup Banner',
      req: false,
      twocol: true,
      colWidth: '1 0 50%',
      alignEnd: true,
      hidden: isKiosk === '0',
      static: !allowedEdit
    },
  ];

  useEffect(() => {
    if (activeTab && activeTab === "6" && (subTab === "2" && isKiosk === "0" || subTab === "1" && isKiosk === "1")) {
      callApi();
    }
  }, [activeTab, isKiosk, subTab]);

  useEffect(() => {
    if (adList) {
      setData(adList?.list);
    }
  }, [adList]);

  const callApi = () => {
    dispatch(getAdList(kioskText, 1, limit, '', '', searchVal, props.setLoading, id));
  }

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getAdList(kioskText, pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading, id));
    } else {
      dispatch(getAdList(kioskText, pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading, id));
    }
  }

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      id: id,
      ad_id: val?.id,
      blob_id: fileId,
      ad_type: kioskText,
      transition_delay: val?.transition_delay,
      regions: val?.regions ? JSON.stringify(val?.regions?.map(x => ({ id: x.value }))) : "[]",
      is_popup: val?.is_popup ? 1 : 0,
    }

    val?.id ?
      await editAd(payload).then(res => {
        setLoad(false);
        if (res.data.code === 200) {
          message.success("Ad updated successfully.");
          setVisible(false);
          setFileId()
          setFileListImage();
          setTimeout(() => callApi(), 500);
        } else {
          message.error(res.data.message);
        }
      }).catch(e => {
        setLoad(false);
        message.error(e.message ?? "Something went wrong");
      }) :
      await addAd(payload).then(res => {
        setLoad(false);
        if (res.data.code === 200) {
          message.success("Ad added successfully.");
          setVisible(false);
          setFileId()
          setFileListImage();
          setTimeout(() => callApi(), 500);
        } else {
          message.error(res.data.message);
        }
      }).catch(e => {
        setLoad(false);
        message.error(e.message ?? "Something went wrong");
      });
  };

  const onUpdateList = async (datalist) => {
    props.setLoading(true);

    const payload = {
      ads: JSON.stringify(datalist?.map((d, i) => ({id: d.id, position: i + 1}))),
      id: id
    }
    await updateAdOrder(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Ad Listing updated successfully.");
        setVisible(false);
        setTimeout(() => callApi(), 500);
        setHasDirty(false);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onAction = async (adId, status) => {
    props.setLoading(true);

    const payload = {
      id: id,
      ad_id: adId,
      status_event: status === 'Active' ? 'deactivate' : 'activate'
    }

    await removeAd(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Ad Status has been updated successfully!");
        dispatch(getAdList(kioskText, 1, limit, '', '', searchVal, props.setLoading, id));
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onRemove = async (recId) => {
    props.setLoading(true);

    const payload = {
      ad_id: recId,
      id: id,
      status_event: 'remove'
    }

    await removeAd(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Ad has been removed successfully!");
        callApi();
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const handleUpload = async (e) => {
    if(load) return
    setLoad(true);
    try {
      let file = await uploadFileV2(e.file.originFileObj, 'image,video');
      setLoad(false);
      if(file !== false) {
        setFileId(file?.id);
        setFileListImage([{ uid: '-1', name: getFileName(file?.url), status: 'done', url: file?.url }]);
        setValue('doc', file)
      }
    } catch (error) {
      message.error(`Error during file upload: ${error}`);
      setLoad(false);
    }
  };

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={15} className='w-100'>
          <Title level={4} className='m-0'>
            {getValues('id') ? 'Edit' : 'New'} Ad
          </Title>
          <InputField
            fieldname='id'
            label=''
            class='d-none'
            initValue={''}
            control={control}
          />
          <div className='text-center'>
              <Form.Item
                required={true}
                label={""}
                validateStatus={errors.doc && 'error'}
                help={errors.doc && errors.doc.message}
                className={""}
              >
                <Controller
                  name={"doc"}
                  control={control}
                  rules={{
                    // required: getValues('id') ? false : 'This is Required'
                  }}
                  defaultValue={''}
                  render={({ value, fileList, onChange }) => (
                    <>
                      <Upload
                        disabled={!allowedEdit}
                        listType="picture-card"
                        className={`${errors.doc && 'danger'} avatar-uploader larger`}
                        showUploadList={false}
                        accept="image/*,video/mp4"
                        maxCount={1}
                        fileList={fileListImage}
                        customRequest={dummyRequest}
                        onChange={(e) => handleUpload(e)}
                      >
                        <Space size={4}>
                          {fileListImage?.length > 0 ? (
                            <img
                              src={fileListImage[0].url}
                              alt="avatar"
                              style={{
                                width: '100%',
                              }}
                            />
                          ) :
                            <div>
                              <PlusOutlined />
                              <div
                                style={{
                                  marginTop: 8,
                                }}
                              >
                                Upload
                              </div>
                            </div>
                          }
                        </Space>
                      </Upload>
                    </>
                  )}
                />
              </Form.Item>
          </div>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setFileId()
              setFileListImage();
              clearErrors();
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      clearErrors();
      setFileId()
      setFileListImage();
    },
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={24} className='text-right'>
          <Space size={10}>
            {
              hasDirty && allowedEdit &&
              <Button type='primary' htmlType='button' className='b-success attendance-success' onClick={() => onUpdateList(data)}>
                Update Listing
              </Button>
            }
            {
              allowedEdit &&
              <Button type='primary' htmlType='button' className='attendance-success' onClick={() => {
                setVisible(true);
                setValue('id', null);
                setValue('transition_delay', '');
                setValue('is_popup', false);
                setValue('regions', []);
                setFileListImage([]);
                setFileId();
              }}>
                + New Ad
              </Button>
            }
          </Space>
        </Col>

        <Col span={24} className="clickRow">
          <DndListCard
            setHasDirty={setHasDirty}
            setDataList={setData}
            list={data}
            classes="nopad"
            ListCol={ListCol.filter(x => !x.hidden)}
            ListData={data?.map(x => ({ ...x, key: x.id })) || []}
            onChange={onTableChange}
            rowSelection={allowedEdit && rowSelection}
            pagination={{
              total: adList?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  );
}