import React, { useEffect, useState, Fragment } from 'react';
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Spin, message, Form, Tooltip } from 'antd';
import AddForm from '../components/AddForm';
import { getStockWastage, reloadStockWastage } from "../../ducks/actions";
import { editStockWastage, removeStockWastage, exportSwPdf, generateInventoryNo } from "../../ducks/services";
import { useDispatch, useSelector } from 'react-redux';
import { CheckOutlined, ExportOutlined, LoadingOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import AllRoles from '../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../routing/config/utils';
import { useForm } from 'react-hook-form';
import FormGroup from 'Molecules/FormGroup';
import { Popup } from 'Atoms/Popup';

const { Title, Text } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation().pathname;
  const data = useSelector((state) => state.inventory.stockWastage);
  const meta = useSelector((state) => state.inventory.stockWastageOption);
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("1");
  const [load, setLoad] = useState(false);
  const allowedEdit = allowed([AllRoles.INVENTORY.STOCK_WASTE], 'write');
  const allowedDelete = allowed([AllRoles.INVENTORY.STOCK_WASTE], 'delete');
  const { control, formState: { errors }, handleSubmit, setValue, clearErrors } = useForm();
  const [visible, setVisible] = useState(false);

  const formFields = [
    {
      name: 'remarks',
      label: 'Remarks',
      placeholder: 'Please state',
      type: 'input',
      reqmessage: 'Required',
      req: true,
    }
  ];

  useEffect(() => {
    callApi();
  }, []);

  const callApi = () => {
    dispatch(getStockWastage(id, setLoad));
  };

  const reloadApi = (data) => {
    dispatch(reloadStockWastage(data, meta));
  }

  useEffect(() => {
    if (searchParams.get('t')) {
      setActiveTab(searchParams.get('t'));
    }
  }, [searchParams])

  const setActive = (tab) => {
    navigate(`${location}?t=${tab}`);
  }

  const onAction = async (statusEvent, remark = null) => {
    props.setLoading(true);

    const payload = {
      written_off_id: data?.id,
      status_event: statusEvent
    }

    if(remark) payload.cancellation_remarks = remark

    await removeStockWastage(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(`Stock Wastage status has been ${statusEvent}ed successfully!`);
        reloadApi(res.data.data)
      }else{
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const openCancelPopUp = () => {
    setVisible(true);
    setValue('remarks', data?.remarks);
    clearErrors();
  }

  const onFinish = async (val) => {
    onAction('cancel', val?.remarks)
    setVisible(false);
  }

  const cancelPopUp = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            Cancel Stock Wastage
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('remarks', '');
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 650,
    onCancel: () => {
      setVisible(false);
      setValue('remarks', '');
    },
  };

  const exportPdf = async () => {
    props.setLoading(true);

    await exportSwPdf(id).then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    })
  };

  return (
    <>
      <Spin indicator={antIcon} size="large" spinning={load}>
        <Row gutter={[20, 30]}>
          <Col span={24} style={{ position: "sticky", top: "-30px", backgroundColor: "#f5f5f5", zIndex: 3, padding: "10px" }}>
            <Row justify={'space-between'} align={'middle'}>
              <Col flex='auto'>
                <Title level={3} className='mb-0 mt-0'>
                  {data?.reference}
                  {" (Status: "}
                  <span className={data?.status === "posted" ? "c-success" : data?.status === "pending" ? "c-pending" : "c-danger"}>
                    {data?.status?.[0]?.toUpperCase()}{data?.status?.slice(1)}
                  </span>
                  {")"}
                  {data?.netsuite_synced === "1" && <Tooltip title="Synced to Netsuite"><CheckCircleTwoTone twoToneColor="#52c41a" className="ml-1" /></Tooltip>}
                </Title>
              </Col>
              <Col flex='auto' className='text-right'>
                <Space size={10}>
                  <Button size="large" htmlType='button' className='green-btn text-white' onClick={() => exportPdf()}>
                    <ExportOutlined /> Export SW
                  </Button>
                  {
                    data?.status === "pending" &&
                    allowedEdit &&
                    data?.item_count > 0 &&
                    <Button size="large" htmlType='button' className='green-btn text-white' onClick={() => onAction('post')}>
                      <CheckOutlined /> Post SW
                    </Button>
                  }
                  {
                    (data?.status !== "cancelled" && data?.status !== 'pending') &&
                    allowedEdit &&
                    data?.netsuite_synced === "0" &&
                    <Button size="large" htmlType='button' danger onClick={() => openCancelPopUp()}>
                      Cancel SW
                    </Button>
                  }
                  <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/inventory/stock-wastage')}>{'<'} Back</Button>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <AddForm
              loading={props.loading}
              setLoading={setLoad}
              activeTab={activeTab}
              setActiveTab={setActive}
              data={data}
              meta={meta}
              id={id}
              editStockWastage={editStockWastage}
              reloadApi={reloadApi}
              reloadCount={callApi}
              searchParams={searchParams}
              mode={'edit'}
              navigate={navigate}
              allowedEdit={allowedEdit}
              allowedDelete={allowedDelete}
              generateInventoryNo={generateInventoryNo}
            />
          </Col>
        </Row>
      </Spin>
      <Popup {...cancelPopUp} />
    </>
  )
}