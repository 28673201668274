import React, { useEffect, Fragment } from 'react';
import { Row, Col, Form, Button, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';

const _ = require('lodash');

export default (props) => {
  const { id, addKitchenDisplay, updateKitchenDisplay, meta, data, mode, reloadApi } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();

  const formFields = [
    {
      name: 'code',
      label: 'Kitchen Display Code',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
    },
    {
      name: 'desc',
      label: 'Description',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
    },
    {
      name: 'display_mode',
      label: 'KDS',
      req: true,
      type: 'select',
      options: meta?.display_modes?.map((x) => ({ label: x, value: x })),
      placeholder: 'Please select',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
    },
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('code', data.code);
      setValue('desc', data.desc);
      setValue('display_mode', data.display_mode ? { label: data.display_mode, value: data.display_mode } : '');
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      ...val,
      display_mode: val?.display_mode?.value,
      kitchen_display_id: id
    }

    await (mode === "add" ? addKitchenDisplay : updateKitchenDisplay)(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(`${mode === "add" ? "Created" : "Updated"} Kitchen Display successfully!`);
        reloadApi(res.data.data);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        <Col span={24} className='text-right'>
          <Button size="large" htmlType='submit' className='green-btn attendance-success'>Save</Button>
        </Col>
      </Row>
    </Form>
  )
}