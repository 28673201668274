import React, { Fragment } from 'react';

export default (props) => {
  const { columns, data } = props;

  return (
    <div className="inventory-table-container">
      <div className="inventory-table">
        <table>
          <thead>
            <tr>
              {columns?.material_consumption_listing?.list?.map(item =>
                <th className={`${item?.longCol ? 'long-col' : ''} text-${item.align}`} key={`Title ${item.title}`}>
                  {item.title}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data?.list?.map((listItem, listItemIndex) =>
              <Fragment key={`List Item ${listItemIndex}`}>
                <tr>
                  <td className='fwbold' align='left'>{listItem.date}</td>
                </tr>
                {listItem?.list?.map((childListItem, childListItemIndex) =>
                  <Fragment key={`List Item ${listItemIndex}: Child List Item ${childListItemIndex}`}>
                    <tr>
                      <td className='fwbold' align='left' style={{ paddingLeft: "20px" }}>{"-"} {childListItem.item}</td>
                      <td align='right'>{childListItem.qty}</td>
                      <td align='right'>{childListItem.gross_sales}</td>
                      <td align='right'>{childListItem.gross_amount}</td>
                      <td align='right'>{childListItem.discount_amount}</td>
                      <td align='right'>{childListItem.tax_amount}</td>
                      <td align='right'>{childListItem.net_sales}</td>
                      <td align='right'>{childListItem.net_amount}</td>
                      <td align='right'>{childListItem.cost}</td>
                    </tr>
                    <tr>
                      <th colSpan={1}></th>
                      <th className="nested-header" align='left' colSpan={2}>Item</th>
                      <th className="nested-header" align='left'>UOM</th>
                      <th className="nested-header" align='right'>Tran Qty</th>
                      <th className="nested-header" align='right'>Total Inv Cost</th>
                    </tr>
                    {childListItem?.list?.map((subChildListItem, subChildListItemIndex) =>
                      <tr key={`List Item ${listItemIndex}: Child List Item ${childListItemIndex}: Sub Child List Item ${subChildListItemIndex}`}>
                        <td colSpan={1}></td>
                        <td align='left' colSpan={2}>{subChildListItem.item}</td>
                        <td align='left'>{subChildListItem.uom}</td>
                        <td align='right'>{subChildListItem.qty}</td>
                        <td align='right'>{subChildListItem.inv_cost.toFixed(8)}</td>
                      </tr>
                    )}
                  </Fragment>
                )}
                <tr className='bg-gray text-white'>
                  <td>Sub Total</td>
                  {listItem?.sub_totals?.map((item, index) =>
                    <td key={`Sub Total ${item} ${index}`} align='right'>{item}</td>
                  )}
                </tr>
              </Fragment>
            )}


            <tr className='bg-gray text-white'>
              <td>Grand Total</td>
              {data?.grand_total?.map((item, index) =>
                <td key={`Grand Total ${item} ${index}`} align='right'>{item}</td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}