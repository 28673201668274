import axios from 'Services/axiosInterceptor';
import * as action_types from './constants';
import { externalBaseUrl } from '../../../../configs/constants';

export const getTerminals = (page, limit, order, orderby, search = null, load) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/logins/list`, {
    q: JSON.stringify({
      username_cont: search?.name,
      alias_cont: search?.code,
      location_id_eq: search?.group,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.POS_TERMINAL_LIST,
    data: data,
    meta: meta
  });
};

export const getTerminal = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/logins/get`, {
      id: id
    });
    load && load(false);
    dispatch({
      type: action_types.POS_TERMINAL_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getTerminalOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/logins/option`);
    load && load(false);
    dispatch({
      type: action_types.POS_TERMINAL_OPTION,
      data: data
    });
  };
};

export const getVoucherTypes = (page, limit, order, orderby, search = null, load) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/voucher-types/list`, {
    q: JSON.stringify({
      code_or_desc_cont: search?.name,
      value_type_eq: search?.value_type,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.VOUCHER_TYPE_LIST,
    data: data,
    meta: meta
  });
};

export const getVoucherTypeDetail = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/voucher-types/get`, {
      voucher_type_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.VOUCHER_TYPE_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getVoucherTypeOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/voucher-types/option`);
    load && load(false);
    dispatch({
      type: action_types.VOUCHER_TYPE_OPTION,
      data: data
    });
  };
};

export const getGeneratorOption = (load) => async (dispatch) => {
  load && load(true);
  const {
    data: { data },
  } = await axios.post(`${externalBaseUrl}/voucher-types/generator-option`, {
    page: 1,
    limit: 1000
  });
  load && load(false);
  dispatch({
    type: action_types.GENERATOR_OPTION,
    data: data
  });
};

export const getVouchers = (page, limit, order, orderby, search = null, load) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/vouchers/list`, {
    q: JSON.stringify({
      code_or_name_cont: search?.name,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.VOUCHERS_LIST,
    data: data,
    meta: meta
  });
};

export const getVoucherDetail = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/vouchers/get`, {
      voucher_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.VOUCHERS_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getVoucherItems = (page, limit, order, orderby, search = null, load, id) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/vouchers/voucher-items-list`, {
    voucher_id: id,
    q: JSON.stringify({
      code_cont: search?.code,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.VOUCHER_ITEM_LIST,
    data: data,
    meta: meta
  });
};

export const getKitchenDisplays = (page, limit, order, orderby, search = null, load) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data },
  } = await axios.post(`${externalBaseUrl}/kitchen-displays/list`, {
    q: JSON.stringify({
      code_or_desc_cont: search?.code,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.KITCHEN_DISPLAY_LIST,
    data: data,
  });
};

export const getKitchenDisplay = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/kitchen-displays/get`, {
      kitchen_display_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.KITCHEN_DISPLAY_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getKitchenDisplayOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/kitchen-displays/option`);
    load && load(false);
    dispatch({
      type: action_types.KITCHEN_DISPLAY_OPTION,
      data: data
    });
  };
};

export const getKitchenQueues = (page, limit, order, orderby, search = null, load) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data },
  } = await axios.post(`${externalBaseUrl}/kitchen-queues/list`, {
    q: JSON.stringify({
      code_or_desc_cont: search?.code,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.KITCHEN_QUEUE_LIST,
    data: data,
  });
};

export const getKitchenQueue = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/kitchen-queues/get`, {
      kitchen_queue_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.KITCHEN_QUEUE_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getKitchenQueueOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/kitchen-queues/option`);
    load && load(false);
    dispatch({
      type: action_types.KITCHEN_QUEUE_OPTION,
      data: data
    });
  };
};

export const getDiscountList = (page, limit, order, orderby, search = null, load) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data },
  } = await axios.post(`${externalBaseUrl}/discounts/list`, {
    q: JSON.stringify({
      code_or_desc_cont: search?.code,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.DISCOUNT_LIST,
    data: data,
  });
};

export const getDiscountDetail = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/discounts/get`, {
      discount_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.DISCOUNT_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getDiscountOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/discounts/option`);
    load && load(false);
    dispatch({
      type: action_types.DISCOUNT_OPTION,
      data: data
    });
  };
};

export const getDiscountAvailabilities = (page, limit, order, orderby, search = {}, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/discounts/discount-availability-list`, {
      discount_id: id,
      q: JSON.stringify({
        // remark_code_cont: search?.remark_code,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.DISCOUNT_AVAILABILITY_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getAvailableProducts = (status, page, limit, order, orderby, search = {}, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/discounts/available-product-list`, {
      discount_id: id,
      q: JSON.stringify({
        s: `${orderby} ${ordering}`,
        code_or_name_cont: search?.product,
      }),
      status: status,
      page: page,
      limit: limit,
    });
    load && load(false);
    dispatch({
      type: action_types.DISCOUNT_AVAILABLE_PRODUCT_LIST,
      data: data,
    });
  };
}

export const getPrinterList = (page, limit, order, orderby, search = null, load) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data },
  } = await axios.post(`${externalBaseUrl}/printers/list`, {
    q: JSON.stringify({
      code_or_name_cont: search?.name,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.PRINTER_LIST,
    data: data,
  });
};

export const getPrinter = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/printers/get`, {
      printer_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.PRINTER_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getPrinterOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/printers/option`);
    load && load(false);
    dispatch({
      type: action_types.PRINTER_OPTION,
      data: data
    });
  };
};

export const getActivities = (page, limit, order, orderby, load, id) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data },
  } = await axios.post(`${externalBaseUrl}/logins/activity-list`, {
    q: JSON.stringify({
      s: `${orderby} ${ordering}`
    }),
    id: id,
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.TERMINAL_ACTIVITY_LIST,
    data: data,
  });
};

export const getAllActivities = (page, limit, order, orderby, search = {}, load) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/activities/list`, {
    q: JSON.stringify({
      login_username_cont: search?.name,
      location_id_eq: search?.group,
      report_date_eq: search?.start_date,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.ALL_ACTIVITY_LIST,
    data: data,
    meta: meta
  });
};