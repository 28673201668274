import React from 'react';
import { Tabs } from 'antd';
import * as TabCards from './tabList';

export default (props) => {
  const { setActiveTab, oneTab, searchParams } = props;
  const defaultActive = searchParams?.get('t');

  const tabs = [
    {
      name: "Entity",
      Comp: 'Detail',
    },
    {
      name: "Collaborators",
      Comp: 'User',
    },
    {
      name: "Setting",
      Comp: 'Setting',
    },
    {
      name: "KDS",
      Comp: 'Kds',
    },
    {
      name: "Kitchen Queue Alert",
      Comp: 'KitchenQueueAlert',
    },
    {
      name: "Ads",
      Comp: 'Ads',
    },
  ];

  return (
    <Tabs items={
        (oneTab ? [tabs[0]] : tabs).map((item, index) => {
          const Cardi = TabCards[item.Comp];
          return {
            key: (index + 1).toString(),
            label: item.name,
            forceRender: true,
            children: <Cardi {...props} />
          }
        })
    } defaultActiveKey={defaultActive || "1"} onChange={(activeKey) => setActiveTab(activeKey)}/>
  );
};
