import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Empty } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import FormGroup from 'Molecules/FormGroup';
import { useDispatch, useSelector } from 'react-redux';
import { getPoItemList } from '../../../../../ducks/actions'
import { editPoItem, addPoItem, removePoItem } from '../../../../../ducks/services'
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import Search from './Search'
import ActionButton from 'Molecules/ActionButton';
import { allowed } from '../../../../../../../../routing/config/utils';
import AllRoles from '../../../../../../../../routing/config/AllRoles';


const _ = require('lodash');

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, activeTab, data, allowedEdit, allowedDelete, reloadCount } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const poItems = useSelector((state) => state.inventory.poItems);
  const meta = useSelector((state) => state.inventory.poItemsOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [load, setLoad] = useState(false);
  const [visible, setVisible] = useState(false);
  const [searchVal, setSearchVal] = useState();

  const colName = [
    {
      title: 'No.',
      dataIndex: 'index',
      key: 'index',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Item',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Net Unit Amount',
      dataIndex: 'price',
      key: 'price',
      align: 'right',
      sorter: true,
      className: 'enable-click',
      hidden: !allowed([AllRoles.INVENTORY.PURCHASE_ORDER], 'read'),
    },
    {
      title: 'UOM',
      dataIndex: 'uom_code',
      key: 'uom_code',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Order Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: true,
      className: 'enable-click',
      align: 'right',
    },
    {
      title: 'Gross Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: true,
      align: 'right',
      className: 'enable-click',
      hidden: !allowed([AllRoles.INVENTORY.PURCHASE_ORDER], 'read'),
    },
    {
      title: 'Discount Amount',
      dataIndex: 'discount_amount',
      key: 'discount_amount',
      sorter: true,
      align: 'right',
      className: 'enable-click',
      hidden: !allowed([AllRoles.INVENTORY.PURCHASE_ORDER], 'read'),
    },
    {
      title: 'Net Amount',
      dataIndex: 'net_amount',
      key: 'net_amount',
      sorter: true,
      align: 'right',
      className: 'enable-click',
      hidden: !allowed([AllRoles.INVENTORY.PURCHASE_ORDER], 'read'),
    },
    {
      title: 'Tax Amount',
      dataIndex: 'tax_amount',
      key: 'tax_amount',
      sorter: true,
      align: 'right',
      className: 'enable-click',
      hidden: !allowed([AllRoles.INVENTORY.PURCHASE_ORDER], 'read'),
    },
    {
      title: 'Inclusive Tax Amount',
      dataIndex: 'inclusive_tax_amount',
      key: 'inclusive_tax_amount',
      sorter: true,
      align: 'right',
      className: 'enable-click',
      hidden: !allowed([AllRoles.INVENTORY.PURCHASE_ORDER], 'read'),
    },
    {
      title: 'Exclusive Tax Amount',
      dataIndex: 'exclusive_tax_amount',
      key: 'exclusive_tax_amount',
      sorter: true,
      align: 'right',
      className: 'enable-click',
      hidden: !allowed([AllRoles.INVENTORY.PURCHASE_ORDER], 'read'),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="Supplied Item"
          btnAction1={data?.status === "pending" ? () => {
            setVisible(true);
            setValue('id', record.id);
            setValue('item', record.product_id ? { label: record.name, value: record.product_id } : '');
            setValue('uom', record.uom_id ? { label: record.uom_code, value: record.uom_id } : '');
            setValue('quantity', record.quantity);
          } : false}
          onRemove={(data?.status === "pending" && allowedDelete) && onRemove}
          recordId={record.id}
          setLoading={setLoad}
          loading={load}
        />
    }
  ];

  const formFields = [
    {
      name: 'id',
      label: '',
      type: 'input',
      hidden: true,
    },
    {
      name: 'item',
      label: 'Item',
      req: true,
      placeholder: 'Please select',
      type: 'select',
      class: 'default-select',
      options: _.map(meta?.vendor_products, (e) => ({ label: e.product_code, value: e.product_id, uom_id: e.uom_id, uom_code: e.uom_code })),
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit,
      onChange: (e) => {
        setValue('uom', { label: e.uom_code, value: e.uom_id })
      }
    },
    {
      name: 'quantity',
      label: 'Quantity',
      req: true,
      placeholder: 'Please select',
      type: 'input',
      number: true,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
    },
    {
      name: 'uom',
      label: 'UOM',
      req: true,
      placeholder: 'Please select',
      class: 'default-select',
      type: 'select',
      options: [],
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: true
    }
  ];

  useEffect(() => {
    if (activeTab === "2")
      dispatch(getPoItemList(1, limit, '', '', null, props.setLoading, id));
  }, [activeTab]);

  const addNew = () => {
    setVisible(true);
    setValue('id', '');
    setValue('item', '');
    setValue('uom', '');
    setValue('quantity', '');
  }

  const btnList = [
    {
      text: '+ New Item',
      classes: 'attendance-success',
      action: () => addNew(),
    }
  ];

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getPoItemList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading, id));
    } else {
      dispatch(getPoItemList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading, id));
    }
  }
  
  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        product_code: search?.product_code
      };
      setSearchVal(searching);
      dispatch(getPoItemList(1, limit, '', '', searching, props.setLoading, id));
    } else {
      setSearchVal(null);
      dispatch(getPoItemList(1, limit, '', '', null, props.setLoading, id));
    }
  };

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      uom_id: val?.uom?.value,
      quantity: val?.quantity,
      product_id: val?.item?.value,
      product_name: val?.item?.label,
      price: meta?.vendor_products?.find(x => x.product_id === val?.item?.value)?.price,
      purchase_order_item_id: val?.id,
      purchase_order_id: id
    }
    await (val?.id ? editPoItem : addPoItem)(payload).then(res => {
      setLoad(false);
      if(res.data.code === 200) {
        message.success(`PO Item ${val?.id ? 'Updated' : 'Added'} Successfully!`);
        setVisible(false);
        setPage(1);
        reloadCount();
        setTimeout(() => dispatch(getPoItemList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      }else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const onRemove = async (recordId) => {
    setLoad(true);

    const payload = {
      purchase_order_item_id: recordId,
      purchase_order_id: id
    }
    await removePoItem(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success(`PO Item Removed Successfully!`);
        setVisible(false);
        setPage(1);
        reloadCount();
        setTimeout(() => dispatch(getPoItemList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)} scrollToFirstError>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            {getValues('id') ? 'Edit' : 'Add'} PO Item
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('id', '');
              setValue('item', '');
              setValue('uom', '');
              setValue('quantity', '');
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setValue('id', '');
      setValue('uom', '');
      setValue('item', '');
      setValue('quantity', '');
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click") && data?.status === "pending"){
          setValue('id', record.id);
          setValue('item', record.product_id ? { label: record.name, value: record.product_id } : '');
          setValue('uom', record.uom_id ? { label: record.uom_code, value: record.uom_id } : '');
          setValue('quantity', record.quantity);
          setVisible(true);
        }
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={''} btnList={(data?.status === "pending" && allowedEdit) ? btnList : []} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onSearch={onSearch}
            Search={Search}
            onRow={onClickRow}
            ListData={poItems?.list?.map((x, idx) => ({ ...x, key: x.id, index: idx + 1}))}
            onChange={onTableChange}
            ListCol={colName.filter(x => !x.hidden)}
            pagination={{
              total: poItems?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}