import React, { useEffect, Fragment } from 'react';
import { Form, Button, Row, Col, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';

const _ = require('lodash');

export default (props) => {
  const { data, editOutletReceipt, id, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();

  const formFields = [
    {
      type: 'input',
      name: 'qr_value',
      label: 'QR Value',
      req: false,
      placeholder: 'Please state',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      type: 'input',
      name: 'initial_running_no',
      label: 'Initial Running No',
      req: false,
      placeholder: 'Please state',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
      tips: "* Please enter the last receipt running number from your outlet, if available.",
      wordCount: 6
    },
    {
      type: 'switch',
      name: 'receipt_hide_qr',
      label: 'Hide QR?',
      req: false,
      placeholder: 'Please select',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'receipt_footer_desc',
      label: 'Receipt Footer Description',
      req: false,
      placeholder: 'Please state',
      type: 'textarea',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Receipt Footer Description Required',
      tips: "It is capped at 48 characters per line.",
      static: !allowedEdit
    },
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('qr_value', data.qr_value);
      setValue('initial_running_no', data.initial_running_no);
      setValue('receipt_hide_qr', data.receipt_hide_qr === "1");
      setValue('receipt_footer_desc', data.receipt_footer_desc?.replace(/\|/g, "\n"));
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      ...val,
      receipt_hide_qr: val?.receipt_hide_qr ? "1" : "0",
      receipt_footer_desc: val?.receipt_footer_desc?.replace(/\n/g, "|"),
      id: id
    }

    await editOutletReceipt(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Receipt edited successfully!");
        // setTimeout(() => navigate("/outlets"), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>Save</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}